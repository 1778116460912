import React, { useContext, useState } from "react";
import InfoBox from "../../atoms/info-box/infoBox";
import QuestionWizard from "../question-wizard/questionWizard";
import QuestionWizardContent from "../question-wizard/questionWizardContent";
import "./simulador.scss";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";
import { useForm } from "react-hook-form";
import Input from "../../atoms/input/input";
import iconValor from "../../../assets/icons/simulador/segun_valor_vivienda.svg";
import iconCuota from "../../../assets/icons/simulador/segun_cuota_que_puedo_pagar.svg";
import InputPrestamo from "../../molecules/input-prestamo/inputPrestamo";
import axios from "axios";
import Resultado from "./resultado/resultado";
import {
  saveSimulation,
  simulationSegunQuota,
  simulationSegunValue,
} from "../../../services/simuladorServices";
import { AppContext } from "../../../contexts/appContext";
import Loading from "../../atoms/loading/loading";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import { updateSimulation } from "../../../services/simuladorServices";

interface SimuladorProps {
  formData: any;
  setData: any;
  setFormIdData: any;
}

const cardOptions = [
  {
    title: "Según el valor de la vivienda",
    value: "SegunValor",
    img: iconValor,
  },
  {
    title: "Según la cuota que puedo pagar",
    value: "SegunCuota",
    img: iconCuota,
  },
];

const Simulador = (props: any) => {
  // const Simulador = ({ formData, setData, setFormIdData }: SimuladorProps) => {
  const { formData, setData, setFormIdData, stateSolicitud, isRadication } =
    useContext(AppContext);

  const [step, setStep] = useState(0);
  const [results, setResults] = useState<{
    valorCredito: number;
    valorTasainteres: number;
    valorGastosManejo: number;
    valorCuotaSinSeguroMensualAprox: number;
    valorCuotaMensual: number;
    valorSegurosMensualAprox: number;
    valorTotalCredito: number;
    factorSeguroVida: number;
    seguroVidaMensualAprox: number;
    factorTodoRiesgo: number;
    seguroTodoRiesgoMensual: number;
  }>({} as any);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const appContext = useContext(AppContext);

  const getResultsValor = () => {
    setLoading(true);

    const data = {
      valorSolicitado: formData["valorVivienda"],
      valorReposicion: 0,
      categoria: "B",
      plazoNumeroCuotas: formData["cantidadCuotas"],
      codigoUsoId: 1,
      tipoBeneficioId: 1,
      tipoSubsidioId: 1,
      etapaId: 1,
      simulacionId: 0,
      solicitantes: [
        {
          id: 5,
          edad: 50,
          tipoSolicitanteId: 1,
          porcentajeExtraPrima: 0,
        },
      ],
    };

    simulationSegunValue({
      ...data,
      // valorSolicitado: formData["porcentajeResultado"],
      // categoria:
      //   appContext.stateSolicitud.solicitanteLider.validationData.categoria!,
      // plazoNumeroCuotas: formData["cantidadCuotas"],
      // numeroSolicitantes: appContext.stateSolicitud.avalistas.length + 1,
    })
      .then((res) => {
        setResults(res.data.result);
        moveNext();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getResultsCuota = () => {
    const data = {
      valorCuota: formData["cuotaMesSolicitante"],
      valorReposicion: 0,
      categoria: "C",
      plazoNumeroCuotas: formData["cantidadCuotas"],
      codigoUsoId: 1,
      tipoBeneficioId: 1,
      tipoSubsidioId: 1,
      etapaId: 1,
      simulacionId: 0,
      solicitantes: [
        {
          id: 5,
          edad: 50,
          tipoSolicitanteId: 1,
          porcentajeExtraPrima: 0,
        },
      ],
    };
    setLoading(true);
    simulationSegunQuota({
      ...data,
      // valorCuota: formData["cuotaMesSolicitante"],
      // categoria:
      //   appContext.stateSolicitud.solicitanteLider.validationData.categoria!,
      // plazoNumeroCuotas: formData["cantidadCuotas"],
      // numeroSolicitantes: appContext.stateSolicitud.avalistas.length + 1,
    })
      .then((res) => {
        setResults(res.data.result);
        moveNext();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getResults = async () => {
    if (formData["tipoSimulacion"] === "SegunValor") {
      getResultsValor();
    } else {
      getResultsCuota();
    }
  };

  const handleSaveSimulation = async () => {
    let base = 1;
    let valorVivienda = formData["valorVivienda"];
    let porcentajePrestamo = formData["valorPorcentaje"];

    //Tipo de solicitantes:
    //1 -> titular
    //2 -> avalista

    const solicitantes = [
      {
        tipoSolicitante: 1,
        solicitante:
          stateSolicitud.solicitanteLider.formData.documentNumber + "",
      },
    ];

    stateSolicitud.avalistas.forEach((avalista) => {
      solicitantes.push({
        tipoSolicitante: 2,
        solicitante: avalista.formData.numeroDocumento,
      });
    });

    if (formData["tipoSimulacion"] === "SegunCuota") {
      base = 2;
      valorVivienda = results.valorCredito;
      porcentajePrestamo = 0;
    }

    saveSimulation({
      solicitantes,
      base,
      valorVivienda,
      porcentajePrestamo,
      valorPrestamo: results.valorCredito,
      plazo: formData["cantidadCuotas"],
      valorCuotaMensual: results.valorCuotaMensual,
      valorTasaInteres: results.valorTasainteres,
      valorGastosManejo: results.valorGastosManejo,
      valorSeguroMensualAprox: results.valorSegurosMensualAprox,
      valorCuotaSinSeguroMensual: results.valorCuotaSinSeguroMensualAprox,
      valorPrestamoTotal: results.valorTotalCredito,
      //Estos datos de abajo se repiten y no los devuelve el resultado del simulador, validar con back
      condicionTasaInteres: results.valorTasainteres,
      condicionGastosManejo: results.valorGastosManejo,
      condicionBeneficioGastosManejo: 0,
      condicionFactorTodoRiesgo: results.factorTodoRiesgo,
      condicionFactorVida: results.factorSeguroVida,

      usuarioId: stateSolicitud.solicitanteLider.id!,
      lineaCreditoId: parseInt(
        stateSolicitud.solicitanteLider.formData.creditLine!
      ),
      sedeId: parseInt(stateSolicitud.solicitanteLider.formData.sede!),
    });
  };

  const moveNext = async () => {
    if (appContext.isRadication) {
      setLoading(true);
      try {
        let response = await updateSimulation(
          appContext.stateSolicitud.simulacion
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      appContext.setCurrentTab("datosContacto");
    } else {
      setStep(step + 1);
    }
  };

  const movePrev = () => {
    setStep(step - 1);
  };

  return (
    <div>
      {/* Paso: {step} */}
      {loading && <Loading />}
      {!isRadication ? (
        <QuestionWizard handleSumbit={handleSubmit} step={step}>
          <QuestionWizardContent
            question="Simulador crédito de Vivienda"
            subtitle="Selecciona la opción que más se ajusta a lo que necesitas"
            content={(register: any, errors: any, watch: any) => (
              <FlexDivUI alignItems="center" flexDirection="column">
                <FlexDivUI justifyContent="center">
                  <CardGroupInput
                    options={cardOptions}
                    register={register("tipoSimulacion", {
                      required: "Campo requerido",
                      onChange: setData,
                    })}
                    onClick={moveNext}
                  />
                </FlexDivUI>
                <InfoBox
                  title="Solicitantes adicionales"
                  description="Debe ser una persona del grupo familiar primario del titular de crédito. Puedes solicitar el crédito con máximo dos personas."
                />
              </FlexDivUI>
              // <Resultado results={results as any} register={register} errors={errors} formData={formData} />
            )}
          />
          {formData.tipoSimulacion === "SegunValor" ? (
            <>
              <QuestionWizardContent
                question="Simulador según el valor de la vivienda"
                content={(
                  register: any,
                  errors: any,
                  watch: any,
                  setValue: any
                ) => (
                  <FlexDivUI>
                    <FlexDivUI justifyContent="center">
                      <InputPrestamo
                        title="Valor que te préstamos"
                        formData={formData}
                        setData={setData}
                        setFormIdData={setFormIdData}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                      />
                    </FlexDivUI>
                    {/* <InfoBox title="Porcentaje de préstamo" description='Te prestamos hasta el 80% si la vivienda que vas a comprar es nueva de interés social (VIS). Si es otro tipo de vivienda te prestamos hasta el 70%.' /> */}
                  </FlexDivUI>
                )}
                nextCallback={moveNext}
                previousCallback={movePrev}
              />
            </>
          ) : (
            <QuestionWizardContent
              question="Simulador según la cuota que puedo pagar"
              content={(register: any, errors: any, watch: any) => (
                <div className="o-simulador__container-center">
                  <Input
                    register={register("cuotaMesSolicitante", {
                      required: "Campo requerido",
                      onChange: setData,
                    })}
                    type="number"
                    title="¿Cuánto puedes pagar al mes?"
                    error={errors?.cuotaMesSolicitante?.message || ""}
                    placeholder="Agrega el valor"
                    className=""
                  />
                </div>
              )}
              nextCallback={moveNext}
              previousCallback={movePrev}
            />
          )}
          <QuestionWizardContent
            question="Simulador crédito de Vivienda"
            content={(register: any, errors: any, watch: any) => (
              <div className="o-simulador__container-center">
                <Input
                  register={register("cantidadCuotas", {
                    required: "Campo requerido",
                    min: {
                      value: 60,
                      message: "El valor minimo es de 60",
                    },
                    max: {
                      value: 240,
                      message: "El valor máximo es de 240",
                    },
                    onChange: setData,
                  })}
                  type="number"
                  title="¿A cuántos meses?"
                  error={errors?.cantidadCuotas?.message || ""}
                  placeholder="Ingresa el número de meses"
                  className=""
                  value={formData["cantidadCuotas"]}
                  key="cantidadCuotas"
                />
              </div>
            )}
            nextCallback={getResults}
            previousCallback={movePrev}
          />
          <QuestionWizardContent
            question="Simulador crédito de vivienda"
            content={(register: any, errors: any, watch: any) => (
              <Resultado
                results={results as any}
                register={register}
                errors={errors}
                formData={formData}
                setFormIdData={setFormIdData}
                watch={watch}
              />
            )}
            nextCallback={moveNext}
            previousCallback={movePrev}
          />
        </QuestionWizard>
      ) : (
        <QuestionWizardContent
          question="Simulador crédito de vivienda"
          content={(register: any, errors: any, watch: any) => (
            <Resultado
              results={results as any}
              register={register}
              errors={errors}
              formData={formData}
              setFormIdData={setFormIdData}
              watch={watch}
            />
          )}
          nextCallback={moveNext}
          previousCallback={movePrev}
        />
      )}
    </div>
  );
};

export default Simulador;
