import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../../atoms/button/button";
import icono_rechazar from "../../../assets/icons/tratamientoDatos/icono_rechazar.svg";
import Otp from "../otp/otp";
import "./terminosCondiciones.scss";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import { AppContext } from "../../../contexts/appContext";
import SolicitudEspera from "../solicitud-espera/solicitudEspera";
import SolicitudPreaprobada from "../solicitud-preaprobada/solicitudPreaprobada";
import Modal from "../../atoms/modal/modal";
import TitleUI from "../../atoms/title-ui/TitleUI";

const TerminosCondiciones = () => {
  const [rechazar, setReachazar] = useState(false);
  const [aceptar, setAceptar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dockRef = useRef(null);
  const { setCurrentTab, stateSolicitud, dispatchSolicitud } =
    useContext(AppContext);

  const [flagVality, setFlagVality] = useState(false);

  const {
    register,
    //handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tyCFirmado: false,
    },
  });

  let watchAllFields = watch();
  const onClickReject = () => {
    setReachazar(true);
    setAceptar(false);
  };

  const startDiagnostic = () => {
    setReachazar(false);
    // setAcceptTratamiento(true);
    setAceptar(true);
  };

  const showOtpScreen = () => {
    console.log(stateSolicitud);

    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      if (
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.celular != null &&
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.email != null
      ) {
        setReachazar(false);
        setAceptar(true);
      }
      setOpenModal(true);
    } else {
      if (
        stateSolicitud.solicitanteLider.solicitante.celular != null &&
        stateSolicitud.solicitanteLider.solicitante.email != null
      ) {
        setReachazar(false);
        setAceptar(true);
      } else {
        setOpenModal(true);
      }
    }
  };

  const onClickReset = () => {
    setReachazar(false);
    setAceptar(false);
  };

  const HandleValityTerm = () => {
    let vality: boolean = false;

    if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
      vality = true;
      if (
        stateSolicitud.avalistas != undefined ||
        stateSolicitud.avalistas != null
      ) {
        if (stateSolicitud.avalistas.length > 0) {
          stateSolicitud.avalistas.forEach((element) => {
            if (!element.solicitante.tyCFirmado) {
              vality = false;
            }
          });
        }
      }
    }

    setFlagVality(vality);
  };

  const HandleData = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      setValue(
        "tyCFirmado",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.tyCFirmado || false
      );
    } else {
      setValue(
        "tyCFirmado",
        stateSolicitud.solicitanteLider.solicitante.tyCFirmado || false
      );
    }
  };

  useEffect(() => {
    HandleData();
    HandleValityTerm();
  }, []);

  useEffect(() => {
    HandleData();
    HandleValityTerm();
  }, [stateSolicitud]);

  useEffect(() => {
    setReachazar(false);
    setAceptar(false);
  }, [stateSolicitud.solicitanteLider.stateSolicitante]);

  const CloseModal = () => {
    setOpenModal(!openModal);
  };

  // useEffect(() => {
  //     if (dockRef && dockRef.current) {
  //         const y = dockRef.current?.getBoundingClientRect().top + window.pageYOffset - 100;
  //         window.scrollTo({
  //             top: y
  //         });
  //     }
  // }, [rechazar, aceptar])

  if (!watchAllFields.tyCFirmado) {
    return (
      <div className="tratamiento-datos" ref={dockRef}>
        {!rechazar && !aceptar ? (
          <>
            <Modal open={openModal} handleClose={() => CloseModal()}>
              <TitleUI color="primary" as="h1" align="center">
                ¡Lo sentimos!
              </TitleUI>
              <div className="o-terminosCondiciones__Modal__Message">
                <TitleUI color="dark" as="h4" align="center">
                  Tu formulario aún no esta habilitado, para que puedes continuar
                  debes terminar el proceso.
                </TitleUI>
              </div>

              <div className="o-terminosCondiciones__Modal__Button">
                <Button
                  text={"Volver"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    CloseModal();
                  }}
                />
              </div>
             
              
            </Modal>

            <body className="tratamiento-datos__body">
              <div className="tratamiento-datos__body__background">
                <h1 className="tratamiento-datos__body__title2">
                  Términos y condiciones
                </h1>
                <h3 className="tratamiento-datos__body__title">
                  Autorización de tratamiento de datos personales
                </h3>
                <div className="tratamiento-datos__body__text">
                  <p>
                    Con la firma de esta solicitud de crédito autorizo de manera
                    libre, voluntaria, previa, explicita e informada a Comfama,
                    al FGA Fondo de Garantías S.A o sus encargados o, quien
                    represente sus derechos u ostente en el futuro la calidad de
                    acreedor para:
                  </p>
                  <ul className="list">
                    <li>
                      Consultar en bases de datos crediticias o no, públicas o
                      privadas, mi situación patrimonial. .
                    </li>
                    <li>
                      Consultar, reportar, actualizar, procesar o divulgar la
                      información de mi comportamiento crediticio en las
                      centrales de información crediticia para almacenar
                      información sobre mi comportamiento crediticio, la manera
                      como cumplo la obligación crediticia que adquiero.
                    </li>
                    <li>
                      Almacenar, consultar, compartir, verificar y rectificar
                      mis datos personales ante cualquier entidad nacional o
                      extrajera que maneje o administre bases de datos, preste
                      servicios de verificación o realice análisis de
                      administración de riesgos.
                    </li>
                    <li>
                      Contactar a las personas que indico como referencia, de
                      quienes afirmo he obtenido su autorización para
                      suministrar sus datos personales.
                    </li>
                    <li>
                      Comunicar y/o notificar a través de los datos de contacto
                      suministrados por este medio la información sobre el
                      estado del crédito solicitado y/u otorgado.
                    </li>
                    <li>
                      Almacenar, consultar, compartir, verificar, reportar,
                      actualizar y rectificar tus datos personales al interior
                      de Comfama o ante cualquier otra entidad nacional o
                      extrajera que maneje o administre bases de datos, preste
                      servicios de verificación o realice análisis de
                      administración de riesgos.
                    </li>
                    <li>
                      Transferir o transmitir tus datos a terceros dentro y/o
                      fuera de Colombia.
                    </li>
                    <li>
                      Informar a mi empleador cuando este sea el patrocinador
                      sobre mi evolución en el programa, cumplimiento de citas y
                      compromisos.
                    </li>
                  </ul>
                  <p>
                    Así mismo si la línea de crédito que solicito se encuentra
                    amparada por el Fondo de Garantías S.A (FGA), acepto expresa
                    y libremente la garantía de FGA para respaldar la operación,
                    en caso de ser aprobada por la Caja de Compensación Familiar
                    de Antioquia - Comfama, y me obligo a pagar la comisión de
                    la garantía más el IVA, sin lugar a devolución o reintegro
                    por prepago de los créditos.
                  </p>
                  <p>
                    Igualmente declaro conocer la garantía que concede FGA en
                    favor del otorgante del crédito, y en caso de incumplir la
                    obligación a mi cargo soy consciente que FGA pagará al
                    otorgante del crédito el porcentaje pactado y, en
                    consecuencia, operará en su favor la subrogación legal por
                    activa, permitiendo recobrar el valor pagado, momento a
                    partir del cual se generarán intereses de mora y gastos de
                    cobranza, sin que ello implique la extinción parcial, ni
                    total de la obligación. Entiendo que no estoy obligado a
                    diligenciar los datos personales que sean sensible y, soy
                    consciente que al no dar autorización para realizar los
                    tratamientos anteriormente enunciados mi solicitud de
                    crédito no podrá ser tramitada.
                  </p>
                  <p>
                    Esta autorización incluye el tratamiento de datos personales
                    de carácter biométrico. Con esta autorización, además,
                    manifiesto que he sido informado acerca de la política de
                    protección de datos personales de Comfama que se encuentran
                    en la página web www.comfama.com y que he sido informado
                    acerca de los derechos que tengo para proteger mis datos y
                    que puedo ejercerlo a través del correo electrónico
                    protecciondatospersonales@comfama.com.co Declaro que toda la
                    información depositada en el presente documento es cierta,
                    veraz y verificable
                  </p>
                </div>
              </div>
              {/* <span className="tratamiento-datos__body__info">
                Para conocer la politica de protección de datos de personas de
                Comfama,{" "}
                <a
                  className="tratamiento-datos__body__link"
                  href="https://serviciosenlinea.comfama.com/webinicio/HabeasData.htm"
                  target="_blank"
                >
                  haz clic acá
                </a>
              </span> */}
              {/* TODO: Estos controles  */}
              <div className="tratamiento-datos__body__controls">
                <Button
                  type={"button"}
                  classname="tratamiento-datos__body__controls__button"
                  text="Rechazar"
                  isDisabled={false}
                  gtmMark="primary"
                  onClickAction={onClickReject}
                />
                <Button
                  onClickAction={showOtpScreen}
                  type={"button"}
                  classname="tratamiento-datos__body__controls__button"
                  text="Aceptar"
                  isDisabled={false}
                  gtmMark="secondary"
                />
              </div>
            </body>
          </>
        ) : !aceptar && rechazar ? (
          <WrapperContent>
            <FlexDivUI flexDirection="column">
              <div className="tratamiento-datos__rejected">
                {/* <ImageBlobBackground blobImage={blobImage} image={'https://images.ctfassets.net/gkhyeghj07ak/7lYcsIDSFPv1tHszS4GL0c/1bb60a2be99a617169fa93d4826a4a97/tus-propositos.svg'} /> */}
                <FlexDivUI justifyContent="center">
                  <img
                    src={icono_rechazar}
                    width={60}
                    alt="Rechazar tratamiento de datos personales"
                  />
                </FlexDivUI>
                <FlexDivUI flexDirection="column" alignContent="center">
                  <h2 className="tratamiento-datos__rejected__title">
                    Elegiste no permitir el tratamiento de tus datos personales.
                    <br />
                    <br />
                    Por lo tanto no podemos continuar con el proceso.
                  </h2>
                </FlexDivUI>
              </div>
              <h3 className="tratamiento-datos__rejected__subtitle">
                ¿Quieres regresar y aprobar el tratamiento de tus datos?
              </h3>
              <div className="tratamiento-datos__body__controls">
                <Button
                  type={"button"}
                  classname="tratamiento-datos__body__controls__button"
                  text="Sí"
                  isDisabled={false}
                  gtmMark="primary"
                  onClickAction={onClickReset}
                />
                <Button
                  onClickAction={onClickReset}
                  type={"button"}
                  classname="tratamiento-datos__body__controls__button"
                  text="No"
                  isDisabled={false}
                  gtmMark="secondary"
                />
              </div>
            </FlexDivUI>
          </WrapperContent>
        ) : (
          <>
            <Otp handleCorrectCode={startDiagnostic} />
          </>
        )}
      </div>
    );
  } else {
    if (flagVality) {
      return <SolicitudPreaprobada />;
    } else {
      return <SolicitudEspera />;
    }
  }
};

export default TerminosCondiciones;
