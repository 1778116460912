import React, { useState, useEffect, useContext } from "react";

import iconNueva from "../../../assets/icons/icon_casa_nueva.svg";
import iconUsada from "../../../assets/icons/icon_casa_usada.svg";

import "./informacionVivienda.scss";
import { useForm } from "react-hook-form";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Loading from "../../atoms/loading/loading";
import ModalError from "../../molecules/modal-error/modalError";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import TitleUI from "../../atoms/title-ui/TitleUI";
import { saveInformacionVivienda } from "../../../services/orquestadorServices";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";
import Input from "../../atoms/input/input";
import currencyFormat from "../../../utils/currencyFormat";
import { AppContext } from "../../../contexts/appContext";

type TFields = {
  type: string;
  value: number;
  scheduledSavings: number;
  comfamaSubsidy: number;
  othersSubsidy: number;
  layoffs: number;
  othersSavings: number;
  othersCredits: number;
  incomeFamilyGroup: string;
  urbanZone: string;
  property: string;
  buyHouse: string;
  beneficiarySubsidy: string;
  applySubsidy: string;
};

type TOption = {
  value: number;
  label: string;
};

type TQuestion = {
  question: string;
  options: TOptionsQuestion[];
};

type TOptionsQuestion = {
  name: keyof TFields;
  value: string;
  title: string;
};

const cardOptions = [
  {
    title: "Nueva",
    value: 1,
    img: iconNueva,
  },
  {
    title: "Usada",
    value: 2,
    img: iconUsada,
  },
];

const questions: TQuestion[] = [
  {
    question: "¿Deseas comprar vivienda nueva ubicada en zona urbana?",
    options: [
      {
        name: "urbanZone",
        value: "1",
        title: "Si",
      },
      {
        name: "urbanZone",
        value: "2",
        title: "No",
      },
    ],
  },
  {
    question: "¿Eres propietario de vivienda?",
    options: [
      {
        name: "property",
        value: "1",
        title: "Si",
      },
      {
        name: "property",
        value: "2",
        title: "No",
      },
    ],
  },
  {
    question:
      "¿Tu o algún integrante de tu grupo familiar ha comprado vivienda con subsidio?",
    options: [
      {
        name: "buyHouse",
        value: "1",
        title: "Si",
      },
      {
        name: "buyHouse",
        value: "2",
        title: "No",
      },
    ],
  },
  {
    question:
      "¿Eres beneficiario del subsidio de vivienda por la caja de compensación?",
    options: [
      {
        name: "beneficiarySubsidy",
        value: "1",
        title: "Si",
      },
      {
        name: "beneficiarySubsidy",
        value: "2",
        title: "No",
      },
    ],
  },
  {
    question: "¿Deseas aplicar algún subsidio del gobierno?",
    options: [
      {
        name: "applySubsidy",
        value: "1",
        title: "Si",
      },
      {
        name: "applySubsidy",
        value: "2",
        title: "No",
      },
    ],
  },
];

const InformacionVivienda = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [total, setTotal] = useState(0);
  const [optionsIncome, setOptionsIncome] = useState<TOption[]>();

  const { setCurrentTab, stateSolicitud } = useContext(AppContext);

  const {
    register,
    watch,
    trigger,
    setValue,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      value: "",
      scheduledSavings: "",
      comfamaSubsidy: "",
      othersSubsidy: "",
      layoffs: "",
      othersSavings: "",
      othersCredits: "",
      incomeFamilyGroup: "",
      urbanZone: "",
      property: "",
      buyHouse: "",
      beneficiarySubsidy: "",
      applySubsidy: "",
    },
  });

  let watchAllFields = watch();

  useEffect(() => {
    let totalTemp = 0;
    totalTemp =
      Number(watchAllFields.scheduledSavings) +
      Number(watchAllFields.comfamaSubsidy) +
      Number(watchAllFields.othersSubsidy) +
      Number(watchAllFields.layoffs) +
      Number(watchAllFields.othersSavings) +
      Number(watchAllFields.othersCredits);
    setTotal(totalTemp);
    if (totalTemp !== 0) {
      clearErrors("scheduledSavings");
    }
  }, [watchAllFields]);

  useEffect(() => {
    setOptionsIncome([
      {
        label: "Hasta 2 smmlv",
        value: 1,
      },
      {
        label: "Hasta 4 smmlv",
        value: 2,
      },
      {
        label: "Hasta 8 smmlv",
        value: 3,
      },
      {
        label: "Más de 8 smmlv",
        value: 4,
      },
    ]);
  }, []);

  const checkValidity = async (
    fields: Array<keyof TFields>,
    next: () => void,
    isLast?: boolean
  ) => {
    let isValid = await trigger(fields as any);
    if (fields.includes("scheduledSavings")) {
      if (total === 0) {
        setError("scheduledSavings", {
          message: "Al menos un campo es requerido",
        });
        setError("comfamaSubsidy", {
          message: "Al menos un campo es requerido",
        });
        setError("othersSubsidy", {
          message: "Al menos un campo es requerido",
        });
        setError("layoffs", { message: "Al menos un campo es requerido" });
        setError("othersSavings", {
          message: "Al menos un campo es requerido",
        });
        setError("othersCredits", {
          message: "Al menos un campo es requerido",
        });
        isValid = false;
      }
    }
    if (isValid) {
      if (isLast) {
        setLoading(true);
        let indexSolicitante =
          stateSolicitud.solicitanteLider.stateSolicitante || 0;
        let idSolicitante = 0;
        if (indexSolicitante === 0) {
          idSolicitante = stateSolicitud.solicitanteLider.solicitante.id || 0;
        } else {
          idSolicitante =
            stateSolicitud.avalistas[indexSolicitante - 1].solicitante.id || 0;
        }
        try {
          await saveInformacionVivienda({
            ...watchAllFields,
            solicitanteId: idSolicitante,
          });
        } catch (error) {
          console.log(error);
          setShowError(true);
        }
        setLoading(false);
        setCurrentTab("referencia");
      } else {
        next();
      }
    }
  };

  const handleChangeMoney = (name: keyof TFields, value: string) => {
    setValue(name, value);
    clearErrors();
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      {showError && (
        <ModalError showModal={showError} setShowModal={setShowError} />
      )}
      <Wizard>
        <WizardTab onNext={(next: () => void) => checkValidity(["type"], next)}>
          <TitleUI as="h1" color="dark" align="center">
            ¿Sabes que tipo de vivienda quieres?
          </TitleUI>
          <TitleUI as="h4" color="dark" align="center">
            Selecciona la opción que más se ajusta a lo que necesitas
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            {Object.keys(cardOptions).length !== 0 ? (
              <CardGroupInput
                options={cardOptions}
                register={register("type", {
                  required: "Campo requerido",
                })}
              />
            ) : (
              ""
            )}
          </div>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) => checkValidity(["value"], next)}
        >
          <div className="o-form__container">
            <TitleUI as="h1" color="dark" align="center">
              ¿Cuál es el valor de la vivienda?{" "}
              <span className="o-title__span">*</span>
            </TitleUI>
            <Input
              register={register("value", {
                required: "Este campo no puede estar vacio",
              })}
              type="money"
              error={errors.value?.message || ""}
              placeholder="Agrega la cifra"
              setValue={(name: keyof TFields, value: string) => {
                handleChangeMoney(name, value);
              }}
              value={watchAllFields.value}
            />
          </div>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(
              [
                "scheduledSavings",
                "comfamaSubsidy",
                "othersSubsidy",
                "layoffs",
                "othersSavings",
                "othersCredits",
              ],
              next
            )
          }
        >
          <div className="o-form__container">
            <TitleUI as="h1" color="dark" align="center">
              ¿Cómo vas a pagar la cuota inicial?{" "}
              <span className="o-title__span">*</span>
            </TitleUI>
            <div className="o-form__content-large">
              <Input
                register={register("scheduledSavings")}
                type="money"
                title="Ahorro programado"
                error={errors.scheduledSavings?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.scheduledSavings}
              />
              <Input
                register={register("comfamaSubsidy")}
                type="money"
                title="Subsidio de vivienda Comfama"
                error={errors.comfamaSubsidy?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.comfamaSubsidy}
              />
              <Input
                register={register("othersSubsidy")}
                type="money"
                title="Otros subsidios"
                error={errors.othersSubsidy?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.othersSubsidy}
              />
              <Input
                register={register("layoffs")}
                type="money"
                title="Cesantías"
                error={errors.layoffs?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.layoffs}
              />
              <Input
                register={register("othersSavings")}
                type="money"
                title="Otros ahorros"
                error={errors.othersSavings?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.othersSavings}
              />
              <Input
                register={register("othersCredits")}
                type="money"
                title="Otros créditos"
                error={errors.othersCredits?.message || ""}
                placeholder="Agrega la cifra"
                setValue={(name: keyof TFields, value: string) => {
                  handleChangeMoney(name, value);
                }}
                value={watchAllFields.othersCredits}
              />
            </div>
            <div className="o-form__total">
              <p>Total:</p>
              <span>{currencyFormat(total)}</span>
            </div>
          </div>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(
              [
                "urbanZone",
                "property",
                "buyHouse",
                "beneficiarySubsidy",
                "applySubsidy",
              ],
              next,
              true
            )
          }
        >
          <div className="o-form__container">
            <TitleUI as="h1" color="dark" align="center">
              Cuéntanos de tus planes de vivienda{" "}
              <span className="o-title__span">*</span>
            </TitleUI>
            <Input
              register={register("incomeFamilyGroup", {
                required: "Este campo no puede estar vacio",
              })}
              type="select"
              title="¿Cuáles son los ingresos de tu grupo familiar?"
              options={optionsIncome}
              error={errors.incomeFamilyGroup?.message || ""}
              placeholder="Selecciona"
            />
            <div className="o-form__container">
              {questions.map((question, index) => (
                <div className="o-form__questions" key={index}>
                  <label className="a-input__title o-form__label">
                    {question.question}
                  </label>
                  <div className="o-form__questions__answers">
                    {question.options.map((option, index) => (
                      <Input
                        register={register(option.name, {
                          required: "Campo requerido",
                        })}
                        key={index}
                        onClick={() => watch(option.name, option.value)}
                        error={errors[option.name]?.message || ""}
                        type={"radio"}
                        name={option.name}
                        title={option.title}
                        value={watchAllFields[option.name]}
                        className={`radio ${
                          watchAllFields[option.name] === option.value
                            ? "selected"
                            : ""
                        }`}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </WizardTab>
      </Wizard>
    </WrapperContent>
  );
};

export default InformacionVivienda;
