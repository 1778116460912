import { useReducer, useState, useEffect } from "react";
import  FormularioIcon from "../../../assets/icons/icono_terminos.svg";
import ValidaAfilicacionIcon from "../../../assets/icons/list-check.svg";
import { AppContext } from "../../../contexts/appContext";
import Divider from "../../atoms/divider/divider";
import MenuCard from "../../atoms/menu-card/menuCard";
import MenuContent from "../../molecules/menu-content/menuContent";
import avatar from "../../../assets/icons/iconosMenu/avatar.svg"
import FormularioRealIcon from "../../../assets/icons/iconosMenu/Formulario.svg"
import comentariosIcon from "../../../assets/icons/iconosMenu/Comentarios.svg"
import datoscreditosIcon from "../../../assets/icons/iconosMenu/Datos_credito_modificados.svg"
import comentariosEstudio from "../../../assets/icons/iconosMenu/Comentarios_de_estudio.svg"
import solicitudDocumentosIcon from "../../../assets/icons/iconosMenu/solicitud_documentos.svg"
import documentosIcon from "../../../assets/icons/iconosMenu/Documentos.svg"
import datosViviendaIcon from "../../../assets/icons/iconosMenu/Datos_vivienda.svg"
import datosInmobiliariaIcon from "../../../assets/icons/iconosMenu/Datos_inmobiliaria.svg"
import datosDesembolsoIcon from "../../../assets/icons/iconosMenu/Datos_desembolso.svg"
import datosLibranzaIcon from "../../../assets/icons/iconosMenu/Datos_libranza.svg"
import FlechaIcono from "../../../assets/icons/iconosMenu/flecha.svg"
import reducerSolicitud, {
    initialSolicitud, TAvalistas
  } from "../../../reducers/reducerSolicitud";
import listarIcon from "../../../assets/icons/list-ul.svg";
import solicitanteIcon from "../../../assets/icons/icon_solicitante adicional.svg";

import './layoutInfoTitular.scss'
import DatosSolicitud from "../datos-solicitud/datosSolicitud";
import DocumentosDashboard from "../documentos-dashboard/documentosDashboard"
import React from "react";
import MenuCardDashboard from "../../atoms/menu-card-dashboard/menuCardDashboard";
import MenuCardTitular from "../../atoms/menu-card-titular/menuCardTitular";
import ValidacionesAfiliacion from "../validacionesAfiliacion/validacionesAfiliacion";
import ComentariosEstudio from "../comentarios-estudio/comentariosEstudio";
import Comentarios from "../comentarios/comentarios";
import InformacionFinanciera from "../informacion-financiera/informacionFinanciera";
import { capitalize } from "../../../utils/capitalizeFirstLetter";
import { getData } from "../../../services/gestionarCreditosServices";
import Formularios from "../formularios/formularios";


interface LayoutProps {
    children?: any;
  }

  const componentsParts: any = {
    DatosDeSolicitud: () => <DatosSolicitud />,
    DatosDeCreditos: () => "Datos de Credito",
    ValidacionDeAfiliaciones: () => <ValidacionesAfiliacion />,
    Formularios: () => <Formularios />,
    ComentarioDeEstudio: () => <ComentariosEstudio />,
    Comentarios: () => <Comentarios />,
    SolicitudDeDocumentos: () => "Solicitud de Documentos",
    Documentos: () => <DocumentosDashboard />,
    DatosDeVivienda: () => "DatosDeVivienda",
    DatosInmobiliaria: () => "DatosInmobiliaria",
    DatosDesembolso: () => "DatosDesembolso",
    DatosLibranza: () => "DatosLibranza",
    ValidacionDeEstudio: () => "Validaciones de Estudio",
    RechazarSolicitud: () => "Rechazar Solicitud",
    PendienteDocumentos:() => "Pendiente Documentos",
    ValidacionAseguradora: () => "Validacion Aseguradora",
    RecomendadoPorAprobar: () => "Recomendando Por aprobar"
  };


const LayoutInfoTitular = ({ children }: LayoutProps) => {

    const [currentTab, setCurrentTab] = useState<string>("DatosDeSolicitud");
    const [isViable, setIsViable] = useState(false);
 // const [menuOpen, setMenuOpen] = useState(false);
  //const [municipios, setMunicipios] = useState([]);
  //const [tipoIdentificacion, setTipoIdentificacion] = useState([]);
  const [formData, setFormData] = useState({ });
  const [solicitante, setSolicitante] = useState({
    id: 0,
    tipoDocumentoId: 0,
    numeroDocumento: 0,
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    lugarNacimiento: "",
    fechaExpedicion: "",
    lugarExpedicion: "",
    estadoCivilId: 0,
    nivelEstudioId: 0,
    personasACargo: 0,
    celular: "",
    otroNumero: "",
    email: "",
    informacionFinanciera: {
        ingreso: 0,
        otroIngresoLaboral: 0,
        otroIngresoNoLaboral: 0,
        cuotaDeducidaNomina: 0,
        cuotaNoDeducidaNomina: 0,
        valorArriendo: 0,
        otroEgreso: 0,
        solicitanteId: 0
    },
    informacionActividadEconomica: {
        actividadEconomica: "",
        empresa: "",
        tipoContrato: "",
        fechaTerminacionContrato: "",
        antiguedadLaboralMeses: 0,
        solicitanteId: 0
    },
    informacionDomicilio: {
        estrato: 0,
        lugarResidencia: "",
        tipoVivienda: "",
        tiempoResidencia: 0,
        tipoVia: "",
        numero1: "",
        letra1: "",
        cuadrante1: "",
        numero2: "",
        letra2: "",
        cuadrante2: "",
        numero3: "",
        informacionAdicional: "",
        solicitanteId: 0,
        lineaCreditoId: 0
    },
    enfermedadSolicitante: []
  });

  const childrenList = [ "ValidacionDeEstudio", "RechazarSolicitud", "PendienteDocumentos", "ValidacionAseguradora", "RecomendadoPorAprobar" ]
  const [visitedTabs, setVisitedTabs]  = useState<Array<string>>(["/", "estadoSolicitud", "DatosDeSolicitud", "estadoSolicitud","DatosDeCreditos", "ValidacionDeAfiliaciones", "Formularios", "ComentarioDeEstudio", "Comentarios", "SolicitudDeDocumento", "Documentos", "DatosDeVivienda", "DatosInmobiliaria", "DatosDesembolso", 
"DatosLibranza", "ValidacionDeEstudio", "RechazarSolicitud", "PendienteDocumentos", "ValidacionAseguradora", "RecomendadoPorAprobar"]); //Hábilita todas las pestañas como visibles.

  const [selectedDash,setSelectedDash] = useState(false)
  const [valueSolicitud, setValueSolicitud] = useState(0);
  const [isRadication, setIsRadication] = useState(false);
  const [stateSolicitud, dispatchSolicitud] = useReducer(
    reducerSolicitud,
    initialSolicitud
  );
  
  const firstMenuItems = [
    {
      title: "Lista de créditos",
      icon: listarIcon,
      value: "/"
    },
    {
      title: "Estado de la Solicitud",
      icon: FormularioIcon,
      value: "ValidacionDeEstudio",
      icon2: FlechaIcono,

      children: [
        {
          title: "Validación de estudio",
          icon: '',
          value: "ValidacionDeEstudio"
        },
        {
          title: "Rechazar solicitud",
          icon: '',
          value: "RechazarSolicitud"
        },
        {
          title: "Pendiente por documentos",
          icon: '',
          value: "PendienteDocumentos"
        },
        {
          title: "Validaciones aseguradora",
          icon: '',
          value: "ValidacionAseguradora"
        },
        {
          title: "Recomendado por aprobar",
          icon: '',
          value: "RecomendadoPorAprobar"
        }

      ]
    },
  ]
  const menuItems = [
    {
      title: "Datos de solicitud",
      icon: FormularioIcon,
      value: "DatosDeSolicitud"
    },
    {
      title: "Datos de crédito",
      icon: datoscreditosIcon,
      value: "DatosDeCreditos",
    },
    {
        title: "Validaciones",
        icon: ValidaAfilicacionIcon,
        value: "ValidacionDeAfiliaciones",
    },
    {
        title: "Formularios",
        icon: FormularioRealIcon,
        value: "Formularios",
    },
    {
        title: "Comentario de estudio",
        icon: comentariosEstudio,
        value: "ComentarioDeEstudio",
    },
    {
        title: "Comentarios",
        icon: comentariosIcon,
        value: "Comentarios",
    },
    {
        title: "Solicitud de documentos",
        icon: solicitudDocumentosIcon,
        value: "SolicitudDeDocumentos",
    },
    {
        title: "Documentos",
        icon: documentosIcon,
        value: "Documentos",
    },
    {
      title: "Datos de Vivienda",
      icon: datosViviendaIcon,
      value: "DatosDeVivienda",
    },
    {
      title: "Datos inmobiliaria",
      icon: datosInmobiliariaIcon,
      value: "DatosInmobiliaria",
    },
    {
      title: "Datos desembolso",
      icon: datosDesembolsoIcon,
      value: "DatosDesembolso",
    },
    {
      title: "Datos libranza",
      icon: datosLibranzaIcon,
      value: "DatosLibranza",
    }
  ];


  const setData = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const setFormIdData = (id: string, value: any) => {
    setFormData({ ...formData, [id]: value });
  };

  const HandleApplicants = (index: number) => {
    console.log(index);

    dispatchSolicitud({
      type: "UPDATE_STATE_SOLICITANTE_LIDER",
      payload: {
        stateSolicitante: index + 1,
      },
    });
  };

  const HandleDataAnalista = async () => {
    
    const lineaCreditoValue = localStorage.getItem("lineaCreditoId")
    const tipoDocumentoId = localStorage.getItem("tipoDocumentoId")
    const numeroDocumento = localStorage.getItem("numeroDocumento")

    await getData(lineaCreditoValue || "", tipoDocumentoId || "", Number(numeroDocumento || 0)).then((res) => {
      
      
      dispatchSolicitud({
        type: "ADD_SOLICITANTE_ANALISTA",
        payload: {
          stateSolicitante:0,
          analista: {
            ...res.data.result.data.solicitantes[0].solicitante,
          },
        },
      });
    })
    
  }

  const handleSetCurrentTab = (tab: any) => {
    if (!visitedTabs.includes(tab)) {
      setVisitedTabs((prev) => [...prev, tab]);
    }
    setCurrentTab(tab);
  };
  useEffect(() => {
    HandleDataAnalista();
  }, []);
  useEffect(() => {
    console.log("stateSolicitud Use Effect: ",stateSolicitud);
  }, [stateSolicitud]);

    return (
        <AppContext.Provider
        value={{
            currentTab,
            setCurrentTab: handleSetCurrentTab,
            visitedTabs,
            formData,
            solicitante,
            setData,
            setFormIdData,
            stateSolicitud,
            dispatchSolicitud,
            isViable,
            setIsViable,
            valueSolicitud,
            setValueSolicitud,
            isRadication,
            setIsRadication,
        }}
        >
        <div
        className={`o-layout-container`}
      >
        <aside
          className={`o-layout-container__sidebar helper-layout-sidebar`}
        >
          <div className="first-part-aside">
          {stateSolicitud.stateSolicitante !== -1  && (
              <>
                <div className="o-layout-container__sidebar--profile">
                  <div className="o-layout-container__sidebar--profile--img">
                    <img
                      src={avatar}
                      className="o-layout-container__siderbar-profile-icon-img"
                      alt="PerfilUsuario"
                      width={50}
                    />
                  </div>
                  {stateSolicitud.stateSolicitante === 0 && (
                    <div className="o-layout-container__sidebar--profile--info">
                      <p className="o-layout-container__sidebar--profile--info--nombre">
                        {`${capitalize(stateSolicitud.analista.solicitante.primerNombre)} ${capitalize(stateSolicitud.analista.solicitante.primerApellido)}`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--cedula">
                        C.C{" "}
                        {`${stateSolicitud.analista.solicitante.numeroDocumento}`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--titular">
                        Titular
                      </p>
                      <hr />
                    </div>
                  )}
                  {(stateSolicitud.analista.stateSolicitante === 1 ||
                    stateSolicitud.analista.stateSolicitante === 2) && (
                    <div className="o-layout-container__sidebar--profile--info">
                      <p className="o-layout-container__sidebar--profile--info--nombre">
                        
                        {`${capitalize(
                          stateSolicitud.avalistas[
                            stateSolicitud.analista.stateSolicitante - 1
                          ].formData.primerNombre)
                        } ${
                          capitalize(stateSolicitud.avalistas[
                            stateSolicitud.analista.stateSolicitante - 1
                          ].formData.primerApellido)
                        }`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--cedula">
                        C.C{" "}
                        {`${
                          stateSolicitud.avalistas[
                            stateSolicitud.analista.stateSolicitante - 1
                          ].formData.numeroDocumento
                        }`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--adicional">
                        Solicitante adicional{" "}
                        {stateSolicitud.analista.stateSolicitante}
                      </p>
                      <p
                        className="o-layout-container__sidebar--profile--info--adicionalTitular"
                        onClick={() => HandleApplicants(-1)}
                      >
                        Titular:{" "}
                        {`${stateSolicitud.analista.primerNombre} ${stateSolicitud.analista.primerApellido}`}
                      </p>
                      <hr />
                    </div>
                  )}
                </div>

                {stateSolicitud.avalistas.length > 0 && (
                  <div className="o-layout-container__sidebar--adicional">
                    <div className="o-layout-container__sidebar--adicional__Container">
                      <div className="o-layout-container__sidebar--adicional__Container-left">
                        <img
                          src={solicitanteIcon}
                          alt="PerfilUsuario"
                          width={24}
                        />
                      </div>
                      <div className="o-layout-container__sidebar--adicional__Container-title">
                        <p>Solicitantes adicionales:</p>
                      </div>
                    </div>
                    {stateSolicitud.avalistas.map(
                      (item: TAvalistas, index: number) => {
                        if (
                          index !==
                          stateSolicitud.analista.stateSolicitante - 1
                        ) {
                          return (
                            <div
                              key={index}
                              className="o-layout-container__sidebar--adicional__Container"
                            >
                              <div className="o-layout-container__sidebar--adicional__Container-left"></div>
                              <div
                                className="o-layout-container__sidebar--adicional__Container-right"
                                onClick={() => HandleApplicants(index)}
                              >
                                <p>{`${capitalize(item.formData.primerNombre) || ""} ${
                                  capitalize(item.formData.primerApellido) || ""
                                }`}</p>
                              </div>
                            </div>
                          );
                        }
                      }
                    )}
                  </div>
                )}
              </>
            )}
            <Divider
                      dividerWidth="100%"
                      dividerColor="#E5E5E5"
                      dividerMargin=""
            />
          {firstMenuItems.map((item, idx) => (
            <div className="o-infolayoutTitular-helper" key={idx}>
            <MenuCardDashboard
              title={item.title}
              icon={item.icon}
              value={currentTab}
              setValue={setCurrentTab}
              index={item.value}
              shouldDisplayChildren={childrenList.includes(currentTab) ? true : false }
              isredirect={idx === 0 ? true : false}
              item={item}
              selected={selectedDash}
              setSelected={setSelectedDash}
              icon2={item.icon2}
            >
              {item.children && selectedDash &&
                item.children.map((child) => (
                  <React.Fragment key={child.value}>
                    <MenuCardTitular
                      key={item.value}
                      title={child.title}
                      icon={child.icon}
                      value={currentTab}
                      setValue={setCurrentTab}
                      index={child.value}
                      isChild={true}
                      item={child}
                    />
                    
                  </React.Fragment>
                ))}
            </MenuCardDashboard>
            <Divider
                      dividerWidth="100%"
                      dividerColor="#E5E5E5"
                      dividerMargin=""
            />
            </div>
          ))}
          </div>
          <div className="helper-layout">
          {menuItems.map((item, idx) => (
            <div className="o-infolayout-helper" key={idx}>
            <MenuCard
              title={item.title}
              icon={item.icon}
              value={currentTab}
              setValue={handleSetCurrentTab}
              index={item.value}
              shouldDisplayChildren={true}
              isChild={true}
              item={item}
              hiddenModal={true}
            >
            <Divider
                      dividerWidth="100%"
                      dividerColor="#E5E5E5"
                      dividerMargin=""
            />
            </MenuCard>
            </div>
          ))}
          </div>
        </aside>
        <main className="o-layout-container__main">
          {/*TODO: Parte superior del componente*/}
          <MenuContent>{componentsParts[currentTab]()}</MenuContent>
        </main>
      </div>

        </AppContext.Provider>
    )

}


export default LayoutInfoTitular;