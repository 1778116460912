import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../button/button";
import FlexDivUI from "../flexible-div-ui/FlexDivUI";
import Modal from "../modal/modal";
import TitleUI from "../title-ui/TitleUI";
import "./menuCard.scss";

import rechazarIcon from "../../../assets/icons/Layout/icono_solicitud rechazada.svg";

type item = {
  title: string;
  icon: string;
  value: string;
  children?: Array<item>;
};
interface MenuProps {
  icon: any;
  title: string;
  value: any;
  setValue: any;
  index: any;
  children?: any;
  isChild?: boolean;
  shouldDisplayChildren?: boolean;
  item: item;
  hiddenModal?:boolean;
  parentTabs?: boolean
}

const MenuCard = ({
  item,
  icon,
  title,
  value,
  index,
  setValue,
  children,
  isChild = false,
  shouldDisplayChildren = false,
  hiddenModal = false,
  parentTabs = false
}: MenuProps) => {
  const [shouldSelect, setShouldSelect] = useState(false);
  const { visitedTabs, stateSolicitud } = useContext(AppContext);
  const [flagModal, setFlagModal] = useState(false);

  useEffect(() => {
    if (item.children) {
      const found = item.children.find((child) => child.value === value);
      if (found) setShouldSelect(true);
      else setShouldSelect(false);
    }
  }, [value]);

  const print = () => {
    if (parentTabs) {
      setFlagModal(true);
    }
    else if (hiddenModal) {
      HandlevisitedTabs();
    }
    else{
      if (stateSolicitud.solicitanteLider.solicitante.action == "RADICAR") {
        HandlevisitedTabs();
      }
      else{
        if (stateSolicitud.solicitanteLider.stateSolicitante) {
          if (stateSolicitud.solicitanteLider.stateSolicitante != -1) {
              if (
                stateSolicitud.avalistas[
                  stateSolicitud.solicitanteLider.stateSolicitante - 1
                ].solicitante.tyCFirmado
              ) {
                HandlevisitedTabs();
              } else {
                setFlagModal(true);
              }
          }
        } else {
          if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
              if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
                HandlevisitedTabs();
              } else {
                setFlagModal(true);
              }
          }
        }
      }
    }
   
   
    // Bloquea las secciones a las cuales no puede continuar pero si volver
    // if (!visitedTabs.includes(index)) {
    //     return;
    // }
    // console.log(value + ' : ' + index);
    // setValue(index);
  };

  const HandlevisitedTabs = () => {
    setValue(index);
    setFlagModal(false);
  };

  return (
    <>
      <Modal open={flagModal} handleClose={() => setFlagModal(false)}>
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">
            <img src={rechazarIcon} />
          </TitleUI>
          <TitleUI align="center">¿Estás seguro que deseas salir?</TitleUI>
          <div style={{ padding: "0px 30px" }}>
            <TitleUI color="dark" as="h3" align="center">
              Al salir del formulario los datos que ingresaste no quedarán
              guardados, por lo tanto deberas comenazar nuevamente
            </TitleUI>
          </div>
          <div className="o-Layout__Modal">
            <div className="o-Layout__Modal__Button">
              <Button
                text={"Continuar"}
                type="button"
                classname=""
                gtmMark="secondary"
                isDisabled={false}
                onClickAction={() => HandlevisitedTabs()}
              />
            </div>
            <div className="o-Layout__Modal__Button">
              <Button
                text={"Cancelar"}
                type="button"
                classname=""
                gtmMark="secondary"
                isDisabled={false}
                onClickAction={() => setFlagModal(false)}
              />
            </div>
          </div>
        </FlexDivUI>
      </Modal>

      <div
        className={`m-menuCard-container ${
          isChild ? "m-menuCard-container--child" : ""
        } 
                    ${
                      value === index || shouldSelect
                        ? `m-menuCard-container--selected${
                            isChild ? "-child" : ""
                          }`
                        : ""
                    }`}
      >
        <div
          onClick={() => print()}
          className={`m-menuCard-container__content`}
        >
          <img src={icon} alt={title} width={24} />
          <p>{title}</p>
        </div>
        {shouldDisplayChildren && (
          <div className={`m-menuCard-container__children`}>{children}</div>
        )}

        {/* {
                (children && shouldDisplayChildren && (value === index || value.toString().split('.')[0] === index + '')) &&
                <div className="m-menuCard-container__children">
                    {children}
                </div>
            } */}
      </div>
    </>
  );
};

export default MenuCard;
