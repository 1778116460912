import React from "react";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Button from "../../atoms/button/button";

type TProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  title?: string;
  message?: string;
  onClick?: any;
};

const ModalError = ({
  showModal,
  setShowModal,
  title = "Ha ocurrido un error",
  message = "No se ha podido procesar la solicitud.",
  onClick,
}: TProps) => {
  return (
    <Modal open={showModal} handleClose={() => setShowModal(false)}>
      <FlexDivUI alignContent="center" flexDirection="column">
        <TitleUI align="center">{title}</TitleUI>
        <TitleUI color="dark" as="h3" align="center">
          {message}
        </TitleUI>
        <Button
          text={"Cerrar"}
          type="button"
          classname=""
          gtmMark="secondary"
          isDisabled={false}
          onClickAction={() => {
            setShowModal(false);
            onClick && onClick();
          }}
        />
      </FlexDivUI>
    </Modal>
  );
};

export default ModalError;
