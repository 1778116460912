import React, { useContext } from "react";
import { AppContext } from "../../../contexts/appContext";
import TitleUI from "../../atoms/title-ui/TitleUI";
import CardGroupInput from "../card-group-input/cardGroupInput";

type TOptions = {
  title: string;
  value: string;
  img: string;
};
type TProps = {
  title: string;
  options: TOptions[];
  HandleRegister: any;
  valueOption: string
};
const CheckoxContent = (props: TProps) => {
  const { title, options, HandleRegister, valueOption }: TProps = props;
  const { setCurrentTab, stateSolicitud, dispatchSolicitud } =
    useContext(AppContext);

    console.log(valueOption);
    
  return (
    <>
      <TitleUI as="h2" color="dark" align="center">
        {title}
      </TitleUI>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        {Object.keys(options).length !== 0 ? (
          <CardGroupInput
            options={options}
            register={HandleRegister}
            value={valueOption}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CheckoxContent;
