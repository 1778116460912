import React from 'react'
import './form.scss'

const Form = (props: any) => {
    return (
        <form
            className="a-form"
        >{props.children}</form>
    )
}

export default Form