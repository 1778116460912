import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import './inputForm.scss';

interface IInputForm {
  title: string;
  value: string;
  nameId: string;
  type: 'select' | 'text' | 'text-area' | 'number' | 'predict' | 'date';
  options?: Array<any>;
  isError?: boolean;
  errorMessage?: string;
  setCodeType?: any;
  setCodeTheme?: any;
  setCodeTypeDoc?: any;
  gigyaAccount?: any;
  placeholder?: any;
  handleChange: (value: any, name?: string) => void;
  className?: string;
}

const InputForm = (props: IInputForm) => {

  const node: MutableRefObject<any> = useRef();

  const {
    title,
    value,
    nameId,
    type,
    options,
    isError,
    errorMessage,
    gigyaAccount,
    handleChange,
    placeholder,
    className
  } = props;

  const [isActive, setActiveSelect] = useState(false);
  const [keyCode, setkeyCode] = useState(false)

  const handleClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      return;
    } else {
      setActiveSelect(false);
    }
  };

  const changeHandler = (e: any) => {
    handleChange(e.target.value, nameId)
  }

  const validateNumber = (event: any) => {
    if (event.target.value <= 10000000000 && !keyCode) {
      handleChange(event.target.value, nameId)
    }
  }

  useEffect(() => {
    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  const inputTypeComponent = () => {
    switch (type) {
      case 'select':
        return (
          <>
            <label htmlFor={"select_" + nameId} className={'a-input__form__hidden hide_text'}>{title}</label>
            <select
              className='a-input__form a-input__form-select'
              id={"select_" + nameId}
              onClick={() => { gigyaAccount && (["gender", "idNumType"].includes(nameId)) ? setActiveSelect(false) : setActiveSelect(!isActive) }}
              ref={node}
              value={value}
              onChange={changeHandler}
            >
              <>
                <option className="a-input__form a-input__form-select" key={0} value="">&nbsp;</option>
                {(options || []).map((option, index) => {
                  return (
                    <option className="a-input__form a-input__form-select" key={index} value={option} >
                      {option}
                    </option>
                  )
                })}
              </>
            </select>
          </>
        )
      case 'text':
        return (
          <>
            <label htmlFor={nameId} className={'a-input__form__hidden hide_text'}>{title}</label>
            <input className={`a-input__form__field${isError ? ' a-input__form__field--error' : ''}`}
              id={nameId}
              name={title}
              type={nameId === "email" ? "email" : type}
              value={value}
              disabled={(["email", "idNum", "personName"].includes(nameId))}
              maxLength={nameId === "idNum" ? 20 : 40}
              onChange={
                (event) => handleChange(event.target.value, nameId)
              }
              placeholder={placeholder ? placeholder : ""}
            />
          </>
        )
      case 'number':
        return (
          <>
            <label htmlFor={nameId} className={'a-input__form__hidden hide_text'}>{title}</label>
            <input className={`a-input__form__field${isError ? ' a-input__form__field--error' : ''}`}
              id={nameId}
              name={title}
              type={"text"}
              value={value}
              pattern="[0-9]+"
              onKeyDown={(e) => e.keyCode === 69 ? setkeyCode(true) : setkeyCode(false)}
              disabled={(nameId === "phone" || nameId === "cellphone")}
              onChange={
                (event) => validateNumber(event)
              }
            />
          </>
        )
      case 'text-area':
        return (
          <textarea className={`a-input__form__field ${className}`}
            rows={5}
            cols={50}
            maxLength={800}
            id={nameId}
            name={title}
            onChange={
              (event) => handleChange(event.target.value, nameId)
            }
            placeholder={placeholder}
          />
        )

      case 'date':
        return (
          <>
            <label htmlFor={nameId} className={'a-input__form__hidden hide_text'}>{title}</label>
            <input
              type="date"
              id={nameId === "birthDate" ? "birthDate" : ""}
              name={title}
              value={value}
              onChange={
                (e) => handleChange(e.target.value, nameId)
              }
              onKeyDown={(e) => e.preventDefault()}
              disabled={gigyaAccount && ["birthDate"].includes(nameId) ? true : false}
            />
          </>
        )
    }
  }

  return (
    <div className={`a-input__form a-input__form-${type}`}>
      {inputTypeComponent()}
      {isError && <span className="a-input__form__title__error" >{errorMessage}</span>}
    </div>
  )
}

export default InputForm
