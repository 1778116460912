import React, { useEffect, useState } from "react";
import { TSolicitud } from "../reducers/reducerSolicitud";
type TAppContext = {
  currentTab: any;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  formData: any;
  solicitante:any;
  setData: any;
  setFormIdData: any;
  stateSolicitud: TSolicitud;
  dispatchSolicitud: any;
  visitedTabs: Array<string>;
  isViable: boolean;
  setIsViable: any;
  valueSolicitud: number;
  setValueSolicitud: React.Dispatch<React.SetStateAction<number>>;
  isRadication: boolean;
  setIsRadication: React.Dispatch<React.SetStateAction<boolean>>;
  // datosSolicitud: any;
  // setDatosSolicitud: React.Dispatch<React.SetStateAction<any>>;
};
export const AppContext = React.createContext<TAppContext>({} as TAppContext);
