import React from 'react'
import './progressBar.scss'

interface ProgressBarProps {
    percentage: number;
    onClick: any;
    total?: number;
    simple?: boolean;
    title: string;
}

const ProgressBar = (props: ProgressBarProps) => {

    const { percentage, onClick, title } = props;

    return (
        <div className={`a-progress-bar ${props.simple ? 'a-progress-bar--simple' : ''}`}>
            {/* {!props.simple &&
                <div className="a-progress-bar__button" onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                </div>
            } */}
            <div className="a-progress-bar__progress-container">
                <h4>{title}</h4>
                {!props.simple && <p>{percentage + '% completado'}</p>}
                <div className="a-progress-bar__progress-container__progress-bar">
                    <div
                        className="a-progress-bar__progress-container__progress-bar__progress"
                        style={{ width: `${percentage}%` }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;