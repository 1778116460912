import React, { useState } from "react";
import "./stepper.scss";

interface StepperProps {
  steps: Array<{ title: string; value: any; icon?: any }>;
  currentStep: string;
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  const currentStepIndex = (value: string) => {
    return steps.findIndex((step) => step.value === value);
  };
  return (
    (currentStep === "listaDeCreditos" || currentStep === "gestionarTusCreditos") ?
      <></> :
      <div className="a-stepper-container">
        <div className="a-stepper-container__box">
        {steps.map((step, idx) => (
          <React.Fragment key={idx}>
            <div
              className={`a-stepper-container__box__step ${
                currentStep === step.value
                  ? "a-stepper-container__box__step--active"
                  : ""
              } 
                            ${
                              idx < currentStepIndex(currentStep)
                                ? "a-stepper-container__box__step--checked"
                                : ""
                            }`}
            >
              {idx < currentStepIndex(currentStep) ? (
                <span>&#10003;</span>
              ) : (
                <h3>{idx + 1}</h3>
              )}
              {currentStep === step.value && <p>{step.title}</p>}
            </div>
            {idx < steps.length - 1 && (
              <p
                className={`a-stepper-container__box__line ${
                  idx < currentStepIndex(currentStep)
                    ? "a-stepper-container__box__line--active"
                    : ""
                }`}
              ></p>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
