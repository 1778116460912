import React, { useState } from "react";
import PanelFormulario from "../panelFormulario/panelFormulario";

import "./acordeonFormulario.scss";

interface AcordeonProps {
  title: string;
  data?: [string, string][];
  data2?: any;
  position2?: number;
  data2Name?: string;
  dataList?: any[];
  editable?: boolean;
  backgroundInput?: number[];
  img?: any;
  imgInputs?: number[];
}

const AcordeonFormulario = ({
  title,
  data,
  data2,
  position2,
  data2Name,
  dataList,
  editable,
  backgroundInput,
  img,
  imgInputs,
}: AcordeonProps) => {
  const [classAcordeon, setClassAcordeon] = useState("");
  const [maxHeightAcordeon, setMaxHeightAcordeon] = useState(0);

  const HandleActive = () => {
    if (classAcordeon === "") {
      setClassAcordeon("active");
      setMaxHeightAcordeon(500);
    } else {
      setClassAcordeon("");
      setMaxHeightAcordeon(0);
    }
  };

  return (
    <div className="o-AcordeonFormulario">
      <button
        className={`accordion ${classAcordeon}`}
        onClick={() => HandleActive()}
      >
        {title}
      </button>
      <div
        className="o-AcordeonFormulario__Panel"
        style={{ maxHeight: `${maxHeightAcordeon}px` }}
      >
        {dataList?.map((element: any, indexList: number) => {
          let dataElement = Object.entries(element);
          return (
            <div key={indexList}>
              {dataElement?.map((item: any, index: number) => {
                let titleData = item[0];
                return (
                  <div
                    className="o-AcordeonFormulario__Panel__Formulario"
                    key={index}
                  >
                    {item.map((item2: any, index2: number) => {
                      if (index2) {
                        if (data2Name != undefined && index === position2) {
                          return (
                            <PanelFormulario
                              title={titleData}
                              text={item2 != "null" ? item2 : ""}
                              text2={`${data2Name}: ${data2}`}
                              editable={editable}
                              backgroundInput={
                                backgroundInput?.find(
                                  (element) => element == index
                                ) != undefined
                              }
                            />
                          );
                        }
                        return (
                          <PanelFormulario
                            title={titleData}
                            text={item2 != "null" ? item2 : ""}
                            editable={editable}
                            backgroundInput={
                              backgroundInput?.find(
                                (element) => element == index
                              ) != undefined
                            }
                          />
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}

        {data?.map((item, index) => {
          let titleData = item[0];
          return (
            <div
              className="o-AcordeonFormulario__Panel__Formulario"
              key={index}
            >
              {item.map((item2, index2) => {
                if (index2) {
                  if (data2Name != undefined && index === position2) {
                    return (
                      <div className="o-AcordeonFormulario__Panel__Formulario__Container">
                        {imgInputs?.find((element) => element == index) !=
                          undefined && <img src={img} alt="" width="50"/>}
                        <PanelFormulario
                          title={titleData}
                          text={item2 != "null" ? item2 : ""}
                          text2={`${data2Name}: ${data2}`}
                          editable={editable}
                          backgroundInput={
                            backgroundInput?.find(
                              (element) => element == index
                            ) != undefined
                          }
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="o-AcordeonFormulario__Panel__Formulario__Container">
                      {imgInputs?.find((element) => element == index) !=
                        undefined && <img src={img} alt="" width="50"/>}
                      <PanelFormulario
                        title={titleData}
                        text={item2 != "null" ? item2 : ""}
                        editable={editable}
                        backgroundInput={
                          backgroundInput?.find(
                            (element) => element == index
                          ) != undefined
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AcordeonFormulario;
