import React, { useState, useEffect, useContext } from "react";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Loading from "../../atoms/loading/loading";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import TitleUI from "../../atoms/title-ui/TitleUI";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";

import iconPersonal from "../../../assets/icons/icon_referencias_personales.svg";
import iconFamiliar from "../../../assets/icons/icon_referencias_familiares.svg";

import "./referencia.scss";

import { useForm } from "react-hook-form";
import Input from "../../atoms/input/input";
import ParametrizacionContext from "../../../contexts/parametrizacionContext";
import ModalError from "../../molecules/modal-error/modalError";
import { saveReferencias } from "../../../services/orquestadorServices";
import { AppContext } from '../../../contexts/appContext';

type TFields = {
  type: string;
  firstNameOne: string;
  firstNameTwo: string;
  lastNameOne: string;
  lastNameTwo: string;
  phone: string;
  mobilePhone: string;
  relationship: string;
  department: string;
  city: string;
};

type TOption = {
  value: number;
  label: string;
};

const cardOptions = [
  {
    title: "Agregar referencia familiar.",
    value: 1,
    img: iconFamiliar,
  },
  {
    title: "Agregar referencia personal.",
    value: 2,
    img: iconPersonal,
  },
];

const Referencia = () => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsRelationship, setOptionsRelationship] = useState<TOption[]>();
  const [optionsCities, setOptionsCities] = useState<TOption[]>();
  const { municipios } = useContext(ParametrizacionContext);
  const [textTitle, setTextTitle] = useState("");
  const [showError, setShowError] = useState(false);
  const { stateSolicitud } = useContext(AppContext);

  const {
    register,
    watch,
    trigger,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      firstNameOne: "",
      firstNameTwo: "",
      lastNameOne: "",
      lastNameTwo: "",
      phone: "",
      mobilePhone: "",
      relationship: "",
      department: "",
      city: "",
    },
  });

  let watchAllFields = watch();
  let type = watch("type");

  useEffect(() => {
    const tempCities = municipios?.map((municipio) => ({
      label: `${municipio.nombre} - ${municipio.departamento}`,
      value: municipio.codigoDane,
    }));
    setOptionsCities(tempCities);
  }, [municipios]);

  useEffect(() => {
    if (type) {
      setIsOpen(true);
    }
    if (type === "2") {
      setTextTitle("Referencia personal");
      setOptionsRelationship([
        {
          value: 6,
          label: "OTROS NO FAMILIARES",
        },
      ]);
    } else {
      setTextTitle("Referencia familiar");
      setOptionsRelationship([
        {
          value: 1,
          label: "ESPOSO",
        },
        {
          value: 2,
          label: "HIJO",
        },
        {
          value: 3,
          label: "PADRE MADRE",
        },
        {
          value: 4,
          label: "HERMANO",
        },
        {
          value: 5,
          label: "OTROS FAMILIARES",
        },
      ]);
    }
  }, [type]);

  useEffect(() => {
    if (optionsRelationship?.length) {
      setValue("relationship", "6");
    }
  }, [optionsRelationship]);

  const checkValidity = async (next: () => void) => {
    let fields: Array<keyof TFields> = [
      "type",
      "firstNameOne",
      "firstNameTwo",
      "lastNameOne",
      "lastNameTwo",
      "phone",
      "mobilePhone",
      "relationship",
      "department",
      "city",
    ];
    const isValid = await trigger(fields as any);
    let indexSolicitante =
      stateSolicitud.solicitanteLider.stateSolicitante || 0;
    let idSolicitante = 0;
    if (indexSolicitante === 0) {
      idSolicitante = stateSolicitud.solicitanteLider.solicitante.id || 0;
    } else {
      idSolicitante =
        stateSolicitud.avalistas[indexSolicitante - 1].solicitante.id || 0;
    }
    console.log(isValid);
    if (isValid) {
      setLoading(true);
      console.log(watchAllFields);
      try {
        await saveReferencias({
          ...watchAllFields,
          solicitanteId: idSolicitante,
        });
      } catch (error) {
        console.log(error);
        setShowError(true);
      }
      setLoading(false);
    }
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      {showError && (
        <ModalError showModal={showError} setShowModal={setShowError} />
      )}
      <Wizard>
        <WizardTab onNext={(next: () => void) => checkValidity(next)}>
          <TitleUI as="h1" color="dark" align="center">
            Ingresa una referencia <span className="o-title__span ">*</span>
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            {Object.keys(cardOptions).length !== 0 ? (
              <CardGroupInput
                options={cardOptions}
                register={register("type", {
                  required: "Campo requerido",
                })}
              />
            ) : (
              ""
            )}
          </div>
          {isOpen && (
            <div className="o-form">
              <div className="o-form__title">
                <TitleUI as="h2" color="dark" align="left">
                  {textTitle}
                </TitleUI>
              </div>
              <div className="o-form__content">
                <Input
                  register={register("firstNameOne", {
                    required: "Este campo no puede estar vacio",
                  })}
                  type="text"
                  title="Primer Nombre *"
                  error={errors.firstNameOne?.message || ""}
                  placeholder="Ingresa el nombre"
                />
                <Input
                  register={register("firstNameTwo")}
                  type="text"
                  title="Segundo Nombre"
                  error={errors.firstNameTwo?.message || ""}
                  placeholder="Ingresa el nombre"
                />
                <Input
                  register={register("lastNameOne", {
                    required: "Este campo no puede estar vacio",
                  })}
                  type="text"
                  title="Primer Apellido *"
                  error={errors.lastNameOne?.message || ""}
                  placeholder="Ingresa el apellido"
                />
                <Input
                  register={register("lastNameTwo")}
                  type="text"
                  title="Segundo Apellido"
                  error={errors.lastNameTwo?.message || ""}
                  placeholder="Ingresa el apellido"
                />
                <Input
                  register={register("relationship", {
                    required: "Campo requerido",
                  })}
                  title="Parentesco *"
                  options={optionsRelationship}
                  error={errors.relationship?.message || ""}
                  type={"select"}
                  placeholder={"Parentesco"}
                />
                <Input
                  register={register("city", {
                    required: "Campo requerido",
                  })}
                  title="¿Donde vive? *"
                  options={optionsCities}
                  error={errors.city?.message || ""}
                  type={"select"}
                  placeholder={"¿Donde vive?"}
                />
                <Input
                  register={register("phone")}
                  type="number"
                  title="Teléfono fijo"
                  error={errors.phone?.message || ""}
                  placeholder="Ingresa el teléfono"
                />
                <Input
                  register={register("mobilePhone", {
                    required: "Este campo no puede estar vacio",
                  })}
                  type="text"
                  title="Teléfono celular *"
                  error={errors.mobilePhone?.message || ""}
                  placeholder="Ingresa el número de celular"
                />
              </div>
            </div>
          )}
        </WizardTab>
      </Wizard>
    </WrapperContent>
  );
};

export default Referencia;
