import React from 'react'

interface DividerProps {
    dividerWidth: string;
    dividerColor: string;
    dividerMargin: string;
}

const Divider = ({ dividerWidth, dividerColor, dividerMargin }: DividerProps) => {
    return (
        <div style={{ width: "100%", maxWidth: dividerWidth, backgroundColor: dividerColor, height: '1px', margin: dividerMargin }}></div>
    )
}

export default Divider