import React from 'react'
import './iconText.scss'

interface IconTextProps {
    text: string;
    src: string;
    width?: number;
}

const IconText = (props: IconTextProps) => {
    const { text, src, width = 30 } = props;

    return (
        <div className="mentoria-icon-title">
            <img className="mentoria-icon-title__img" src={src} alt={text} width={width} />
            <h3>{text}</h3>
        </div>
    )
}

export default IconText