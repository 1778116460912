import axios from "./axiosConfig";

export const getValidation = (
  documentType: string,
  documentNumber: number,
) => {
  return axios.get(
    `${process.env.REACT_APP_VIABILIDAD_API}?identification=${documentNumber}&documentType=${documentType}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};

export const getValidationRadication = (
  documentType: string,
  documentNumber: number,
  applicantType: number = 1,
) => {
  return axios.get(
    `${process.env.REACT_APP_VIABILIDAD_API}?identification=${documentNumber}&documentType=${documentType}&tipoSolicitante=${applicantType}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};

