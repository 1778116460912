import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./datalist.scss";
import searchIcon from "../../../assets/icons/arrow_down.svg";
import { useController, UseControllerProps } from "react-hook-form";

type TDatalist = {
  name: string;
  options: Array<{ label: string; value: any }>;
  title?: string;
  valueSelect?: { label: string; value: any };
};

interface DatalistProps extends UseControllerProps, TDatalist {}

const DatalistV2 = (props: DatalistProps) => {
  const { field, fieldState } = useController(props);
  const { title, options, name, valueSelect } = props;
  const error = fieldState.error?.message || "";
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(valueSelect);
  const [filteredOptions, setFilteredOptions] = useState<
    Array<{ label: string; value: any }>
  >([]);
  const [searchValue, setSearchValue] = useState(String(valueSelect?.value));
  const ref: any = useRef(null);
  const inputRef: any = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      searchValue
        ? options.filter((option) =>
            option.label.toUpperCase().includes(searchValue.toUpperCase())
          )
        : options
    );
  }, [searchValue, options]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current?.contains(event.target)) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(valueSelect);
    if (String(valueSelect?.value) != "undefined") {
      setSearchValue(String(valueSelect?.value));
    } else {
      setSearchValue("");
    }
  }, [valueSelect]);

  const handleClose = () => {
    setSearchValue("");
    setIsOpen(false);
  };

  const handleOpen = () => {
    if (isOpen) {
      setSearchValue("");
    }

    setIsOpen(!isOpen);
  };

  const handleOnChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchValue(e.target.value);
  };

  const handleOptionClick = (option: { label: string; value: any }) => {
    setSearchValue("");
    setValue(option);
    field.onChange(option.value);
    setIsOpen(false);
  };

  return (
    <div className="a-datalist" ref={ref}>
      {title && (
        <label
          className={`a-datalist__title ${
            error ? "a-datalist__title--error" : ""
          }`}
        >
          {title}
        </label>
      )}
      <button
        onClick={handleOpen}
        className={`a-datalist__button ${
          error ? "a-datalist__button--error" : ""
        }`}
        style={{ color: field.value ? "#303030" : "gray" }}
        type="button"
      >
        {value?.label || "Selecciona"}
        <img
          src={searchIcon}
          alt={isOpen ? "Cerrar" : "Abrir"}
          className={`a-datalist__button__search-icon ${
            isOpen ? "a-datalist__button__search-icon--open" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="a-datalist__section">
          <input
            className="a-datalist__section__hidden"
            type="text"
            {...field}
          ></input>
          <input
            type="text"
            name="search"
            value={searchValue}
            placeholder="BUSCAR"
            onChange={handleOnChange}
            autoComplete="off"
          />
          <div className="a-datalist__section__options">
            {filteredOptions.map((option) => (
              <span
                className="a-datalist__section__options__option"
                onClick={() => handleOptionClick(option)}
                key={option.value}
              >
                {option.label}
              </span>
            ))}
          </div>
        </div>
      )}
      {error && <span className="a-datalist__error">{error}</span>}
    </div>
  );
};

export default DatalistV2;
