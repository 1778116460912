import React, { useState } from 'react';
import Button from '../../atoms/button/button';
import Divider from '../../atoms/divider/divider';
import InputForm from '../../atoms/input-form/inputForm';
import DisplayBadge from '../../molecules/display-badge/displayBadge';
import Table from '../../molecules/table/table';
import "./listaCreditos.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from '../../atoms/input/input';
import { getSolicitudByDocument } from '../../../services/orquestadorServices'
import Loading from "../../atoms/loading/loading";

type Inputs = {
    document: number;
}

const ListaCreditos = () => {
    const [formData, setFormData] = useState<any>({
        document: "",
        linea: "",
    });
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm<Inputs>();
    const [showData, setShowData] = useState(false)
    const [loading, setLoading] = useState(false);
    const [datosSolicitud, setDatosSolicitud] = useState<any>([])
    const [solicitudes, setSolicitudes] = useState<any>([])

    console.log(watch("document"));

    const handleInputChange = (value: any, target: any) => {
        setFormData((prev: any) => ({ ...prev, [target]: value }))
    }

    const handleSearch = (data: SubmitHandler<Inputs>) => {
        return console.log(data);
    }

    const onSubmit: SubmitHandler<Inputs> = async data => {
        setLoading(true);
        try {
            await getSolicitudByDocument(data.document).then((res) => {
                setDatosSolicitud(res.data.result);
                setSolicitudes(res.data.result.solicitudes)
                setShowData(true);
            })
        } catch (error) {
            console.log(error);
            // setShowError(true);
        }
        setLoading(false);        
    }

    const handleClear = () => {
        reset();
    }

    const headers = [
        {
            title: "Número de solicitud",
            value: "numeroSolicitud"
        },
        {
            title: "Detalle",
            value: "detalle",
            type: "image"
        },
        {
            title: "Viabilidad",
            value: "viabilidad"
        },
        {
            title: "Estado",
            value: "estado"
        },
        {
            title: "Fecha",
            value: "fechaCreacion"
        },
        {
            title: "Línea",
            value: "lineaCredito"
        },
        {
            title: "Origen",
            value: "origen"
        },
    ]

    return (
        <div className="o-listaCreditos-container">
            {loading && <Loading />}
            <header className="o-listaCreditos-container__header">
                <h2>Lista de créditos</h2>
                <h3>Encuentra la solicitud de crédito que necesitas</h3>
            </header>
            <Divider dividerWidth='881px' dividerColor='#E3E3E3' dividerMargin='27.5px 0 56.5px 0' />
            <form className="o-listaCreditos-container__section" onSubmit={handleSubmit(onSubmit)}>
                <div className="o-listaCreditos-container__section__container">
                    <div className="o-listaCreditos-container__section__container__input">
                        <Input
                            register={register("document", {
                                required: "Este campo no puede estar vacio",
                                valueAsNumber: true,
                            })}
                            type="number"
                            title="¿Sabes el número de documento?"
                            error={errors.document?.message || ""}
                            placeholder="Ingresa el número"
                        />
                    </div>
                </div>
                <div className="o-listaCreditos-container__section__buttons">
                    <Button
                        type='button'
                        classname=''
                        text={'Limpiar'}
                        isDisabled={false}
                        gtmMark="primary"
                        onClickAction={handleClear}
                    />
                    <Button
                        type='input'
                        classname=''
                        text={'Buscar'}
                        isDisabled={false}
                        gtmMark="secondary"
                    />
                </div>
            </form>
            {
                showData &&
                <>
                    <Divider dividerWidth='881px' dividerColor='#E3E3E3' dividerMargin='27.5px 0 48px 0' />
                    <div>
                        <div style={{ marginBottom: "43px" }}>
                            <DisplayBadge title={datosSolicitud.nombre} subtitle={datosSolicitud.tipoDocumento + " " + datosSolicitud.numeroDocumento} />
                        </div>
                        <Table headers={headers} body={solicitudes} titular={datosSolicitud} />
                    </div>
                </>
            }
        </div>
    )
}

export default ListaCreditos;