import React, { useState } from 'react'
import DatosAfiliacion from "./datosAfiliacion/datosAfiliacion"
import ListasRestrictivas from "./listasRestrictivas/listasRestrictivas"
import FlexDivUI from '../../atoms/flexible-div-ui/FlexDivUI'
import TitleUI from '../../atoms/title-ui/TitleUI'
import WrapperContent from '../../atoms/wrapper-content/WrapperContent'

import './ValidacionesAfiliacion.scss'

const ValidacionesAfiliacion = () => {
  const [page, setPage] = useState(1)

  return (
    <WrapperContent>
      <FlexDivUI justifyContent="flex-start" flexDirection="column">
        <TitleUI marginBottom={21} marginTop={0} color="primary">
          Validaciones
        </TitleUI>
      </FlexDivUI>
      <div className="gray-line" />
      <div className="o-ValidacionesAfiliacion__header">
        <div>
          <p
            onClick={() => setPage(1)}
            className={`o-ValidacionesAfiliacion__header__label ${
              page === 1 ? '--active' : ''
            }`}
          >
            Datos de afiliación
          </p>
        </div>
        <div>
          <p
            onClick={() => setPage(2)}
            className={`o-ValidacionesAfiliacion__header__label ${
              page === 2 ? '--active' : ''
            }`}
          >
            Listas restrictivas
          </p>
        </div>
        <div>
          <p
            onClick={() => setPage(3)}
            className={`o-ValidacionesAfiliacion__header__label ${
              page === 3 ? '--active' : ''
            }`}
          >
            Motor de decisión
          </p>
        </div>
        <div>
          <p
            onClick={() => setPage(4)}
            className={`o-ValidacionesAfiliacion__header__label ${
              page === 4 ? '--active' : ''
            }`}
          >
            Libranza
          </p>
        </div>
      </div>

      {page === 1 ? (
        <DatosAfiliacion />
      ) : page === 2 ? (
        // <></>
        <ListasRestrictivas />
      ) : page === 3 ? (
        <h1>Motor de decisión</h1>
      ) : (
        <h1>Libranza</h1>
      )}
    </WrapperContent>
  )
}

export default ValidacionesAfiliacion
