import React from "react";
import InputFileDashboard from "../../atoms/input-file-dashboard/inputFileDashboard";

import "./fileDashboard.scss";

type TProps = {
  text?: string;
  isRequired?: boolean;
  name: string;
  label?: string;
  processFile: (name: string, file: File) => void;
  deleteFile?: (name: string) => void;
  isEdit?: boolean;
  setOpenModal?: any;
};

const FileDashboard = ({ name, label, processFile, deleteFile, isEdit, setOpenModal, }: TProps) => {
  return (
    <div className="m-fileDashboard">
      <InputFileDashboard
        name={name}
        label={label}
        processFile={processFile}
        deleteFile={deleteFile}
        isEdit={isEdit}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default FileDashboard;
