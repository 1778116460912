import React from 'react'
import './circle.scss'

interface CircleProps {
  color: string;
}

const Circle = ({ color }: CircleProps) => {
  return (
    <div className="a-circle" style={{ backgroundColor: color }}></div>
  )
}

export default Circle