import React from "react";
import InputFile from "../../atoms/input-file/inputFile";

import "./file.scss";
import Tooltip from "../../atoms/tooltip/tooltip";

type TProps = {
  text: string;
  isRequired?: boolean;
  name: string;
  label?: string;
  deleteFile?: (name: string) => void;
  handleAddFile: (name: string, file: File) => void;
  showError: boolean;
  tooltip: string;
};

const File = ({
  name,
  label,
  text,
  isRequired,
  deleteFile,
  handleAddFile,
  showError,
  tooltip,
}: TProps) => {
  return (
    <div className="m-file">
      <div className="m-file__tooltip">
        <span className="m-file__text">
          {text}
          {isRequired && <span className="m-file__text__required">{` *`}</span>}
        </span>
        {tooltip ? <Tooltip title={text} subtitle={tooltip} /> : <div>{' '}</div>}
      </div>
      <InputFile
        name={name}
        label={label}
        deleteFile={deleteFile}
        handleAddFile={handleAddFile}
        showError={showError}
      />
    </div>
  );
};

export default File;
