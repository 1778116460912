import React, { useEffect, useState } from "react";
import ProgressBar from "../../atoms/progress-bar/progressBar";

const Wizard = ({ children, shouldRender=true }: any) => {
  const [step, setStep] = useState(0);
  const allChildren = React.Children.map(children, (child, index) => {
    if (index !== step) return;

    if (child === null || child.type.name !== "WizardTab") {
      setStep(prev => prev + 1);
      return;
    }

    return React.cloneElement(child, {
      step,
      setStep
    })
  })

  useEffect(() => {
    // console.log(allChildren);
  }, [allChildren]);

  return (
    <>
      {allChildren}
      <ProgressBar
        percentage={Math.round((step * 100) / children.length)}
        onClick={() => { }}
        title={`Paso ${step + 1}`}
      ></ProgressBar>
    </>
  );
};

export default Wizard;
