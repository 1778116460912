import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../contexts/appContext";
import {
  getLineaCredito,
  getSede,
  getTipoIdentificacion,
} from "../../../services/parametrizacionServices";
import { getData } from "../../../services/gestionarCreditosServices";
import Button from "../../atoms/button/button";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Divider from "../../atoms/divider/divider";
import Input from "../../atoms/input/input";
import "./gestionarCreditos.scss";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import Loading from "../../atoms/loading/loading";
import Datalist from "../../atoms/datalist/datalist";
import Select from "../../atoms/select/select";
import { ADD_AVALISTA } from "../../../reducers/reducerSolicitud";
import ProcesarCredito from "../procesar-credito/procesarCredito";

interface GestionarCreditosProps {
  setGestionarChildren?: any;
}

type Inputs = {
  documentType: string;
  creditLine: string;
  documentNumber: number;
  sede: string;
};

export type TSettle = {
  lineaCredito: string;
  valorMonto: number;
  plazo: number;
  tasaInteres: string;
  tiempoAmortizacion: number;
  vigenciaOperacion: string;
  vigenciaOperacionString: string;
};

const GestionarCreditos = ({
  setGestionarChildren,
}: GestionarCreditosProps) => {
  const {
    setCurrentTab,
    stateSolicitud,
    dispatchSolicitud,
    setIsViable,
    setIsRadication,
  } = useContext(AppContext);
  const [modalContent, setModalContent] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [dataSettle, setDataSettle] = useState<TSettle>({
    lineaCredito: "",
    valorMonto: 0,
    plazo: 0,
    tasaInteres: "",
    tiempoAmortizacion: 0,
    vigenciaOperacion: "",
    vigenciaOperacionString: "",
  });

  useEffect(() => {
    setIsRadication(false);
  }, []);

  const ContentError = () => {
    return (
      <Modal
        open={modalContent === "error"}
        handleClose={() => setModalContent("")}
      >
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">Lo sentimos</TitleUI>
          <TitleUI color="dark" as="h3" align="center">
            Ocurrió un error inesperado, comunicate con un administrador y
            vuelve a intentarlo.
          </TitleUI>
          <Button
            text={"Cerrar"}
            type="button"
            classname=""
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={() => setModalContent("")}
          />
        </FlexDivUI>
      </Modal>
    );
  };

  const ContentNotViable = () => {
    return (
      <Modal
        open={modalContent === "notviable"}
        handleClose={() => setModalContent("")}
      >
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">Lo sentimos</TitleUI>
          <TitleUI color="dark" as="h3" align="center">
            No eres viable para continuar con el proceso.
          </TitleUI>
          <Button
            text={"Cerrar"}
            type="button"
            classname=""
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={() => setModalContent("")}
          />
        </FlexDivUI>
      </Modal>
    );
  };

  const ContentSuccess = () => {
    return (
      <Modal open={modalContent === "success"} handleClose={handleCloseModal}>
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">Felicidades</TitleUI>
          <TitleUI color="dark" as="h3" align="center">
            Estás habilitado para tomar este servicio de crédito.
          </TitleUI>
          <Button
            text={"Continuar"}
            type="button"
            classname=""
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={handleCloseModal}
          />
        </FlexDivUI>
      </Modal>
    );
  };

  const ContentAdditional = () => {
    return (
      <Modal
        open={modalContent === "additional"}
        handleClose={handleCloseModal}
      >
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">
            {stateSolicitud.solicitanteLider.solicitante.primerNombre}
          </TitleUI>
          <TitleUI color="dark" as="h3" align="center">
            Alguno de los solicitantes adicionales no ha radicado su solicitud
          </TitleUI>
          <Button
            text={"Continuar"}
            type="button"
            classname=""
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={handleCloseModal}
          />
        </FlexDivUI>
      </Modal>
    );
  };

  const modalContentComponents: any = {
    error: <ContentError />,
    success: <ContentSuccess />,
    notviable: <ContentNotViable />,
    additional: <ContentAdditional />,
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
  } = useForm<Inputs>();

  const watchCreditLine = watch("creditLine");

  const [ddlOptions, setDdlOptions] = useState<any>({
    tiposIdentificacion: [],
    sedes: [],
    lineasCredito: [],
  });

  const validateLeader = async (data: any) => {
    setLoading(true);

    let solicitudState = "";

    await getData(data.creditLine, data.documentType, data.documentNumber)
      .then((res) => {
        solicitudState = res.data.result.action;
        if (solicitudState === "CREAR") {
          if (res.data.result.data.viable) {
            setModalContent("success");
            const dataWork = {
              empresa: res.data.result.data.informacion.nombreEmpleador,
              actividadEconomica: res.data.result.data.informacion.tipoAfiliado,
            };
            dispatchSolicitud({
              type: "ADD_SOLICITANTE_LIDER",
              payload: {
                stateSolicitante: 0,
                solicitante: {
                  ...res.data.result.data.informacion,
                  informacionActividadEconomica: {
                    ...dataWork,
                  },
                  viabilidadEconomica: {
                    ...dataWork,
                  },
                  tyCFirmado: false,
                  action: solicitudState,
                },
                formData: res.data.result.data.informacion,
                validationData: res.data.result.data.informacion,
                // solicitudData: {
                //   id: res.data.result.data.solicitudId || 1,
                //   valorPreaprobado: res.data.result.data.valorMonto, //TODO: Completar el valor preaprobado en el endpoint
                // },
              },
            });
            setAction(solicitudState);
          } else {
            setModalContent("notviable");
          }
        }
        if (solicitudState === "RETOMAR") {
          setModalContent("additional");
          dispatchSolicitud({
            type: "ADD_SOLICITANTE_LIDER",
            payload: {
              stateSolicitante: 0,
              solicitante: {
                ...res.data.result.data.solicitantes[0].solicitante,
                viabilidadEconomica: {
                  ...res.data.result.data.solicitantes[0].solicitante
                    .informacionActividadEconomica,
                },
                tyCFirmado: res.data.result.data.solicitantes[0].tyCFirmado,
                action: solicitudState,
              },
              formData: data,
              validationData: res.data.result.data.solicitantes[0].solicitante,
              solicitudData: {
                id: res.data.result.data.solicitudId || 1,
                valorPreaprobado: res.data.result.data.valorMonto, //TODO: Completar el valor preaprobado en el endpoint
              },
            },
          });
          if (res.data.result.data.solicitantes.length > 1) {
            res.data.result.data.solicitantes.forEach(
              (element: any, index: number) => {
                if (index !== 0) {
                  dispatchSolicitud({
                    type: ADD_AVALISTA,
                    payload: {
                      id: Date.now(),
                      formData: {
                        tipoDocumento: element.solicitante.tipoDocumentoId,
                        numeroDocumento: element.solicitante.numeroDocumento,
                        primerNombre: element.solicitante.primerNombre,
                        segundoNombre: element.solicitante.segundoNombre,
                        primerApellido: element.solicitante.primerApellido,
                        segundoApellido: element.solicitante.segundoApellido,
                        correoElectronico: element.solicitante.email,
                        fechaNacimiento: element.solicitante.fechaNacimiento,
                        numeroCelular: element.solicitante.celular,
                      },
                      validationData: element,
                      solicitante: {
                        ...element.solicitante,
                        viabilidadEconomica: {
                          ...element.solicitante.informacionActividadEconomica,
                        },
                        tyCFirmado: element.tyCFirmado,
                      },
                    },
                  });
                }
              }
            );
          }
          setAction(solicitudState);
        } else if (solicitudState === "RADICAR") {
          const hoy = new Date();
          const vigencia = new Date(res.data.result.data.vigenciaOperacion);
          const diferencia = vigencia.getTime() - hoy.getTime();
          const mesesTranscurridas = diferencia / 1000 / 60 / 60 / 24 / 30;
          const vigenciaOperacionString = `${
            mesesTranscurridas < 0 ? 0 : mesesTranscurridas
          } meses a partir de la fecha de esta comunicación`;

          dispatchSolicitud({
            type: "ADD_SOLICITANTE_LIDER",
            payload: {
              stateSolicitante: 0,
              solicitante: {
                ...res.data.result.data.solicitantes[0].solicitante,
                action: solicitudState,
              },
              formData: data,
              validationData: res.data.result.data.solicitantes[0].solicitante,
              solicitudData: {
                id: res.data.result.data.solicitudId || 1,
                valorPreaprobado: res.data.result.data.valorMonto, //TODO: Completar el valor preaprobado en el endpoint
              },
            },
          });

          res.data.result.data.solicitantes.forEach(
            (solicitante: any, index: number) => {
              if (index >= 1) {
                dispatchSolicitud({
                  type: "ADD_AVALISTA",
                  payload: {
                    formData: { ...solicitante.solicitante },
                    solicitante: {
                      ...solicitante.solicitante,
                    },
                  },
                });
              }
            }
          );

          //Add simulation
          if (res.data.result.data.simulacion?.id) {
            dispatchSolicitud({
              type: "UPDATE_SIMULACION",
              payload: res.data.result.data.simulacion,
            });
          }

          setDataSettle({
            ...res.data.result.data,
            vigenciaOperacionString: vigenciaOperacionString, // vigenciaOperacionString
          });
          setAction(solicitudState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalContent("");

    if (action === "RETOMAR" || action === "CREAR") {
      setIsViable(true);
      if (stateSolicitud.avalistas.length > 0) {
        setCurrentTab("simulador");
      } else {
        setCurrentTab("solicitantesAdicionales");
      }
    }
  };

  useEffect(() => {
    // populate lineasCredito ddl
    // setIsViable(true);

    setLoading(true);
    getLineaCredito()
      .then((res: any) => {
        setDdlOptions({
          ...ddlOptions,
          lineasCredito: res.data.result.map((item: any) => {
            return { label: item.nombre, value: item.codigo };
          }),
        });
      })
      .catch((err) => {
        // alert("Error: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // populate other ddls by creditLine
    setLoading(true);
    let creditLineSelected: number = parseInt(watchCreditLine);
    if (isNaN(creditLineSelected)) {
      // disableFields(true);
      return;
    }
    const parametersPromises = [
      getTipoIdentificacion(creditLineSelected),
      getSede(creditLineSelected),
    ];
    Promise.all(parametersPromises)
      .then(([tiposIdentificacion, sedes]) => {
        setDdlOptions({
          ...ddlOptions,
          tiposIdentificacion: tiposIdentificacion.data.result.map(
            (item: any) => {
              return { label: item.nombre, value: item.id };
            }
          ),
          sedes: sedes.data.result.map((item: any) => {
            return { label: item.nombre, value: item.codigo };
          }),
        });
      })
      .catch((err) => {
        //TODO: Monstrar error que no se pudieron traer los datos
        // alert("Error: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [watchCreditLine]);

  return action === "RADICAR" ? (
    <ProcesarCredito />
  ) : (
    <WrapperContent>
      {loading && <Loading />}
      {modalContentComponents[modalContent]}
      <header className="o-gestionarCreditos-container__header">
        <TitleUI>Gestionar tus créditos</TitleUI>
        <h3>
          Para comenzar ingresa el número de documento de identidad del
          solicitante, recuerda verificar el documento original.
        </h3>
      </header>
      <Divider
        dividerWidth="881px"
        dividerColor="#E3E3E3"
        dividerMargin="27.5px 0 56.5px 0"
      />
      <form
        className="o-gestionarCreditos-container__section"
        onSubmit={handleSubmit(validateLeader)}
      >
        <div className="o-gestionarCreditos-container__section__container">
          <Select
            name="creditLine"
            error={errors?.creditLine?.message || ""}
            title="Línea de crédito"
            options={ddlOptions.lineasCredito}
            rules={{ required: "* Campo obligatorio" }}
            control={control as any}
            className="o-gestionarCreditos-container__section__container__input"
          />
          <Select
            name="documentType"
            error={errors?.documentType?.message || ""}
            title="Tipo de documento"
            options={ddlOptions.tiposIdentificacion}
            rules={{ required: "* Campo obligatorio" }}
            control={control as any}
            className="o-gestionarCreditos-container__section__container__input"
          />
        </div>
        <div className="o-gestionarCreditos-container__section__container">
          <Input
            register={register("documentNumber", {
              required: "Este campo no puede estar vacio",
              valueAsNumber: true,
            })}
            type="number"
            // ref={numeroInput}
            title="Número del documento"
            error={errors.documentNumber?.message || ""}
            placeholder="Ingresa el número"
            className="o-gestionarCreditos-container__section__container__input"
          />
          <Datalist
            title="Sede"
            name={"campus"}
            options={ddlOptions.sedes}
            rules={{ required: "* Campo obligatorio" }}
            control={control as any}
          />
        </div>
        <div className="o-gestionarCreditos-container__section__buttons">
          <Button
            type="button"
            classname=""
            text={"Limpiar"}
            isDisabled={false}
            gtmMark="primary"
            onClickAction={() => reset()}
          />
          <Button
            type="input"
            classname=""
            text={"Aceptar"}
            isDisabled={false}
            gtmMark="secondary"
          />
        </div>
      </form>
    </WrapperContent>
  );
};

export default GestionarCreditos;
