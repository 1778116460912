import React from 'react'
import './displayBadge.scss'

interface DisplayBadgeProps {
    title: string;
    subtitle: string;
}

const DisplayBadge = ({ title, subtitle }: DisplayBadgeProps) => {
    return (
        <div className="m-displayBadge-container">
            <div className="m-displayBadge-container__bar"></div>
            <div className="m-displayBadge-container__content">
                <h3>{title}</h3>
                <h4>{subtitle}</h4>
            </div>
        </div>
    )
}

export default DisplayBadge