import React, { useContext, useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import reducerSolicitud, {
  UPDATE_SOLICITANTE_LIDER,
  UPDATE_TERM_AVALISTA,
} from "../../../reducers/reducerSolicitud";
// import { getToken, postSendOtp, postValidateOtp } from '../../../../services/mentoriaService';
import Button from "../../atoms/button/button";
import IconText from "../../atoms/icon-text/iconText";
import icono_celular from "../../../assets/icons/tratamientoDatos/icono_celular.svg";
import icono_correo from "../../../assets/icons/tratamientoDatos/icono_correo.svg";
import icono_reloj from "../../../assets/icons/tratamientoDatos/icono_reloj.svg";
import "./otp.scss";
import Loading from "../../atoms/loading/loading";
import { generarOtp, validarOtp } from "../../../services/otpServices";
import SolicitudPreaprobada from "../solicitud-preaprobada/solicitudPreaprobada";
import DatosContactos from "../datos-contactos/datosContactos";
import Modal from "../../atoms/modal/modal";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Input from "../../atoms/input/input";
import InfoBox from "../../atoms/info-box/infoBox";

import { AppContext } from "../../../contexts/appContext";
import SolicitudEspera from "../solicitud-espera/solicitudEspera";

const DURATION = 1; // 1minutos en segundos o 60 * 5

const Otp = (props: any) => {
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(DURATION * 60);
  const [countdown, setCountdown] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const [flagVality, setFlagVality] = useState(false);

  const [dataContact, setDataContact] = useState({
    numero: "",
    email: "",
  });

  const [openModal, setOpenModal] = useState(false);

  const { setCurrentTab, stateSolicitud, dispatchSolicitud } =
    useContext(AppContext);

  const [error, setError] = useState("");

  const {
    register,
    //handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      celular: "",
      email: "",
      otroNumero: "",
      numeroDocumento: "",
      primerNombre: "",
      tyCFirmado: false,
    },
  });
  let watchAllFields = watch();
  const displayMobile =
    watchAllFields.celular.substring(0, 3) +
    "*".repeat(6) +
    watchAllFields.celular.substring(9, 11);
  const displayEmail =
    watchAllFields.email.substring(0, 2) +
    "*".repeat(watchAllFields.email.split("@")[0].length) +
    "@" +
    watchAllFields.email.split("@")[1];

  const getTokenOtp = async () => {
    if (timer === 0) {
      setTimer(DURATION * 60);
    }
    setError("");
    try {
      let data = {
        idSolicitud: 2,
        celular: "",
        correo: "",
        nombre: "",
        numeroDocumento: "",
        tipoDocumento: 1,
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        data = {
          ...data,
          celular:
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.celular || "",
          correo:
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.email || "",
          nombre:
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.primerNombre || "",
          numeroDocumento: String(
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.numeroDocumento || ""
          ),
        };
      } else {
        data = {
          ...data,
          celular: stateSolicitud.solicitanteLider.solicitante.celular || "",
          correo: stateSolicitud.solicitanteLider.solicitante.email || "",
          nombre:
            stateSolicitud.solicitanteLider.solicitante.primerNombre || "",
          numeroDocumento: String(
            stateSolicitud.solicitanteLider.solicitante.numeroDocumento || ""
          ),
        };
      }
      // idSolicitud: 2,
      // celular: watchAllFields.celular,
      // correo: watchAllFields.email,
      // nombre: watchAllFields.primerNombre,
      // numeroDocumento: watchAllFields.numeroDocumento,
      // tipoDocumento: 1,

      console.log(data);

      await generarOtp({
        ...data,
      }).then((res) => {
        if (res.status !== undefined) {
          if (res.status === 200) {
            setError("");
            // setCountdown(
            //   setInterval(() => {
            //     setTimer((prev) => prev - 1);
            //   }, 1000)
            // );
          } else {
            setError(
              "*Hubo un problema al generar el código OTP, intentalo nuevamente"
            );
            setTimer(0);
          }
        }
      });
    } catch (error) {
      console.log(error);
      setError(
        "*Hubo un problema al generar el código OTP, intentalo nuevamente"
      );
      setTimer(0);
    } finally {
      setLoading(false);
    }
  };

  const checkOtp = async () => {
    setLoading(true)
    setError("");
    if (code == null || code == "") {
      setError("*No ingresaste ningún código");
    } else {
      try {
        
        if (true) {
          props.handleCorrectCode();

          const payload: any = {};
          payload.tyCFirmado = true;
          if (stateSolicitud.solicitanteLider.stateSolicitante) {
            dispatchSolicitud({
              type: UPDATE_TERM_AVALISTA,
              payload,
            });
          } else {
            dispatchSolicitud({
              type: UPDATE_SOLICITANTE_LIDER,
              payload,
            });
          }
          setCode("");
          // setState("correctCode");
        }


        // validarOtp({
        //   idSolicitud: 1,
        //   celular: watchAllFields.celular,
        //   codigo: code,
        //   correo: watchAllFields.email,
        //   nombre: watchAllFields.primerNombre,
        //   documento: watchAllFields.numeroDocumento,
        //   ipAdress: "0.0.0.0",
        // })
        //   .then((res) => {
        //     setLoading(false)
        //     if (res !== undefined) {
        //       if (res.data.success) {
        //         // props.handleCorrectCode();
        //         const payload: any = {};
        //         payload.tyCFirmado = true;
        //         if (stateSolicitud.solicitanteLider.stateSolicitante) {
        //           dispatchSolicitud({
        //             type: UPDATE_TERM_AVALISTA,
        //             payload,
        //           });
        //         } else {
        //           dispatchSolicitud({
        //             type: UPDATE_SOLICITANTE_LIDER,
        //             payload,
        //           });
        //         }
        //         setCode("");
        //         // setState("correctCode");
        //       } else {
        //         setError(
        //           "*El código que acabas de ingresar es incorrecto, verifica"
        //         );
        //         setLoading(false)
        //       }
        //     }
        //   })
        //   .catch((error) => {
        //     setLoading(false);
        //     console.log(error);
        //     setError(
        //       "*El código que acabas de ingresar es incorrecto, verifica"
        //     );
        //   }).finally(() => {
        //     console.log("FINALIZA");
            
        //     setLoading(false);
        //   });
      } catch (error) {
        setError("Hubo un error validando el código, intentalo nuevamente");
      }
    }
  };
  const HandleGuardar = async () => {
    const payload: any = {};
    payload.email = watchAllFields.email;
    payload.celular = watchAllFields.celular;

    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      dispatchSolicitud({
        type: UPDATE_TERM_AVALISTA,
        payload,
      });
    } else {
      dispatchSolicitud({
        type: UPDATE_SOLICITANTE_LIDER,
        payload,
      });
    }
    setTimer(DURATION * 60);
    // dispatchSolicitud({
    //   type: UPDATE_SOLICITANTE_LIDER,
    //   payload,
    // });
    // setTimer(DURATION * 60);
    // await getTokenOtp();
    CloseModal();
  };

  const HandleContact = () => {
    setOpenModal(true);
  };
  const CloseModal = () => {
    setOpenModal(!openModal);
  };
  const HandleData = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      setValue(
        "celular",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.celular || ""
      );

      setValue(
        "email",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.email || ""
      );

      setValue(
        "otroNumero",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.otroNumero || ""
      );

      setValue(
        "numeroDocumento",
        String(
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.numeroDocumento
        ) || ""
      );

      setValue(
        "primerNombre",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.primerNombre || ""
      );

      setValue(
        "tyCFirmado",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.tyCFirmado || false
      );
    } else {
      setValue(
        "celular",
        stateSolicitud.solicitanteLider.solicitante.celular || ""
      );

      setValue(
        "email",
        stateSolicitud.solicitanteLider.solicitante.email || ""
      );

      setValue(
        "otroNumero",
        stateSolicitud.solicitanteLider.solicitante.otroNumero || ""
      );

      setValue(
        "numeroDocumento",
        String(stateSolicitud.solicitanteLider.solicitante.numeroDocumento) ||
          ""
      );

      setValue(
        "primerNombre",
        stateSolicitud.solicitanteLider.solicitante.primerNombre || ""
      );
      setValue(
        "tyCFirmado",
        stateSolicitud.solicitanteLider.solicitante.tyCFirmado || false
      );
    }
  };

  const HandleDataContact = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      setDataContact({
        numero:
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.celular || "",
        email:
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.email || "",
      });
    } else {
      setDataContact({
        numero: stateSolicitud.solicitanteLider.solicitante.celular || "",
        email: stateSolicitud.solicitanteLider.solicitante.email || "",
      });
    }
  };

  const HandleValityTerm = () => {
    let vality: boolean = false;

    if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
      vality = true;
      if (
        stateSolicitud.avalistas != undefined ||
        stateSolicitud.avalistas != null
      ) {
        if (stateSolicitud.avalistas.length > 0) {
          stateSolicitud.avalistas.forEach((element) => {
            if (!element.solicitante.tyCFirmado) {
              vality = false;
            }
          });
        }
      }
    }

    setFlagVality(vality);
  };

  useEffect(() => {
    HandleData();
    HandleDataContact();
    HandleValityTerm();
    if (state === "") {
      setLoading(true);
      getTokenOtp();
      return () => {
        clearInterval(countdown);
      };
    }
  }, []);

  useEffect(() => {
    if (timer >= 1) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    HandleData();
    HandleDataContact();
    HandleValityTerm();
    if (state === "") {
      setLoading(true);
      getTokenOtp();
      return () => {
        clearInterval(countdown);
      };
    }
  }, [stateSolicitud]);

  return (
    <div className="mentoria-otp">
      <Modal open={openModal} handleClose={() => CloseModal()}>
        <TitleUI as="h2" color="dark" align="center">
          ¿Cuáles son tus datos de contacto?
        </TitleUI>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginBottom: "25px",
            flexDirection: "column",
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "0 auto",
            }}
          >
            <Input
              register={register("celular", {
                required: "Campo requerido",
              })}
              error={errors.celular?.message || ""}
              title={"Número de celular"}
              type={"number"}
              placeholder={"Agrega el número"}
              className="o-datosContactos-checkPhone"
            />
            <Input
              register={register("otroNumero", {
                //required: "Campo requerido"
              })}
              error={errors.otroNumero?.message || ""}
              title={"Otro número"}
              type={"number"}
              placeholder={"Agrega el número"}
              className="o-datosContactos-checkPhone"
            />
            <Input
              register={register("email", {
                required: "Campo requerido",
              })}
              error={errors.email?.message || ""}
              title={"Correo electrónico"}
              type={"email"}
              placeholder={"Agrega el correo electrónico"}
              className="o-datosContactos-checkPhone"
            />
          </form>

          <InfoBox
            classNa={"o-datosContactos-infoBox"}
            title="Verificar datos de contacto"
            description="Es importante que los datos de contacto sean reales porque a través de ellos se firman y se reciben los documentos."
          />
          <div className="Modal__buttons">
            <Button
              text="Guardar"
              classname=""
              gtmMark="secondary"
              isDisabled={false}
              type="button"
              onClickAction={HandleGuardar}
            />
          </div>
        </div>
      </Modal>
      {loading && <Loading />}
      <header className="mentoria-otp__header">
        <h2 className="mentoria-otp__header__title">
          Para aceptar el tratamiento de tus datos te acabamos de enviar un
          código a tu celular y a tu correo electrónico.
        </h2>
        <section className="mentoria-otp__header__section">
          <div className="mentoria-otp__header__section__card">
            <img
              className="mentoria-otp__header__section__card__img"
              src={icono_celular}
              alt="Número de celular"
            />
            <div className="mentoria-otp__header__section__card__container">
              <p className="mentoria-otp__header__section__card__title">
                Número de celular
              </p>
              <p className="mentoria-otp__header__section__card__subtitle">
                {`${dataContact.numero.substring(
                  0,
                  3
                )}******${dataContact.numero.substring(9, 11)}`}
              </p>
            </div>
          </div>
          <div className="mentoria-otp__header__section__card">
            <img
              className="mentoria-otp__header__section__card__img"
              src={icono_correo}
              alt="Correo electrónico"
            />
            <div>
              <p className="mentoria-otp__header__section__card__title">
                Correo electrónico
              </p>
              <p className="mentoria-otp__header__section__card__subtitle">
                {`${
                  dataContact.email.substring(0, 2) +
                  "*".repeat(dataContact.email.split("@")[0].length)
                } ${dataContact.email.split("@")[1]}`}
              </p>
            </div>
          </div>
        </section>
        <span
          className="mentoria-otp__header__datos-erroneos"
          onClick={() => HandleContact()}
        >
          Estos no son mis datos
        </span>
      </header>
      <main className="mentoria-otp__main">
        <p className="mentoria-otp__main__title">
          Una vez recibido ingrésalo en el siguiente campo
        </p>
        <input
          type="password"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="mentoria-otp__main__input"
          placeholder="Digita el código"
        />
        {error && <p className="mentoria-otp__main__error">{error}</p>}
        <IconText
          width={21}
          text={`Reenviar código en ${
            Math.floor(timer / 60) +
            ":" +
            (timer % 60 < 10 ? "0" : "") +
            Math.round(timer % 60)
          } ${timer >= 60 ? "minutos" : "segundos"}`}
          src={icono_reloj}
        />
      </main>
      <div className="mentoria-otp__buttons">
        <Button
          text="Reenviar código"
          classname=""
          gtmMark="primary"
          isDisabled={timer > 0}
          type="button"
          onClickAction={getTokenOtp}
        />
        <Button
          text="Validar código"
          classname=""
          gtmMark="secondary"
          isDisabled={false}
          type="button"
          onClickAction={checkOtp}
        />
      </div>
    </div>
  );
};

export default Otp;
