import React from 'react'
import { useController, UseControllerProps } from "react-hook-form";
import arrow from '../../../assets/icons/arrow_down.svg';
import './select.scss'

type TSelect = {
    className?: string;
    error: string;
    title: string;
    placeholder?: string;
    options: Array<{label: string, value: any}>
    value?:string
}

interface SelectProps extends UseControllerProps, TSelect {};

const Select = (props: SelectProps) => {
    const { field, fieldState } = useController(props);
    const { className, error, title, placeholder, options, value } = props;
  return (
    <div className={`select ${className}`}>
        {props.title && <label className={`select__title ${error ? "select__title--error" : ""}`} htmlFor=''>{title}</label>}
        <div className='select__input-select-container'>
            <select
                className={`select__input-select-container__select ${error ? "select__input--error" : ""}`}
                style={{ color: field.value ? '#303030' : 'gray' }}
                placeholder={placeholder || ""}
                {...field}
                defaultValue={1}
            >
                <option value="" hidden selected>{placeholder || 'Selecciona'}</option>
                {options.map((option) => (
                    <option key={option.label + option.value} value={option.value}>{option.label}</option>
                )
                )}
            </select>
            <img
                src={arrow}
                alt="Dropdown menu"
                className='select__input-select-container__img'
            />
        </div>
        {error && <span className='select__error'>{error}</span>}
    </div>
  )
}

export default Select