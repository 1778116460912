import React from "react"
import "./button.scss"

const Button = (props: ButtonInterface) => {

	const {
		id,
		type,
		classname,
		text,
		onClickAction,
		isDisabled,
		href,
		icon,
		gtmMark,
		strictCss,
		target
	} = props

	const isHttp = (url?: string) => {
		return url?.startsWith('http');
	}

	return (
		<>
			{text ? (
				type === "button" ? (
					<button
						id={id}
						className={`${strictCss ? '' : 'a-button '} ${classname} ${gtmMark}`}
						disabled={isDisabled}
						onClick={onClickAction}
						type={type}
					>
						{text}
					</button>
				) : (type === "link" ? (
					props.download ?
						<span
							id={id}
							className={`${strictCss ? '' : 'a-button '} ${classname} ${gtmMark}`}
							onClick={onClickAction}
						>
							{icon && <span className={`${icon}`} />}
							{text}
						</span>
						:
						<a
							id={id}
							href={href}
							target={isHttp(href) ? '_blank' : target || "_self"}
							rel={href}
							className={`${strictCss ? '' : 'a-button '} ${classname} ${gtmMark}`}
						>
							{icon && <span className={`${icon}`} />}
							{text}
						</a>
				) : (
					<input
						className={`${strictCss ? '' : 'a-button '} ${classname} ${gtmMark}`}
						type="submit"
						value={text}
						disabled={isDisabled}
					/>
				))
			) : (
				<></>
			)
			}
		</>
	)
}

interface ButtonInterface {
	id?: string;
	type: 'button' | 'input' | 'link';
	classname: string;
	text: string | null | undefined;
	onClickAction?: () => any;
	isDisabled: boolean | undefined;
	icon?: string;
	href?: string,
	gtmMark?: string,
	strictCss?: boolean,
	target?: string,
	download?: boolean
}

export default Button
