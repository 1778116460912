import axios from "./axiosConfig";

export const getTipoIdentificacion = (creditLine:number) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/TipoIdentificacion/${creditLine}`,
    headers: {}
  };
  return axios(config);
};

export const getSede = (creditLine:number) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Sede/${creditLine}`,
    headers: {}
  };
  return axios(config);
};

export const getLineaCredito = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/LineaCredito`,
    headers: {}
  };
  return axios(config);
};

export const getMunicipios = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Municipio`,
    headers: {}
  };
  return axios(config);
}

export const getEstadoCivil = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/EstadoCivil/1`,
    headers: {}
  };
  return axios(config);
}

export const getNivelEstudio = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/NivelEstudio/1`,
    headers: {}
  };
  return axios(config);
}

export const getEnfermedades = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Enfermedad/1`,
    headers: {}
  };
  return axios(config);
}

export const getMessages = () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Mensaje/1`,
    headers: {}
  };
  return axios(config);
}