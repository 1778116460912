import React, { useState } from 'react'
import Button from '../../atoms/button/button';
import InfoBox from '../../atoms/info-box/infoBox';
import ProgressBar from '../../atoms/progress-bar/progressBar';
import './questionWizard.scss'

interface QuestionWizardProps {
   children: any;
   handleSumbit: any;
   step: number;
}

const QuestionWizard = ({ children, handleSumbit, step }: QuestionWizardProps) => {

   // const onSubmit = (data: any) => {
   //    console.log(data);
   //    setStep(step + 1)
   // }

   return (
      <>
         <main className="m-question-wizard-container__content">{children[step]}</main>
         {/* <InfoBox title="Solicitantes adicionales" description='Debe ser una persona del grupo familiar primario del titular de crédito. Puedes solicitar el crédito con máximo dos personas.' /> */}
         <ProgressBar percentage={Math.round((step * 100) / children.length)} onClick={() => { }} title={`Paso ${step + 1}`}></ProgressBar>
         {/* <div className="m-question-wizard-container__buttons-container" style={{ gridTemplateColumns: `repeat(${step === 0 ? '1' : '2'}, 15%)` }}>
            {step > 0 &&
               < Button
                  type="button"
                  classname="m-question-wizard-container__buttons-container__button"
                  text={"Volver"}
                  isDisabled={false}
                  onClickAction={() => { setStep(step - 1) }}
                  gtmMark="primary"
               />
            }
            <Button
               type="input"
               classname="m-question-wizard-container__buttons__button"
               text={"Siguiente"}
               isDisabled={false}
               // onClickAction={() => { setStep(step + 1) }}
               gtmMark="secondary"
            />
         </div> */}
      </>
   )
}

export default QuestionWizard