import React, { FC, ReactNode } from "react";
import "./FlexDivUI.scss";

type TFlexDivUI = {
  as?: "div" | "section" | "main";
  display?: "block" | "flex" | "grid";
  gap?: number;
  flexDirection?: 'row' | 'column';
  alignContent?:
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "inherit"
  | "initial"
  | "unset";
  justifyContent?:
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "inherit"
  | "initial"
  | "unset";
  alignItems?: string;
  children?: ReactNode;
};

const FlexDivUI: FC<TFlexDivUI> = ({
  as: Tag = "div",
  display = "flex",
  gap = 0,
  justifyContent = "flex-start",
  flexDirection = "row",
  alignContent = "flex-start",
  alignItems = "flex-start",
  children,
  ...otherProps
}) => {
  const classParent = "a-FlexDivUI";
  const classString = `${classParent} ${classParent}--${display} ${classParent}--gap${gap} ${classParent}--${justifyContent} ${classParent}--${flexDirection} ${classParent}--${alignContent}-align`;
  return (
    <Tag className={classString} {...otherProps}>
      {children}
    </Tag>
  );
};

export default FlexDivUI;
