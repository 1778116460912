import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dosIcon from "../../../assets/icons/icon_dos.svg";
import ningunoIcon from "../../../assets/icons/icon_ninguna.svg";
import unoIcon from "../../../assets/icons/icon_uno.svg";
import { AppContext } from "../../../contexts/appContext";
import {
  ADD_AVALISTA,
  CLEAR_AVALISTAS,
  DELETE_AVALISTAS2,
  UPDATE_AVALISTAS,
  UPDATE_LIDER_ID,
} from "../../../reducers/reducerSolicitud";
import { saveSolicitantes } from "../../../services/orquestadorServices";
import { getTipoIdentificacion } from "../../../services/parametrizacionServices";
import { getValidation } from "../../../services/validadorServices";
import { capitalize } from "../../../utils/capitalizeFirstLetter";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import Form from "../../atoms/form/form";
import InfoBox from "../../atoms/info-box/infoBox";
import Input from "../../atoms/input/input";
import Loading from "../../atoms/loading/loading";
import Select from "../../atoms/select/select";
import TitleUI from "../../atoms/title-ui/TitleUI";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import "./solicitantesAdicionales.scss";

interface SolicitantesAdicionalesProps {
  setData: any;
}

const cardOptions = [
  {
    title: "Ninguno",
    value: 0,
    img: ningunoIcon,
  },
  {
    title: "Uno",
    value: 1,
    img: unoIcon,
  },
  {
    title: "Dos",
    value: 2,
    img: dosIcon,
  },
];

const SolicitantesAdicionales = (props: any) => {
  const { setCurrentTab, stateSolicitud, solicitante, dispatchSolicitud } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const validationResult = {
    fechaAfiliacion: "",
    fechaFinAfiliacion: "",
    nitEmpleador: "",
    nombreEmpleador: "",
    numeroDocumento: "",
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    edad: 0,
    categoria: "",
    tipoAfiliado: "",
    porcentajeAporte: 0,
    antiguedadLaboral: 0,
    salario: 0,
    cuotaMonetaria: 0,
  };

  const solicitanteAvalista = {
    id: undefined,
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    lugarNacimiento: "",
    fechaExpedicion: "",
    lugarExpedicion: "",
    estadoCivilId: undefined,
    nivelEstudioId: undefined,
    personasACargo: undefined,
    celular: "",
    otroNumero: "",
    email: "",
    action: "",
    informacionFinanciera: {
      ingreso: undefined,
      otroIngresoLaboral: undefined,
      otroIngresoNoLaboral: undefined,
      cuotaDeducidaNomina: undefined,
      cuotaNoDeducidaNomina: undefined,
      valorArriendo: undefined,
      otroEgreso: undefined,
      solicitanteId: undefined,
    },
    informacionActividadEconomica: {
      actividadEconomica: "",
      empresa: "",
      tipoContrato: "",
      fechaTerminacionContrato: "",
      antiguedadLaboralMeses: undefined,
      solicitanteId: undefined,
    },
    informacionDomicilio: {
      estrato: undefined,
      lugarResidencia: "",
      tipoVivienda: "",
      tiempoResidencia: undefined,
      tipoVia: "",
      numero1: "",
      letra1: "",
      cuadrante1: "",
      numero2: "",
      letra2: "",
      cuadrante2: "",
      numero3: "",
      informacionAdicional: "",
      solicitanteId: undefined,
      lineaCreditoId: undefined,
    },
    enfermedadSolicitante: undefined,
  };
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    reset,
    setError,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      solicitantes_adicionales: stateSolicitud.avalistas.length,
      unoTipoDocumento:
        String(stateSolicitud?.avalistas[0]?.solicitante.tipoDocumentoId) || "",
      unoNumeroDocumento:
        String(stateSolicitud?.avalistas[0]?.solicitante.numeroDocumento) || "",
      unoPrimerNombre:
        stateSolicitud?.avalistas[0]?.solicitante.primerNombre || "",
      unoSegundoNombre:
        stateSolicitud?.avalistas[0]?.solicitante.segundoNombre || "",
      unoPrimerApellido:
        stateSolicitud?.avalistas[0]?.solicitante.primerApellido || "",
      unoSegundoApellido:
        stateSolicitud?.avalistas[0]?.solicitante.segundoApellido || "",
      unoFechaNacimiento:
        stateSolicitud?.avalistas[0]?.solicitante.fechaNacimiento || "",
      unoNumeroCelular: "",
      unoCorreoElectronico: "",
      dosTipoDocumento:
        String(stateSolicitud?.avalistas[1]?.solicitante.tipoDocumentoId) || "",
      dosNumeroDocumento:
        String(stateSolicitud?.avalistas[1]?.solicitante.numeroDocumento) || "",
      dosPrimerNombre:
        stateSolicitud?.avalistas[1]?.solicitante.primerNombre || "",
      dosSegundoNombre:
        stateSolicitud?.avalistas[1]?.solicitante.segundoNombre || "",
      dosPrimerApellido:
        stateSolicitud?.avalistas[1]?.solicitante.primerApellido || "",
      dosSegundoApellido:
        stateSolicitud?.avalistas[1]?.solicitante.segundoApellido || "",
      dosFechaNacimiento:
        stateSolicitud?.avalistas[1]?.solicitante.fechaNacimiento || "",
      dosNumeroCelular: "",
      dosCorreoElectronico: "",
    },
  });

  const watchAllFields = watch();

  // useEffect(() => {
  //   console.log(stateSolicitud);
  // }, [stateSolicitud]);
  // useEffect(() => {
  //   console.log(watchAllFields);
  // }, [watchAllFields]);

  const [ddlOptions, setDdlOptions] = useState<any>({
    tiposIdentificacion: [],
  });

  const checkAvalistQuantity = (next: () => void) => {
    // pasar a simulación si no se requieren avalistas
    if (watchAllFields.solicitantes_adicionales == 0) {
      setCurrentTab("simulador");
      dispatchSolicitud({
        type: CLEAR_AVALISTAS,
      });
    } else {
      next && next();
    }
  };

  const checkAvalistOne = async (next: () => void) => {
    let isFilled = await trigger(["unoTipoDocumento", "unoNumeroDocumento"]);
    // console.log(isFilled);
    if (isFilled) {
      if (watchAllFields.solicitantes_adicionales == 1) {
        await dispatchSolicitud({
          type: DELETE_AVALISTAS2,
        });
      }

      if (stateSolicitud.avalistas.length < 1) {
        await dispatchSolicitud({
          type: ADD_AVALISTA,
          payload: {
            id: Date.now(),
            formData: {
              tipoDocumento: parseInt(watchAllFields.unoTipoDocumento),
              numeroDocumento: watchAllFields.unoNumeroDocumento,
            },
            validationData: validationResult,
            solicitante: {
              ...solicitanteAvalista,
              tipoDocumentoId: parseInt(watchAllFields.unoTipoDocumento),
              numeroDocumento: Number(watchAllFields.unoNumeroDocumento),
            },
          },
        });
      }
      next();
    }
  };

  const checkAvalistTwo = async (next: () => void) => {
    let isFilled = await trigger(["dosTipoDocumento", "dosNumeroDocumento"]);
    if (isFilled) {
      console.log(stateSolicitud);

      if (stateSolicitud.avalistas.length < 2) {

        dispatchSolicitud({
          type: ADD_AVALISTA,
          payload: {
            id: Date.now(),
            formData: {
              tipoDocumento: parseInt(watchAllFields.dosTipoDocumento),
              numeroDocumento: watchAllFields.dosNumeroDocumento,
            },
            validationData: validationResult,
            solicitante: {
              ...solicitanteAvalista,
              tipoDocumentoId: parseInt(watchAllFields.unoTipoDocumento),
              numeroDocumento: Number(watchAllFields.unoNumeroDocumento),
            },
          },
        });

      }
      next();
    }

    console.log(stateSolicitud);
  };

  const getEdad = (dateString: any) => {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  };

  const checkValidity = async (fields: any[], next: () => void) => {
    // console.log(watchAllFields);

    let isFilled = await trigger(fields);
    let fecha1 = ["unoFechaNacimiento"];
    let fecha2 = ["dosFechaNacimiento"];

    // console.log(fields);
    if (isFilled) {
      if (typeof watchAllFields.unoFechaNacimiento !== "string") {
        if (JSON.stringify(fields) === JSON.stringify(fecha1)) {
          let fecha1 = (watchAllFields.unoFechaNacimiento as any).format(
            "YYYY-MM-DD"
          );
          if (getEdad(fecha1) <= 18) {
            isFilled = false;
            setError("unoFechaNacimiento", {
              type: "manual",
              message:
                "La edad requerida para el solicitante uno tiene que ser mayor a 18",
            });
          }
        }
      }

      if (typeof watchAllFields.dosFechaNacimiento !== "string") {
        if (JSON.stringify(fields) === JSON.stringify(fecha2)) {
          let fecha2 = (watchAllFields.dosFechaNacimiento as any).format(
            "YYYY-MM-DD"
          );
          if (getEdad(fecha2) <= 18) {
            isFilled = false;
            setError("dosFechaNacimiento", {
              type: "manual",
              message:
                "La edad requerida para el solicitante dos tiene que ser mayor a 18",
            });
          }
        }
      }
    }

    if (isFilled) next();
  };

  const saveAvalistData = (next: () => void, avalistIndex: number) => {
    let dummie = [...stateSolicitud.avalistas];

    if (avalistIndex === 0) {
      dummie[avalistIndex].solicitante = {
        ...stateSolicitud.avalistas[0].solicitante,
        id: undefined,
        tipoDocumentoId: parseInt(watchAllFields.unoTipoDocumento),
        numeroDocumento: Number(watchAllFields.unoNumeroDocumento),
        primerNombre: watchAllFields.unoPrimerNombre,
        segundoNombre: watchAllFields.unoSegundoNombre,
        primerApellido: watchAllFields.unoPrimerApellido,
        segundoApellido: watchAllFields.unoSegundoApellido,
        fechaNacimiento:
          typeof watchAllFields.unoFechaNacimiento !== "string"
            ? (watchAllFields.unoFechaNacimiento as any).format("YYYY-MM-DD")
            : watchAllFields.unoFechaNacimiento,
      };
      dummie[avalistIndex].formData = {
        tipoDocumento: parseInt(watchAllFields.unoTipoDocumento),
        numeroDocumento: watchAllFields.unoNumeroDocumento,
        primerNombre: watchAllFields.unoPrimerNombre,
        segundoNombre: watchAllFields.unoSegundoNombre,
        fechaNacimiento:
          typeof watchAllFields.unoFechaNacimiento !== "string"
            ? (watchAllFields.unoFechaNacimiento as any).format("YYYY-MM-DD")
            : watchAllFields.unoFechaNacimiento,
        numeroCelular: watchAllFields.unoNumeroCelular,
        correoElectronico: watchAllFields.unoCorreoElectronico,
        primerApellido: watchAllFields.unoPrimerApellido,
        segundoApellido: watchAllFields.unoPrimerApellido,
      };
    } else if (avalistIndex === 1) {
      dummie[avalistIndex].solicitante = {
        ...stateSolicitud.avalistas[1].solicitante,
        tipoDocumentoId: parseInt(watchAllFields.dosTipoDocumento),
        numeroDocumento: Number(watchAllFields.dosNumeroDocumento),
        primerNombre: watchAllFields.dosPrimerNombre,
        segundoNombre: watchAllFields.dosSegundoNombre,
        primerApellido: watchAllFields.dosPrimerApellido,
        segundoApellido: watchAllFields.dosSegundoApellido,
        fechaNacimiento:
          typeof watchAllFields.dosFechaNacimiento !== "string"
            ? (watchAllFields.dosFechaNacimiento as any).format("YYYY-MM-DD")
            : watchAllFields.dosFechaNacimiento,
      };
      dummie[avalistIndex].formData = {
        tipoDocumento: parseInt(watchAllFields.dosTipoDocumento),
        numeroDocumento: watchAllFields.dosNumeroDocumento,
        primerNombre: watchAllFields.dosPrimerNombre,
        segundoNombre: watchAllFields.dosSegundoNombre,
        fechaNacimiento:
          typeof watchAllFields.dosFechaNacimiento !== "string"
            ? (watchAllFields.dosFechaNacimiento as any).format("YYYY-MM-DD")
            : watchAllFields.dosFechaNacimiento,
        numeroCelular: watchAllFields.dosNumeroCelular,
        correoElectronico: watchAllFields.dosCorreoElectronico,
        primerApellido: watchAllFields.dosPrimerApellido,
        segundoApellido: watchAllFields.dosPrimerApellido,
      };
    }
    dispatchSolicitud({
      type: UPDATE_AVALISTAS,
      payload: dummie,
    });
    next();
  };

  useEffect(() => {
    const parametersPromises = [getTipoIdentificacion(1)];
    Promise.all(parametersPromises)
      .then(([tiposIdentificacion]) => {
        setDdlOptions({
          ...ddlOptions,
          tiposIdentificacion: tiposIdentificacion.data.result.map(
            (item: any) => {
              return { label: item.nombre, value: item.id };
            }
          ),
        });
      })
      .catch((err) => {
        console.log(err);

        alert("Error: " + err);
      });
  }, []);

  const uploadSolicitantes = (next: any) => {
    // const payload = [];

    // const solicitanteLider = stateSolicitud.solicitanteLider;

    // payload.push({
    //   TipoDocumentoId: solicitanteLider.formData.documentType,
    //   numeroDocumento: solicitanteLider.formData.documentNumber + "",
    //   primerNombre: solicitanteLider.validationData.primerNombre,
    //   segundoNombre: solicitanteLider.validationData.segundoNombre,
    //   primerApellido: solicitanteLider.validationData.primerApellido,
    //   segundoApellido: solicitanteLider.validationData.segundoApellido,
    //   fechaNacimiento: solicitanteLider.validationData.fechaNacimiento,
    // });

    // if (watchAllFields.solicitantes_adicionales > 0) {
    //   payload.push({
    //     TipoDocumentoId: watchAllFields.unoTipoDocumento,
    //     numeroDocumento: watchAllFields.unoNumeroDocumento,
    //     primerNombre: watchAllFields.unoPrimerNombre,
    //     segundoNombre: watchAllFields.unoSegundoNombre,
    //     primerApellido: watchAllFields.unoPrimerApellido,
    //     segundoApellido: watchAllFields.unoSegundoApellido,

    //     fechaNacimiento: typeof watchAllFields.unoFechaNacimiento !== 'string' ? (watchAllFields.unoFechaNacimiento as any).format(
    //       "YYYY-MM-DD"
    //     ): watchAllFields.unoFechaNacimiento,
    //     Celular: watchAllFields.unoNumeroCelular,
    //     Email: watchAllFields.unoCorreoElectronico,
    //   });
    // }

    // if (watchAllFields.solicitantes_adicionales > 1) {
    //   payload.push({
    //     TipoDocumentoId: watchAllFields.dosTipoDocumento,
    //     numeroDocumento: watchAllFields.dosNumeroDocumento,
    //     primerNombre: watchAllFields.dosPrimerNombre,
    //     segundoNombre: watchAllFields.dosSegundoNombre,
    //     primerApellido: watchAllFields.dosPrimerApellido,
    //     segundoApellido: watchAllFields.dosSegundoApellido,

    //     fechaNacimiento: typeof watchAllFields.dosFechaNacimiento !== 'string' ? (watchAllFields.dosFechaNacimiento as any).format(
    //       "YYYY-MM-DD"
    //     ): watchAllFields.dosFechaNacimiento,

    //     Celular: watchAllFields.dosNumeroCelular,
    //     Email: watchAllFields.dosCorreoElectronico,
    //   });
    // }
    // console.log(payload);

    setCurrentTab("simulador");

    // await saveSolicitantes(payload)
    //   .then((res: { data: { result: any } }) => {
    //     // console.log(res.data.result)
    //     const titular = res.data.result.find(
    //       (solicitante: any) =>
    //         solicitante.numeroDocumento ===
    //         stateSolicitud.solicitanteLider.formData.documentNumber
    //     );
    //     if (!titular) {
    //       //Error: no se encontró un usuario en memoria con esa identificación
    //       return;
    //     }

    //     dispatchSolicitud({
    //       type: UPDATE_LIDER_ID,
    //       payload: titular.id,
    //     });
    //   })
    //   .catch((err: any) => {
    //     console.error(err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     return;
    //   });
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      <Wizard>
        <WizardTab onNext={checkAvalistQuantity}>
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuántos solicitantes adicionales tendrá tu crédito?
          </TitleUI>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <CardGroupInput
              options={cardOptions}
              register={register("solicitantes_adicionales", {
                required: "Campo requerido",
                valueAsNumber: true,
              })}
              value={watchAllFields.solicitantes_adicionales}
            />
          </div>
          <InfoBox
            title="Solicitantes adicionales"
            description="Debe ser una persona del grupo familiar primario del titular de crédito. Puedes solicitar el crédito con máximo dos personas."
          />
        </WizardTab>
        <WizardTab onNext={(next: any) => checkAvalistOne(next)}>
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es el documento de identidad del solicitante uno?
          </TitleUI>
          <FlexDivUI flexDirection="column" alignContent="center">
            <Select
              name="unoTipoDocumento"
              error={errors?.unoTipoDocumento?.message || ""}
              title=""
              options={ddlOptions.tiposIdentificacion}
              rules={{ required: "*Campo obligatorio" }}
              control={control as any}
              value={watchAllFields.unoTipoDocumento}
            />
            <Input
              register={register("unoNumeroDocumento", {
                required: "Campo requerido",
              })}
              type="number"
              title=""
              error={errors.unoNumeroDocumento?.message || ""}
              placeholder="Agrega el número"
              className=""
            />
          </FlexDivUI>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(["unoPrimerNombre", "unoPrimerApellido"], next)
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es el nombre del solicitante uno?
          </TitleUI>
          <FlexDivUI justifyContent="center">
            <Input
              register={register("unoPrimerNombre", {
                required: "Campo requerido",
              })}
              type="text"
              title="Primer nombre"
              error={errors.unoPrimerNombre?.message || ""}
              placeholder="Ingresa el primer nombre."
              className="o-gestionarCreditos-container__section__container__input"
              value={watchAllFields.unoPrimerNombre}
            />
            <Input
              register={register("unoSegundoNombre")}
              type="text"
              title="Segundo nombre"
              error={errors.unoSegundoNombre?.message || ""}
              placeholder="Ingresa el segundo nombre"
              value={watchAllFields.unoSegundoNombre}
            />
          </FlexDivUI>
          <FlexDivUI justifyContent="center">
            <Input
              register={register("unoPrimerApellido", {
                required: "* Campo requerido",
              })}
              type="text"
              title="Primer apellido"
              error={errors.unoPrimerApellido?.message || ""}
              placeholder="Ingresa el primer apellido."
              className="o-gestionarCreditos-container__section__container__input"
            />
            <Input
              register={register("unoSegundoApellido")}
              type="text"
              title="Segundo apellido"
              error={errors.unoSegundoApellido?.message || ""}
              placeholder="Ingresa el segundo apellido"
            />
          </FlexDivUI>
          <InfoBox
            title="Verificar datos de contacto"
            description="Es importante que los datos de contacto sean reales porque a través de ellos se firman y se reciben los documentos."
          />
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(["unoFechaNacimiento"], next)
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es la fecha de nacimiento del solicitante uno?
          </TitleUI>
          <FlexDivUI justifyContent="center">
            <Input
              title=""
              error={errors?.unoFechaNacimiento?.message}
              type={"date"}
              controller={control}
              rules={{
                required: "* Campo requerido",
              }}
              name="unoFechaNacimiento"
            />
          </FlexDivUI>
        </WizardTab>
        {watchAllFields.solicitantes_adicionales == 2 && (
          <WizardTab onNext={(next: () => void) => saveAvalistData(next, 0)}>
            <TitleUI as="h2" color="dark" align="center" marginBottom={5}>
              ¡Solicitante adicional uno añadido!
            </TitleUI>
            <TitleUI as="h3" color="dark" align="center">
              Continuemos con los datos del segundo solicitante adicional
            </TitleUI>
          </WizardTab>
        )}
        {watchAllFields.solicitantes_adicionales == 1 && (
          <WizardTab
            onNext={(next: () => void) => {
              saveAvalistData(next, 0);
              uploadSolicitantes(next);
            }}
          >
            <FlexDivUI justifyContent="center">
              <img src="" alt="" />
            </FlexDivUI>
            <FlexDivUI flexDirection="column" alignContent="center">
              <TitleUI as="h2" color="dark" align="center" marginBottom={5}>
                ¡Hola{" "}
                {`${capitalize(
                  stateSolicitud.solicitanteLider.solicitante.primerNombre!
                )} ${capitalize(
                  stateSolicitud.solicitanteLider.solicitante.primerApellido!
                )}`}
                !
              </TitleUI>
              <TitleUI as="h3" color="dark" align="center">
                Ya tenemos los datos del solicitante adicional.
              </TitleUI>
              <TitleUI as="h3" color="primary" align="center">
                Te invitamos a simular tu crédito
              </TitleUI>
            </FlexDivUI>
          </WizardTab>
        )}
        <WizardTab onNext={(next: any) => checkAvalistTwo(next)}>
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es el documento de identidad del solicitante dos?
          </TitleUI>
          <FlexDivUI flexDirection="column" alignContent="center">
            <Select
              name="dosTipoDocumento"
              error={errors?.dosTipoDocumento?.message || ""}
              title=""
              options={ddlOptions.tiposIdentificacion}
              rules={{ required: "*Campo obligatorio" }}
              control={control as any}
              value={watchAllFields.dosTipoDocumento}
            />
            <Input
              register={register("dosNumeroDocumento", {
                required: "Campo requerido",
              })}
              type="number"
              title=""
              error={errors.dosNumeroDocumento?.message || ""}
              placeholder="Agrega el número"
              className=""
            />
          </FlexDivUI>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(["dosPrimerNombre", "dosPrimerApellido"], next)
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es el nombre del solicitante dos?
          </TitleUI>
          <FlexDivUI justifyContent="center" gap={1}>
            <Input
              register={register("dosPrimerNombre", {
                required: "Campo requerido",
              })}
              type="text"
              title="Primer nombre"
              error={errors.dosPrimerNombre?.message || ""}
              placeholder="Ingresa el primer nombre."
            />
            <Input
              register={register("dosSegundoNombre")}
              type="text"
              title="Segundo nombre"
              error={errors.dosSegundoNombre?.message || ""}
              placeholder="Ingresa el segundo nombre"
            />
          </FlexDivUI>
          <FlexDivUI justifyContent="center" gap={1}>
            <Input
              register={register("dosPrimerApellido", {
                required: "* Campo requerido",
              })}
              type="text"
              title="Primer apellido"
              error={errors.dosPrimerApellido?.message || ""}
              placeholder="Ingresa el primer apellido."
            />
            <Input
              register={register("dosSegundoApellido")}
              type="text"
              title="Segundo apellido"
              error={errors.dosSegundoApellido?.message || ""}
              placeholder="Ingresa el segundo apellido"
            />
          </FlexDivUI>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(["dosFechaNacimiento"], next)
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Cuál es la fecha de nacimiento del solicitante dos?
          </TitleUI>
          <FlexDivUI justifyContent="center">
            <Input
              title=""
              error={errors?.dosFechaNacimiento?.message}
              type={"date"}
              controller={control}
              rules={{
                required: "* Campo requerido",
              }}
              name="dosFechaNacimiento"
            />
          </FlexDivUI>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) => {
            saveAvalistData(next, 1);
            uploadSolicitantes(next);
          }}
        >
          <FlexDivUI justifyContent="center">
            <img src="" alt="" />
          </FlexDivUI>
          <FlexDivUI flexDirection="column" alignContent="center">
            <TitleUI as="h2" color="dark" align="center" marginBottom={5}>
              ¡Hola{" "}
              {`${capitalize(
                stateSolicitud.solicitanteLider.solicitante.primerNombre!
              )} ${capitalize(
                stateSolicitud.solicitanteLider.solicitante.primerApellido!
              )}`}
              !
            </TitleUI>
            <TitleUI as="h3" color="dark" align="center">
              Ya tenemos los datos de los solicitantes adicionales.
            </TitleUI>
            <TitleUI as="h3" color="primary" align="center">
              Te invitamos a simular tu crédito
            </TitleUI>
          </FlexDivUI>
        </WizardTab>
        {/* } */}
      </Wizard>
    </WrapperContent>
  );
};

export default SolicitantesAdicionales;
