import React, { useState } from 'react'
import FlexDivUI from '../../atoms/flexible-div-ui/FlexDivUI'
import TitleUI from '../../atoms/title-ui/TitleUI'
import WrapperContent from '../../atoms/wrapper-content/WrapperContent'
import FileDasboard from "../../molecules/file-dashboard/fileDashboard";
import ModalBorrar from "../../../assets/icons/modales/ModalBorrar.svg"

import "./documentosDashboard.scss"
import Modal from '../../atoms/modal/modal'
import Button from '../../atoms/button/button'

const DocumentosDashboard = () => {
    const [page, setPage] = useState(1)
    const [openModal, setOpenModal] = useState(false);
    
    return (
        <div className="o-DocumentosDashboard">
            <Modal open={openModal} handleClose={() => setOpenModal(!openModal)}>
                <div className="o-modal">
                    <img src={ModalBorrar} alt="" />
                </div>
                <div className="o-modal-content">
                    <TitleUI align="center" marginBottom={15} marginTop={25}>
                        ¿Estás seguro que quieres eliminar este documento?
                    </TitleUI>
                </div>
                <FlexDivUI alignContent="center" flexDirection="column">
                <div className="o-modal-buttondivs">
                    <Button
                    text={"CANCELAR"}
                    type="button"
                    classname=""
                    gtmMark="primary"
                    isDisabled={false}
                    onClickAction={() => {
                        setOpenModal(!openModal)
                    }}
                    />
                    &nbsp; &nbsp;
                    <Button
                    text={"CONTINUAR"}
                    type="button"
                    classname=""
                    gtmMark="secondary"
                    isDisabled={false}
                    onClickAction={() => {
                        setOpenModal(!openModal)
                    }}
                    />
                </div>
                </FlexDivUI>
          </Modal>
            <WrapperContent>
                <FlexDivUI justifyContent="flex-start" flexDirection="column">
                    <TitleUI marginBottom={21} marginTop={0} color="primary">
                    Documentos
                    </TitleUI>
                </FlexDivUI>
                <div className="gray-line" />
                <div className="o-DocumentosDashboard__documents">
                    <div className="row">
                        <div className="column-rigth">
                            <FileDasboard
                            name="cedula"
                            isRequired={false}
                            label="Solicitud de subsidio - Frech"
                            processFile={(name, file) => {
                            
                            }}
                            />
                            <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Pagaré y carta de instrucciones"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                            />
                            <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Condiciones de crédito"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                            />
                        </div>
                        <div className="column-left">
                            <FileDasboard
                            name="cedula"
                            isRequired={false}
                            label="Carta preaprobado"
                            processFile={(name, file) => {
                            
                            }}
                            
                            />
                            <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Autorización de desembolso"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                            />
                            <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Carta aprobación"
                                processFile={(name, file) => {
                                
                                }}
                            />
                        </div>
                    </div>
                </div>
            </WrapperContent>
            <div className="o-DocumentosDashboard__header">
                <div>
                <p
                    onClick={() => setPage(1)}
                    className={`o-DocumentosDashboard__header__label ${
                    page === 1 ? '--active' : ''
                    }`}
                >
                    Titular
                </p>
                </div>
                <div>
                <p
                    onClick={() => setPage(2)}
                    className={`o-DocumentosDashboard__header__label ${
                    page === 2 ? '--active' : ''
                    }`}
                >
                    Solicitante adicional 1
                </p>
                </div>
                <div>
                <p
                    onClick={() => setPage(3)}
                    className={`o-DocumentosDashboard__header__label ${
                    page === 3 ? '--active' : ''
                    }`}
                >
                    Solicitante adicional 2
                </p>
                </div>
            </div>

            {page === 1 ? (
                <div className="o-DocumentosDashboard__titular">
                    <div className="o-DocumentosDashboard__titular__content">
                        <div className="o-DocumentosDashboard__titular__content__nombre">
                            Santiago Jaramillo
                        </div>
                        <div className="o-DatosAfiliacion__content__box">
                            Titular
                        </div>
                    </div>
                    <div className="gray-line" />
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Preaprobado</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Respuesta centrales de riesgo"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Términos y condiciones"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Radicación</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Comprobante pago nómina o pensión"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado otros ingresos"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Extracto bancarios"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Fotocopia RUT"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado existencia y representación legal"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Estudio</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formulario de postulación MI Casa Ya"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Indicadores"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formato vida Allianz"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Adjuntar otro documento"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Legalización y desembolso</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Autorización deducción de nómina"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                            </div>
                        </div>
                    </div>
                </div>
            ) : page === 2 ? (
                <div className="o-DocumentosDashboard__titular">
                    <div className="o-DocumentosDashboard__titular__content">
                        <div className="o-DocumentosDashboard__titular__content__nombre">
                            Carlos Mario Gómez
                        </div>
                        <div className="o-DatosAfiliacion__content__box__solicitante">
                            Solicitante adicional 1
                        </div>
                    </div>
                    <div className="gray-line" />
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Preaprobado</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Respuesta centrales de riesgo"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Términos y condiciones"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Radicación</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Comprobante pago nómina o pensión"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado otros ingresos"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Extracto bancarios"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Fotocopia RUT"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado existencia y representación legal"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Estudio</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formulario de postulación MI Casa Ya"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Indicadores"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formato vida Allianz"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Adjuntar otro documento"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Legalización y desembolso</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Autorización deducción de nómina"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="o-DocumentosDashboard__titular">
                    <div className="o-DocumentosDashboard__titular__content">
                        <div className="o-DocumentosDashboard__titular__content__nombre">
                            Pedro Gómez Gonzalez
                        </div>
                        <div className="o-DatosAfiliacion__content__box__solicitante">
                            Solicitante adicional 2
                        </div>
                    </div>
                    <div className="gray-line" />
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Preaprobado</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Respuesta centrales de riesgo"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Términos y condiciones"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Radicación</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Comprobante pago nómina o pensión"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado otros ingresos"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Extracto bancarios"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Fotocopia RUT"
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Certificado existencia y representación legal"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Estudio</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formulario de postulación MI Casa Ya"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Indicadores"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Formato vida Allianz"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Adjuntar otro documento"
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-DocumentosDashboard__titular__content__title">
                        <h2>Legalización y desembolso</h2>
                        <div className="row">
                            <div className="column-rigth">
                                <FileDasboard
                                name="cedula"
                                isRequired={false}
                                label="Autorización deducción de nómina"
                                isEdit={true}
                                setOpenModal={setOpenModal}
                                processFile={(name, file) => {
                                
                                }}
                                />
                            </div>
                            <div className="column-left">
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DocumentosDashboard