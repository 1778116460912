import React, { useEffect, useState } from 'react'
import './menuCardDashboard.scss'
import { Link } from "react-router-dom";

type item = {
    title: string,
    icon: string,
    value: string,
    children?: Array<item>,
}
interface MenuProps {
    icon: any;
    title: string;
    value: any;
    setValue: any;
    index: any;
    children?: any;
    shouldDisplayChildren?: boolean;
    isredirect?: boolean;
    item: item;
    selected: boolean;
    setSelected: any;
    icon2?: any
}


const MenuCardDashboard = ({ item, icon, title, value, index, setValue, children, shouldDisplayChildren = false, isredirect = false, selected, setSelected, icon2 }: MenuProps) => {
    const [shouldSelect, setShouldSelect] = useState(false);

    const print = () => {
        console.log(value + ' : ' + index);
        setValue(index)
    }
    const setSelectedTitle =() => {
    setSelected(!selected)
    setValue(index)
    console.log(selected)
    }
    useEffect(() => {
        if (item.children) {
            const found = item.children.find((child) => child.value === value);
            if (found) setShouldSelect(true);
            else setShouldSelect(false);
        }
    }, [value])

    



    return (
        <>

        {isredirect ? (
            <Link to={index} className='no-style-link'>
                <div
                className={
                    `m-DashboardMenu-container
                        ${(value === index || shouldSelect) ?
                        `m-DashboardMenu--selected` : ''}`
                    }
            >
                <div className={`m-DashboardMenu-container__content`}>
                    <img src={icon} alt={title} width={24}/>
                    <p>{title}</p>
                    {icon2 && <img src={icon2} alt={title + " segundo icono"} width={24}/>}
                </div>
                {/*shouldDisplayChildren && <div className={`m-DashboardMenu-container__children`}>
                        {children}
                    </div>*/}
            </div>
        </Link>

        ) : (
            <div
            className={
                `m-DashboardMenu-container 
                    ${selected ?
                    `m-DashboardMenu-container--selected` : ''}`
                }
        >
            <div onClick={setSelectedTitle} className={`m-DashboardMenu-container__content`}>
                <img src={icon} alt={title} width={24}/>
                <p>{title}</p>
            </div>
            {shouldDisplayChildren && <div className={`m-DashboardMenu-container__children`}>
                    {children}
                </div>}
        </div>
        )}
        
        </>
    )


}

export default MenuCardDashboard;