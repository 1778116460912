import React from "react";

import "./panelFormulario.scss";

interface PanelFormularioProps {
  title: string;
  text: string;
  text2?: string;
  editable?: boolean;
  backgroundInput?: boolean;
}

const PanelFormulario = ({
  title,
  text,
  text2,
  editable,
  backgroundInput,
}: PanelFormularioProps) => {
  const textTitle2 = text2?.split(":")[0];
  const textValue2 = text2?.split(":")[1];

  return (
    <div className="PanelFormulario">
      <div className="PanelFormulario__Title">
        <label>{title}:</label>
      </div>
      <div className="PanelFormulario__Value">
        {editable ? (
          <input
            readOnly
            className={backgroundInput ? "readOnlyGray" : ""}
            value={text}
          />
        ) : (
          <label>{text}</label>
        )}
      </div>
      <div className="PanelFormulario__Value">
        {text2 != undefined && (
          <label>
            <strong>{textTitle2}:</strong>
            {textValue2}
          </label>
        )}
      </div>
    </div>
  );
};

export default PanelFormulario;
