import React from "react";

import icono_gracias from "../../../assets/icons/terminosCondiciones/icono_gracias.svg";
import Button from "../../atoms/button/button";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";

import "./solicitudEspera.scss";

const SolicitudEspera = () => {
  return (
    <WrapperContent>
      <div className="o-SolicitudEspera">
        <div className="o-SolicitudEspera__Img">
          <img src={icono_gracias} alt="icono_gracias" />
        </div>
        <div className="o-SolicitudEspera__Title">
          <h1>¡Ya tu formulario quedó listo!</h1>{" "}
        </div>
        <div className="o-SolicitudEspera__Text">
          <h4>
            para continuar el proceso los demás solicitantes deben completar su
            información
          </h4>
        </div>
        <div className="o-SolicitudEspera__Button">
          <Button
            type="input"
            classname=""
            text={"FINALIZAR"}
            isDisabled={false}
            gtmMark="secondary"
          />
        </div>
      </div>
    </WrapperContent>
  );
};

export default SolicitudEspera;
