import axios from "axios";

const axiosBase = axios.create();

axiosBase.interceptors.request.use(
    (config: any) => {
      const token = sessionStorage.getItem('user-jwt');
  
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete axiosBase.defaults.headers.common.Authorization;
        console.log("No token found");
      }
      return config;
    },
  
    error => Promise.reject(error)
  );

export default axiosBase;