import React from 'react'
import './tooltip.scss';
import tooltipImage from '../../../assets/icons/simulador/Tool_tip.svg'

interface TooltipProps {
    title: string;
    subtitle: string;
}

const Tooltip = ({ title, subtitle }: TooltipProps) => {
    return (
        <div className='a-tooltip'>
            <img
                src={tooltipImage}
                alt="Información"
                className="a-tooltip__img"
            />
            <div className="a-tooltip__tooltip-info">
                <h3 className="a-tooltip__tooltip-info__title">
                    {title}
                </h3>
                <p className="a-tooltip__tooltip-info__description">
                    {subtitle}
                </p>
            </div>
        </div>
    )
}

export default Tooltip