import React, { useEffect, useReducer, useState } from 'react'
import Circle from '../../atoms/circle/circle';
import './table.scss';
import iconDetalle from '../../../assets/icons/icon_consultar.svg';
import Loading from "../../atoms/loading/loading";
import { useNavigate } from "react-router-dom";
import reducerSolicitud, { initialSolicitud } from "../../../reducers/reducerSolicitud";
import {
  getLineaCredito,
  getTipoIdentificacion,
} from "../../../services/parametrizacionServices";

interface TableProps {
    headers: Array<{ title: string, value: string }>;
    body: any;
    titular?: any;
}

const Table = ({ headers, body, titular }: TableProps) => {
    const [loading, setLoading] = useState(false);
    const [lineaCredito, setLineaCredito] = useState([]);
    const [tipoIdentificacion, setTipoIdentificacion] = useState([]);
    const [stateSolicitud, dispatchSolicitud] = useReducer(
      reducerSolicitud,
      initialSolicitud
    );    

    const navigate = useNavigate();

    
    const changeDateFormat = (date: string) => {
        let otherDate = date.split('T')
        
        let newDate = otherDate[0].split('-')
        let hour = otherDate[1].split('.')
    
        if (newDate[1] === '01') {
          newDate[1] = 'Enero'
        } else if (newDate[1] === '02') {
          newDate[1] = 'Febrero'
        } else if (newDate[1] === '03') {
          newDate[1] = 'Marzo'
        } else if (newDate[1] === '04') {
          newDate[1] = 'Abril'
        } else if (newDate[1] === '05') {
          newDate[1] = 'Mayo'
        } else if (newDate[1] === '06') {
          newDate[1] = 'Junio'
        } else if (newDate[1] === '07') {
          newDate[1] = 'Julio'
        } else if (newDate[1] === '08') {
          newDate[1] = 'Agosto'
        } else if (newDate[1] === '09') {
          newDate[1] = 'Septiembre'
        } else if (newDate[1] === '10') {
          newDate[1] = 'Octubre'
        } else if (newDate[1] === '11') {
          newDate[1] = 'Noviembre'
        } else if (newDate[1] === '12') {
          newDate[1] = 'Diciembre'
        }
        return newDate[2] + ' de ' + newDate[1] + ' ' + newDate[0] + ' ' + hour[0]
      }
    
      const handleClick = (linea: string, numero: string) => {
        let lineaCreditoValue = ""
        let tipoDocumentoValue = ""
      
        lineaCredito.forEach((element: any) => {
          if (element.nombre == linea) {
            lineaCreditoValue = element.codigo
          }
        });
        tipoIdentificacion.forEach((element: any) => {
          if (element.codigo == titular.tipoDocumento) {
            tipoDocumentoValue = String(element.id)
          }
        });
        const titularCredito2 =  {
            lineaCreditoId : lineaCreditoValue,
            tipoDocumentoId : tipoDocumentoValue,
            numeroDocumento : titular.numeroDocumento
          };
          console.log(titularCredito2);
          const titularCredito = {
            lineaCreditoId: lineaCreditoValue,
            tipoDocumentoId: tipoDocumentoValue,
            numeroDocumento: titular.numeroDocumento
          }
        // dispatchSolicitud({
        //   type: "ADD_TITULAR_CREDITO",
        //   payload: {
        //     titularCredito: {
        //       lineaCreditoId: lineaCreditoValue,
        //       tipoDocumentoId: tipoDocumentoValue,
        //       numeroDocumento: titular.numeroDocumento
        //     },
        //   },
        // });
        setLoading(true);
        
        localStorage.setItem("solicitudID", numero)
        localStorage.setItem("lineaCreditoId", lineaCreditoValue)
        localStorage.setItem("tipoDocumentoId", tipoDocumentoValue)
        localStorage.setItem("numeroDocumento", titular.numeroDocumento)



        navigate("/info-titular")
        setLoading(false);
      }

      useEffect(() => {
        Promise.all([getLineaCredito(), getTipoIdentificacion(1)])
          .then((response) => {
            setLineaCredito(response[0].data.result)
            setTipoIdentificacion(response[1].data.result)
          })
          .catch((error) => console.error(error));
    
      }, []);

      return (
        <table className="m-table" cellSpacing="0">
            {loading && <Loading />}
            <thead className="m-table__head">
                <tr>
                    {
                        headers.map((header) => <th>{header.title}</th>)
                    }
                </tr>
            </thead>
            <tbody className="m-table__body">
                {
                    body.map((content: any) => (
                        <tr>
                            {
                                headers.map((header) => (
                                    <td>
                                                                               
                                        <div style={{ display: header.value === "viabilidad" ? "inline-flex" : "", alignItems: "center", height: "", justifyContent: "center" }}>
                                            {header.value === "viabilidad" && <Circle color={`${content[header.value] === "Rechazado" ? '#F31147' : '#2A9C9D'}`} />}
                                            {header.value === "detalle" && <button onClick={() => handleClick(content["lineaCredito"], content["numeroSolicitud"])}><img src={iconDetalle} alt="IconoDetalle"/></button>}
                                            {header.value === "fechaCreacion" && changeDateFormat(content[header.value])}
                                            {header.value !== "fechaCreacion" && content[header.value]}
                                        </div>
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default Table;