import React, { useContext, useEffect, /* useEffect,*/ useState } from "react";
import { useForm } from "react-hook-form";
import viviendaArrendada from "../../../assets/icons/icono_vivienda_arrendada.svg";
import viviendaPropia from "../../../assets/icons/icono_vivienda_propia.svg";
import viviendaFamiliar from "../../../assets/icons/icono_vivienda_familiar.svg";
import direccionCasa from "../../../assets/icons/direccion.svg";
import completeSteps from "../../../assets/icons/vamosbiencomplete.svg";
import { AppContext } from "../../../contexts/appContext";
import iconCelular from "../../../assets/icons/icon_celular.svg";
import iconCorreo from "../../../assets/icons/icon_correo.svg";

import InfoBox from "../../atoms/info-box/infoBox";
import Input from "../../atoms/input/input";
import TitleUI from "../../atoms/title-ui/TitleUI";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import "./datosContactos.scss";
import Datalist from "../../atoms/datalist/datalist";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import Button from "../../atoms/button/button";
import ParametrizacionContext from "../../../contexts/parametrizacionContext";
import {
  getTipoVivienda,
  getCuadrante,
  getVia,
  getTipoVia,
} from "../../../services/informacionDireccion";
import Loading from "../../atoms/loading/loading";
import {
  UPDATE_CONTACT_AVALISTA,
  UPDATE_CONTACT_LIDER,
} from "../../../reducers/reducerSolicitud";
import { capitalize } from "../../../utils/capitalizeFirstLetter";
import { saveContactos } from "../../../services/orquestadorServices";
// import { nextTick } from "process";

const cardOptions = [
  {
    title: "Propia",
    value: 1,
    img: viviendaPropia,
  },
  {
    title: "Arrendada",
    value: 2,
    img: viviendaArrendada,
  },
  {
    title: "Familiar",
    value: 3,
    img: viviendaFamiliar,
  },
];

const estratoData = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
];

type TFields = {
  tipoVivienda: string;
  tiempoResidencia: string;
  tipoVia: string;
  numero1: string;
  letra1: string;
  cuadrante1: string;
  numero2: string;
  viviendaLetrados: string;
  viviendaCuadrantedos: string;
  numero3: string;
  informacionAdicional: string;
  estrato: string;
  lugarResidencia: string;
  celular: string;
  email: string;
  otroNumero: string;
};

const DatosContactos = (props: any) => {
  const { setCurrentTab, stateSolicitud, dispatchSolicitud, isRadication } =
    useContext(AppContext);
    console.log(stateSolicitud);
    
  const { municipios } = useContext(ParametrizacionContext);
  const [currentRadio, setCurrentRadio] = useState(0);
  const [cityValue, setCityValue] = useState("");
  const [dlloMunicipios, setMunicipios] = useState([]);
  const [dllTipoVivienda, setTipoVivienda] = useState(0);

  const [dlNacimiento, setDlNacimiento] = useState({
    label: "",
    value: undefined,
  });
  const [openModal, setOpenModal] = useState(false);
  const [currentFunction, setCurrentFunction] = useState(
    () => () => console.log("defecto")
  );
  const [direccionDatos, setDireccionDatos] = useState<any>({
    DataTipoVivienda: {},
    DataMunicipios: {},
    DataCuadrante: {},
    dataVias: {},
    dataTipoVia: {},
  });
  const [loading, setLoading] = useState(true);

  const CloseModal = () => {
    setOpenModal(!openModal);
  };

  const WebData = async () => {
    setLoading(true);
    const tempMunicipios: any = municipios?.map((municipio) => ({
      label: `${municipio.nombre} - ${municipio.departamento}`,
      value: municipio.codigoDane,
    }));
    const CallApi = [getTipoVivienda(), getCuadrante(), getVia(), getTipoVia()];
    await Promise.all(CallApi)
      .then(([getTipoViviendas, getCuadrantes, getVias, getTipoVias]) => {
        setLoading(true);
    

        setDireccionDatos({
          ...direccionDatos,
          DataMunicipios: tempMunicipios,
          DataTipoVivienda: getTipoViviendas.data.result.map((item: any) => {
            return {
              title: item.nombre,
              value: parseInt(item.codigo),
              img:
                item.nombre === "Propia"
                  ? viviendaPropia
                  : item.nombre === "Arrendada"
                  ? viviendaArrendada
                  : item.nombre === "Familiar"
                  ? viviendaFamiliar
                  : "",
            };
          }),
          DataCuadrante: getCuadrantes.data.result.map((item: any) => {
            return {
              label: item.nombre,
              value: item.codigo,
            };
          }),
          dataVias: getVias.data.result.map((item: any) => {
            return {
              label: item.nombre,
              value: item.codigo,
            };
          }),
          dataTipoVia: getTipoVias.data.result.map((item: any) => {
            return {
              label: item.nombre,
              value: item.nombre,
              id: item.codigo,
            };
          }),
        });
      })
      .catch((err) => {
        alert("Error: " + err);
      });
    setLoading(false);
  };
  
  useEffect(() => {
    WebData();
    HandleData();
    HandleSelect();
  }, []);

  useEffect(() => {
    HandleData();
  }, [stateSolicitud.solicitanteLider.stateSolicitante]);

  const HandleSelect = () => {
    let tempMunicipios: any = [];
    let dataValue: any;
    municipios.forEach((element) => {
      const municipioSelect = {
        label: `${element.departamento} - ${element.nombre}`,
        value: element.codigoDane,
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        if (
          String(municipioSelect.value) ==
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.lugarExpedicion
        ) {
          dataValue = municipioSelect;
        }
      } else {
        if (
          String(municipioSelect.value) ==
          stateSolicitud.solicitanteLider.solicitante.lugarNacimiento
        ) {
          dataValue = municipioSelect;
        }
      }

      tempMunicipios.push(municipioSelect);

      if (dataValue != undefined) {
        setDlNacimiento(dataValue);
      }
    });
    setMunicipios(tempMunicipios);
  };
  const HandleData = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      console.log(direccionDatos);
      setValue(
        "tiempoResidencia",
        String(
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.informacionDomicilio?.tiempoResidencia
        ) || ""
      );
      if (direccionDatos.dataTipoVia.length > 0) {
        direccionDatos.dataTipoVia.forEach((item: any) => {
          let indexAvalista =
            stateSolicitud.solicitanteLider.stateSolicitante &&
            stateSolicitud.solicitanteLider.stateSolicitante - 1;

          if (indexAvalista) {
            if (
              item.codigo ==
              stateSolicitud.avalistas[indexAvalista || 0].solicitante
                .informacionDomicilio?.tipoVia
            ) {
              setValue("tipoVia", item.nombre || "");
            }
          }
        });
      }
      setValue(
        "numero1",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.numero1 || ""
      );
      setValue(
        "letra1",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.letra1 || ""
      );
      setValue(
        "cuadrante1",
        capitalize(
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.informacionDomicilio?.cuadrante1 || ""
        ) || ""
      );
      setValue(
        "numero2",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.numero2 || ""
      );
      setValue(
        "viviendaLetrados",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.letra2 || ""
      );
      setValue(
        "viviendaCuadrantedos",
        capitalize(
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.informacionDomicilio?.cuadrante2 || ""
        ) || ""
      );
      setValue(
        "numero3",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.numero3 || ""
      );
      setValue(
        "informacionAdicional",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.informacionAdicional || ""
      );
      setValue(
        "estrato",
        String(
          stateSolicitud.avalistas[
            stateSolicitud.solicitanteLider.stateSolicitante - 1
          ].solicitante.informacionDomicilio?.estrato
        ) || ""
      );
      setValue(
        "lugarResidencia",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionDomicilio?.lugarResidencia || ""
      );

      setValue(
        "celular",
        stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante - 1].solicitante.celular || ""
      );
      setValue(
        "email",
        stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante - 1].solicitante.email || ""
      );
      setValue(
        "otroNumero",
        stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante - 1].solicitante.otroNumero || ""
      );

      setValue("tipoVia", stateSolicitud.avalistas[
        stateSolicitud.solicitanteLider.stateSolicitante - 1
      ].solicitante.informacionDomicilio?.tipoVia || "");

      setValue("tipoVivienda", stateSolicitud.avalistas[
        stateSolicitud.solicitanteLider.stateSolicitante - 1
      ].solicitante.informacionDomicilio?.tipoVivienda || "");

    } else {
      setValue(
        "tiempoResidencia",
        String(
          stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
            ?.tiempoResidencia
        ) || ""
      );

      if (direccionDatos.dataTipoVia.length > 0) {
        direccionDatos.dataTipoVia.forEach((item: any) => {
          if (
            item.codigo ==
            stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
              ?.tipoVia
          ) {
            setValue("tipoVia", item.nombre || "");
          }
        });
      }
      setValue(
        "numero1",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.numero1 || ""
      );
      setValue(
        "letra1",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.letra1 || ""
      );
      setValue(
        "cuadrante1",
        capitalize(
          stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
            ?.cuadrante1 || ""
        ) || ""
      );
      console.log(
        capitalize(
          stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
            ?.cuadrante1 || ""
        )
      );

      setValue(
        "numero2",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.numero2 || ""
      );
      setValue(
        "viviendaLetrados",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.letra2 || ""
      );
      setValue(
        "viviendaCuadrantedos",
        capitalize(
          stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
            ?.cuadrante2 || ""
        ) || ""
      );
      setValue(
        "numero3",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.numero3 || ""
      );
      setValue(
        "informacionAdicional",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.informacionAdicional || ""
      );
      setValue(
        "estrato",
        String(
          stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
            ?.estrato
        ) || ""
      );
      setValue(
        "lugarResidencia",
        stateSolicitud.solicitanteLider.solicitante.informacionDomicilio
          ?.lugarResidencia || ""
      );
      setValue(
        "celular",
        stateSolicitud.solicitanteLider.solicitante.celular || ""
      );
      setValue(
        "email",
        stateSolicitud.solicitanteLider.solicitante.email || ""
      );
      setValue(
        "otroNumero",
        stateSolicitud.solicitanteLider.solicitante.otroNumero || ""
      );

      setValue("tipoVia", stateSolicitud.solicitanteLider.solicitante.informacionDomicilio?.tipoVia || "");
      setValue("tipoVivienda", stateSolicitud.solicitanteLider.solicitante.informacionDomicilio?.tipoVivienda || "");

    }

    let tempMunicipios: any = [];
    let dataValue: any;

    municipios.forEach((element) => {
      if (String(element.codigoDane) === watchAllFields.lugarResidencia) {
        setCityValue(element.nombre);
      }

      const municipioSelect = {
        label: `${element.departamento} - ${element.nombre}`,
        value: element.codigoDane,
      };
      tempMunicipios.push(municipioSelect);
    });
    setMunicipios(tempMunicipios);
    setLoading(false);
  };

  const {
    register,
    //handleSubmit,
    watch,
    trigger,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipoVivienda: "",
      tiempoResidencia: "",
      tipoVia: "",
      numero1: "",
      letra1: "",
      cuadrante1: "",
      numero2: "",
      viviendaLetrados: "",
      viviendaCuadrantedos: "",
      numero3: "",
      informacionAdicional: "",
      estrato: "",
      lugarResidencia: "",
      celular: "",
      email: "",
      otroNumero: "",
    },
  });
  let watchAllFields = watch();
  const checkValidity = async (
    fields: Array<keyof TFields>,
    next: () => void
  ) => {
    const isValid = await trigger(fields);
    if (isValid) {
      const payload: any = {
        data: {},
        informacionDomicilio: {},
      };
      fields.forEach((field: keyof TFields) => {
        if (
          field === "celular" ||
          field === "email" ||
          field === "otroNumero"
        ) {
          payload.data[field] = watchAllFields[field];
        } else {
          
          payload.informacionDomicilio[field] = watchAllFields[field];
        }
      });
      console.log(watchAllFields);
      
      console.log(fields);
      
      console.log(payload);
      

      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        dispatchSolicitud({
          type: UPDATE_CONTACT_AVALISTA,
          payload,
        });
      } else {
        dispatchSolicitud({
          type: UPDATE_CONTACT_LIDER,
          payload,
        });
      }

      console.log(stateSolicitud);
      next();
    }
  };
  const checkValidityModal = async (fields: any[], next: () => void) => {
    let isFilled = await trigger(fields);
    if (isFilled) goNext(next);
  };

  const goNext = (next: () => void) => {
    setCurrentFunction(() => next);
    CloseModal();
  };

  const saveData = async (next: () => void) => {
    console.log('next');

    if (isRadication) {
      setLoading(true);
      let indexSolicitante =
        stateSolicitud.solicitanteLider.stateSolicitante || 0;
      let dataSend = {}
      try {
        if (indexSolicitante === 0) {
          dataSend = {
            ...stateSolicitud.solicitanteLider.solicitante.informacionDomicilio,
            lugarResidencia: String(stateSolicitud.solicitanteLider.solicitante.informacionDomicilio?.lugarResidencia),
            celular: stateSolicitud.solicitanteLider.solicitante.celular,
            email: stateSolicitud.solicitanteLider.solicitante.email,
            otroNumero: stateSolicitud.solicitanteLider.solicitante.otroNumero || ''
          }
          await saveContactos(
            dataSend
          );
        } else {
          dataSend = {
            ...stateSolicitud.avalistas[indexSolicitante - 1].solicitante
            .informacionDomicilio,
            lugarResidencia: String(stateSolicitud.avalistas[indexSolicitante - 1].solicitante
              .informacionDomicilio?.lugarResidencia),
            celular: stateSolicitud.avalistas[indexSolicitante - 1].solicitante
            .celular,
            email: stateSolicitud.avalistas[indexSolicitante - 1].solicitante
            .email,
            otroNumero: stateSolicitud.avalistas[indexSolicitante - 1].solicitante
            .otroNumero || ''
          }
          await saveContactos(
            dataSend
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    setCurrentTab("actividadEconomica");
  };

  const HandleSave = (next: () => void) => {
    
    const payload: any = {
      data: {},
      informacionDomicilio: {},
    };
    payload.data = {
      celular: watchAllFields.celular,
      email: watchAllFields.email,
      otroNumero: watchAllFields.otroNumero,
    }
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      dispatchSolicitud({
        type: UPDATE_CONTACT_AVALISTA,
        payload,
      });
    } else {
      dispatchSolicitud({
        type: UPDATE_CONTACT_LIDER,
        payload,
      });
    }
    next();
    // setCurrentTab("actividadEconomica");
  };

  useEffect(() => {
    municipios.forEach((element) => {
      if (String(element.codigoDane) === watchAllFields.lugarResidencia) {
        setCityValue(element.nombre);
      }
    });
  }, [watchAllFields.lugarResidencia]);

  return (
    <WrapperContent>
      {loading && <Loading />}
      <Wizard>

      <WizardTab
          onNext={(next: () => void) =>
            checkValidityModal(["celular", "email", "otroNumero"], next)
          }
        >
          <Modal open={openModal} handleClose={() => CloseModal()}>
            <FlexDivUI alignContent="center" flexDirection="column">
              <TitleUI align="center">
                Revisa que los datos de contacto estén correctos
              </TitleUI>

              <div className="o-modal-content">
                <div className="o-modal-content-icon-text">
                  <img src={iconCelular} alt="IconoCelular" />
                  <div className="o-modal-content-icon-text-desc">
                    <h3 className="o-modal-content-icon-text-desc-title">
                      Número de celular
                    </h3>
                    <p className="o-modal-content-icon-text-desc-text">
                      {watchAllFields.celular}
                    </p>
                  </div>
                </div>
                <div className="o-modal-content-icon-text">
                  <img src={iconCorreo} alt="IconoCorreo" />
                  <div className="o-modal-content-icon-text-desc">
                    <h3 className="o-modal-content-icon-text-desc-title">
                      Correo electrónico
                    </h3>
                    <p className="o-modal-content-icon-text-desc-text">
                      {watchAllFields.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="o-modal-buttondivs">
                <Button
                  text={"DEBO CORREGIR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    CloseModal();
                  }}
                />
                &nbsp; &nbsp;
                <Button
                  text={"CONTINUAR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    HandleSave(currentFunction);
                    CloseModal();
                  }}
                />
              </div>
            </FlexDivUI>
          </Modal>

          <TitleUI as="h2" color="dark" align="center">
            ¿Cuáles son tus datos de contacto?
          </TitleUI>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
              flexDirection: "column",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                margin: "0 auto",
              }}
            >
              <Input
                register={register("celular", {
                  required: "Campo requerido",
                })}
                error={errors.celular?.message || ""}
                title={"Número de celular"}
                type={"number"}
                placeholder={"Agrega el número"}
                className="o-datosContactos-checkPhone"
              />
              <Input
                register={register("otroNumero", {
                  //required: "Campo requerido"
                })}
                error={errors.otroNumero?.message || ""}
                title={"Otro número"}
                type={"number"}
                placeholder={"Agrega el número"}
                className="o-datosContactos-checkPhone"
              />
              <Input
                register={register("email", {
                  required: "Campo requerido",
                })}
                error={errors.email?.message || ""}
                title={"Correo electrónico"}
                type={"email"}
                placeholder={"Agrega el correo electrónico"}
                className="o-datosContactos-checkPhone"
              />
            </form>

            <InfoBox
              classNa={"o-datosContactos-infoBox"}
              title="Verificar datos de contacto"
              description="Es importante que los datos de contacto sean reales porque a través de ellos se firman y se reciben los documentos."
            />
          </div>
        </WizardTab>


        <WizardTab
          onNext={(next: () => void) => checkValidity(["tipoVivienda"], next)}
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿En qué tipo de vivienda vives?
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            {Object.keys(direccionDatos.DataTipoVivienda).length !== 0 ? (
              <CardGroupInput
                options={direccionDatos.DataTipoVivienda}
                register={register("tipoVivienda", {
                  required: "Campo requerido",
                })}
                value={
                  stateSolicitud.solicitanteLider.stateSolicitante
                    ? stateSolicitud.avalistas[
                        stateSolicitud.solicitanteLider.stateSolicitante - 1
                      ].solicitante.informacionDomicilio?.tipoVivienda
                    : stateSolicitud.solicitanteLider.solicitante
                        .informacionDomicilio?.tipoVivienda
                }
              />
            ) : (
              ""
            )}
          </div>
        </WizardTab>
        
        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(["tiempoResidencia"], next)
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Hace cuánto tiempo vives ahí?
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            <form>
              <Input
                register={register("tiempoResidencia", {
                  required: "Campo requerido",
                })}
                options={[
                  { value: 1, label: "1 mes" },
                  { value: 2, label: "2 meses" },
                  { value: 3, label: "3 meses" },
                  { value: 4, label: "4 meses" },
                  { value: 5, label: "5 meses" },
                ]}
                error={errors.tiempoResidencia?.message || ""}
                type={"select"}
                value={
                  stateSolicitud.solicitanteLider.stateSolicitante
                    ? stateSolicitud.avalistas[
                        stateSolicitud.solicitanteLider.stateSolicitante - 1
                      ].solicitante.informacionDomicilio?.tiempoResidencia
                    : stateSolicitud.solicitanteLider.solicitante
                        .informacionDomicilio?.tiempoResidencia
                }
                placeholder={"Selecciona el Tiempo"}
                className="o-datosContactos-container__section__container__input"
              />
            </form>
          </div>
        </WizardTab>

        <WizardTab
          onNext={(next: () => void) =>
            checkValidity(
              [
                "tipoVia",
                "numero1",
                "letra1",
                "cuadrante1",
                "lugarResidencia" /*,"numero2","ViviendaLetrados","ViviendaCuadrantedos","numero3","informacionAdicional"*/,
                "estrato",
              ],
              next
            )
          }
        >
          <TitleUI as="h2" color="dark" align="center">
            ¿Hace cuánto tiempo vives ahí?
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
              flexDirection: "column",
            }}
          >
            <div className={"o-datosContactos-completeDirection"}>
              <div
                className={"o-datosContactos-completeDirection-icons"}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={direccionCasa} alt={"ImagenDireccionCasa"} />
                <h3 style={{ marginLeft: "10px" }}>Dirección</h3>
              </div>
              <div className={"o-datosContactos-completeDirection-box"}>
                <p style={{ margin: "12px 20px 10px 20px" }}>
                  {watchAllFields.tipoVia + " "} {watchAllFields.numero1 + " "}
                  {watchAllFields.letra1 + " "}{" "}
                  {watchAllFields.cuadrante1 + " "}
                  {watchAllFields.numero2 + " "}{" "}
                  {watchAllFields.viviendaLetrados + " "}
                  {watchAllFields.viviendaCuadrantedos + " "}{" "}
                  {watchAllFields.numero3 + " "}
                </p>
              </div>
            </div>
            <form className={"o-datosContactos-formDirection"}>
              <Input
                register={register("tipoVia", {
                  required: "Campo requerido",
                })}
                options={direccionDatos.dataTipoVia}
                error={errors.tipoVia?.message || ""}
                type={"select"}
                title={"Tipo vía"}
                placeholder={"Selecciona"}
                className="o-datosContactos-container__section__container__input"
              />
              <Input
                register={register("numero1", {
                  required: "Campo requerido",
                })}
                error={errors.numero1?.message || ""}
                title={"Número"}
                type={"number"}
                placeholder={"Agregar"}
                className="o-datosContactos-container__section__container__input"
              />
              <Input
                register={register("letra1", {
                  required: "Campo requerido",
                })}
                error={errors.letra1?.message || ""}
                title={"Letra"}
                type={"select"}
                placeholder={"Selecciona"}
                options={direccionDatos.dataVias}
                className="o-datosContactos-container__section__container__input"
              />
              <Input
                register={register("cuadrante1")}
                error={errors.cuadrante1?.message || ""}
                title={"Cuadrante"}
                type={"select"}
                options={direccionDatos.DataCuadrante}
                placeholder={"Selecciona"}
                className="o-datosContactos-container__section__container__input"
              />

              <Input
                register={register("numero2", {
                  //required: "Campo requerido"
                })}
                error={errors.numero2?.message || ""}
                title={"Número"}
                type={"number"}
                placeholder={"Agregar"}
                className="o-datosContactos-container__section__container__input"
              />

              <Input
                register={register("viviendaLetrados", {
                  //required: "Campo requerido"
                })}
                error={errors.viviendaLetrados?.message || ""}
                title={"Letra"}
                type={"select"}
                placeholder={"Selecciona"}
                options={direccionDatos.dataVias}
                className="o-datosContactos-container__section__container__input"
              />
              <Input
                register={register("viviendaCuadrantedos", {
                  //required: "Campo requerido"
                })}
                error={errors.viviendaCuadrantedos?.message || ""}
                title={"Cuadrante"}
                type={"select"}
                options={direccionDatos.DataCuadrante}
                placeholder={"Selecciona"}
                className="o-datosContactos-container__section__container__input"
              />

              <Input
                register={register("numero3", {
                  //required: "Campo requerido"
                })}
                error={errors.numero3?.message || ""}
                title={"Número"}
                type={"number"}
                placeholder={"Agregar"}
                className="o-datosContactos-container__section__container__input"
              />

              <Input
                register={register("informacionAdicional", {
                  //required: "Campo requerido"
                })}
                error={errors.informacionAdicional?.message || ""}
                title={
                  "Información adicional - complemento. Ejemplo: Apartamento, Edificio, Finca"
                }
                type={"text"}
                placeholder={"Ingresa"}
                className="o-datosContactos-AdditionalInfo"
              />
              <Datalist
                title="Ciudad de Residencia"
                name="lugarResidencia"
                options={dlloMunicipios}
                rules={{ required: "* Campo obligatorio" }}
                control={control as any}
                valueSelect={dlNacimiento}
              />
              <Input
                register={register("estrato", {
                  required: "Campo requerido",
                })}
                error={errors.estrato?.message || ""}
                title={"Estrato"}
                type={"select"}
                options={estratoData}
                placeholder={"Selecciona"}
                className="o-datosContactos-container__section__container__input"
                value={watchAllFields.estrato}
              />
            </form>
          </div>
        </WizardTab>


        <WizardTab onNext={(next: () => void) => saveData(next)}>
          <div style={{ width: "40px", height: "40px", margin: "0 auto" }}>
            <img
              src={completeSteps}
              style={{ width: "100%", height: "100%" }}
              alt="ImagenPasoCompleto"
              title={"completesvg"}
            />
          </div>
          <TitleUI as="h2" color="dark" align="center">
            ¡Vamos muy bien!
          </TitleUI>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
              flexDirection: "column",
            }}
          >
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Ahora te preguntaremos un poco sobre tu actividad economica
            </p>
          </div>
        </WizardTab>

      </Wizard>
    </WrapperContent>
  );
};

export default DatosContactos;
