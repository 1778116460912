import axios from "./axiosConfig";

export const saveFile = async (payload: any) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_FILES_API}/docuware/upload`,
    headers: {},
    data: payload,
  };
  return axios(config);
};
