let copCOL = Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

const currencyFormat = (val: number) => {
  if (isNaN(val)) return val;
  return copCOL.format(val);
};

export default currencyFormat;
