import React, { useContext, useEffect, useState } from "react";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Loading from "../../atoms/loading/loading";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Divider from "../../atoms/divider/divider";
import Button from "../../atoms/button/button";
import Modal from "../../atoms/modal/modal";

import "./procesarCredito.scss";
import InputForm from "../../atoms/input-form/inputForm";
import { getValidationRadication } from "../../../services/validadorServices";
import { AppContext } from "../../../contexts/appContext";
import { updateSolicitud } from "../../../services/orquestadorServices";
import { useNavigate } from "react-router-dom";
import errorsDictionary from "../../../utils/errors";
import {
  TErrorsValidation,
  TErrorDictionary,
} from "../../../interfaces/solicitudInterface";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import ModalCambiarValor from "../../molecules/modal-cambiar-valor/modalCambiarValor";
import CambiarValor from "../cambiar-valor/cambiarValor";
import { TSettle } from "../gestionar-creditos/gestionarCreditos";
import { getData } from "../../../services/gestionarCreditosServices";
import currencyFormat from "../../../utils/currencyFormat";
import ModalStudy from "../../molecules/modal-study/modalStudy";

export type TDataModal = {
  type: string;
  text: string;
  title: string;
};

const ProcesarCredito = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const errorsList: TErrorDictionary[] = errorsDictionary;
  const [showError, setShowError] = useState(false);
  const [dataError, setDataError] = useState<TErrorDictionary>();
  const [showModalValue, setShowModalValue] = useState(false);
  const [isChangeValue, setIsChangeValue] = useState(false);
  const [dataSettle, setDataSettle] = useState<TSettle>({
    lineaCredito: "",
    valorMonto: 0,
    plazo: 0,
    tasaInteres: "",
    tiempoAmortizacion: 0,
    vigenciaOperacion: "",
    vigenciaOperacionString: "",
  });
  const [showModalStudy, setShowModalStudy] = useState(false);
  const [dataModalStudy, setDataModalStudy] = useState<TDataModal>();

  const {
    stateSolicitud,
    setCurrentTab,
    setIsRadication,
    isRadication,
    setIsViable,
  } = useContext(AppContext);
  const solicitanteLider = stateSolicitud.solicitanteLider;

  const handleDesist = () => {
    setOpenModal(true);
  };

  const handleChange = (value: string) => {
    setReason(value);
    value.length < 1 ? setDisabledButton(true) : setDisabledButton(false);
  };

  const handleAccept = async () => {
    setLoading(true);
    let data = {
      solicitudId: solicitanteLider.solicitudData.id,
      estadoId: 11,
      observacion: reason,
    };
    try {
      let response = await updateSolicitud(data);
      if (response.status === 200) {
        setReason("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModal(false);
      setLoading(false);
      setIsViable(false);
      setCurrentTab("gestionarTusCreditos");
    }
  };

  const handleRequest = async () => {
    setLoading(true);
    if (isRadication) {
      setLoading(true);
      let data = {
        solicitudId: solicitanteLider.solicitudData.id,
        estadoId: 11, //Pendiente porque debe ser otro estado
        observacion: reason,
      };
      try {
        let response = await updateSolicitud(data);
        if (response.status === 200) {
          setReason("");
          setShowModalStudy(true);
          setDataModalStudy({
            type: "success",
            text: "Tu crédito fue enviado a estudio, en los próximos días te contactará un asesor.",
            title: "¡Felicitaciones!",
          });
          setIsViable(false);
          setCurrentTab("gestionarTusCreditos");
        } else {
          setShowModalStudy(true);
          setDataModalStudy({
            type: "error",
            text: "Este crédito aun no puede pasar a estudio, revisa que la información de cada formulario este completa.",
            title: "¡Lo sentimos!",
          });
        }
      } catch (error) {
        console.log(error);
        setShowModalStudy(true);
        setDataModalStudy({
          type: "error",
          text: "Este crédito aun no puede pasar a estudio, revisa que la información de cada formulario este completa.",
          title: "¡Lo sentimos!",
        });
      } finally {
        setOpenModal(false);
        setLoading(false);
      }
    } else {
      try {
        if (
          solicitanteLider.formData?.documentType &&
          solicitanteLider.formData?.documentNumber
        ) {
          let response = await getValidationRadication(
            solicitanteLider.formData.documentType,
            solicitanteLider.formData.documentNumber,
            1
          );
          let validationResult: TErrorsValidation =
            response.data.result.errores;
          if (!validationResult.length) {
            //Cambiar al tener cédula real
            //quitar negación al terminar pruebas
            const error = errorsList.find(
              (element) => element.name === validationResult[0]
            );
            if (error) {
              setShowError(true);
              setDataError(error);
            }
          } else {
            setShowModalValue(true);
            setIsRadication(true);
            setIsViable(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDataCredit = async () => {
    setLoading(true);
    const res = await getData(
      solicitanteLider.formData.creditLine || "1",
      solicitanteLider.formData.documentType || "1",
      solicitanteLider.formData.documentNumber || 1
    );
    // const hoy = new Date();
    const vigencia = new Date(res.data.result.data.vigenciaOperacion);
    // const diferencia = vigencia.getTime() - hoy.getTime();
    // const mesesTranscurridas = diferencia / 1000 / 60 / 60 / 24 / 30;
    const vigenciaOperacionString = `Este pre-aprobado estará vigente hasta ${vigencia.getDate()} de ${vigencia.toLocaleString(
      "default",
      { month: "long" }
    )} de ${vigencia.getFullYear()}`;

    setDataSettle({
      ...res.data.result.data,
      vigenciaOperacionString: vigenciaOperacionString, // vigenciaOperacionString
    });
    setLoading(false);
  };

  useEffect(() => {
    getDataCredit();
  }, []);

  const ContentNotViable = () => {
    return (
      <Modal open={showError} handleClose={() => setShowError(false)}>
        <FlexDivUI alignContent="center" flexDirection="column">
          <TitleUI align="center">{dataError?.title}</TitleUI>
          <TitleUI color="dark" as="h3" align="center">
            {dataError?.message}
          </TitleUI>
          <Button
            text={"Cerrar"}
            type="button"
            classname=""
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={() => {
              setShowError(false);
              setCurrentTab("gestionarTusCreditos");
            }}
          />
        </FlexDivUI>
      </Modal>
    );
  };

  return (
    <WrapperContent>
      {showError && <ContentNotViable />}
      {showModalStudy && (
        <ModalStudy
          showModal={showModalStudy}
          setShowModal={setShowModalStudy}
          dataModal={dataModalStudy}
        />
      )}
      {showModalValue && (
        <ModalCambiarValor
          showModalValue={showModalValue}
          setShowModalValue={setShowModalValue}
          setIsChangeValue={setIsChangeValue}
        />
      )}
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <section className="o-procesarCredito__modal">
          <h3 className="o-procesarCredito__modal__title">
            ¿Por qué lo vas a desistir?
          </h3>
          <InputForm
            type="text-area"
            title="Motivo"
            handleChange={(value) => {
              handleChange(value);
            }}
            value={reason}
            nameId="reason"
            placeholder="Agregar texto"
            className="o-procesarCredito__modal__reason"
          />
          <Button
            type="button"
            classname=""
            text={"Aceptar"}
            isDisabled={disabledButton}
            gtmMark="modal"
            onClickAction={handleAccept}
          />
          {/* <p className="o-procesarCredito__modal__footer">
            ¡GRACIAS POR PENSAR EN NOSOTROS!
          </p> */}
        </section>
      </Modal>

      {loading && <Loading />}
      {!isChangeValue ? (
        <>
          <div className="o-gestionarCreditos__Radicar">
            <div className="o-gestionarCreditos__Radicar">
              {/* <div className="o-gestionarCreditos__Radicar__Image"></div> */}
              <div className="o-gestionarCreditos__Radicar__Title">
                <h1>Tu solicitud de crédito ha sido pre-aprobada</h1>
              </div>
              <div className="o-gestionarCreditos__Radicar__SubTitle">
                <h2>Información del crédito</h2>
              </div>

              <div className="o-gestionarCreditos__Radicar__Container">
                <div className="o-gestionarCreditos__Radicar__Container__Text">
                  <h3>Línea de crédito:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__Container__Value">
                  <h3>{dataSettle.lineaCredito}</h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__ContainerPink">
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Text">
                  <h3>Monto del crédito hasta:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Value">
                  <h3>{currencyFormat(dataSettle.valorMonto)}</h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__Container">
                <div className="o-gestionarCreditos__Radicar__Container__Text">
                  <h3>Plazo:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__Container__Value">
                  <h3>{dataSettle.plazo} meses</h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__ContainerPink">
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Text">
                  <h3>Tasa de interés:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Value">
                  <h3>
                    La tasa de interés será la vigente al momento del desembolso
                    para cada tipo de financiación
                  </h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__Container">
                <div className="o-gestionarCreditos__Radicar__Container__Text">
                  <h3>Tipo de amortización:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__Container__Value">
                  <h3>Cuota fija en pesos</h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__ContainerPink">
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Text">
                  <h3>Vigencia:</h3>
                </div>
                <div className="o-gestionarCreditos__Radicar__ContainerPink__Value">
                  <h3>{dataSettle.vigenciaOperacionString}</h3>
                </div>
              </div>

              <div className="o-gestionarCreditos__Radicar__Button">
                <Button
                  type="button"
                  classname=""
                  text={"Desistir Crédito"}
                  isDisabled={false}
                  gtmMark="primary"
                  onClickAction={handleDesist}
                />
                <Button
                  type="button"
                  classname=""
                  text={isRadication ? "Enviar a Estudio" : "Solicitar Crédito"}
                  isDisabled={false}
                  gtmMark="secondary"
                  onClickAction={handleRequest}
                />
              </div>
            </div>
          </div>

          {/* <header className="o-gestionarCreditos-container__header">
            <TitleUI>Aceptar / Desistir Crédito</TitleUI>
            <h3>Información del Crédito...</h3>
          </header>
          <Divider
            dividerWidth="881px"
            dividerColor="#E3E3E3"
            dividerMargin="27.5px 0 56.5px 0"
          />
          <Button
            type="button"
            classname=""
            text={"Solicitar Crédito"}
            isDisabled={false}
            gtmMark="primary"
            onClickAction={handleRequest}
          />
          <Button
            type="button"
            classname=""
            text={"Desistir Crédito"}
            isDisabled={false}
            gtmMark="primary"
            onClickAction={handleDesist}
          /> */}
        </>
      ) : (
        <CambiarValor />
      )}
    </WrapperContent>
  );
};

export default ProcesarCredito;
