import React, { useContext } from "react";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Button from "../../atoms/button/button";
import { AppContext } from "../../../contexts/appContext";
import currencyFormat from "../../../utils/currencyFormat";

import iconIngreso from "../../../assets/icons/icono_total_ingresos.svg";

import "./modalCambiarValor.scss";

type TProps = {
  showModalValue: boolean;
  setShowModalValue: (status: boolean) => void;
  setIsChangeValue: (status: boolean) => void;
};

const ModalCambiarValor = ({
  showModalValue,
  setShowModalValue,
  setIsChangeValue,
}: TProps) => {
  const { stateSolicitud, setCurrentTab, setValueSolicitud } =
    useContext(AppContext);
  const {
    solicitudData: { valorPreaprobado },
  } = stateSolicitud.solicitanteLider;

  return (
    <Modal open={showModalValue} handleClose={() => setShowModalValue(false)}>
      <FlexDivUI alignContent="center" flexDirection="column">
        <TitleUI align="center">
          ¿Deseas disminuir el valor de tu crédito?
        </TitleUI>
        <FlexDivUI justifyContent="center" flexDirection="row" alignContent="center" gap={1}>
          <img src={iconIngreso} alt="IconoIngresos" />
          <TitleUI color="dark" align="center" className="m-modal-title">
            {currencyFormat(valorPreaprobado)}
          </TitleUI>
        </FlexDivUI>

        <FlexDivUI flexDirection="row" justifyContent="center" gap={1}>
          <Button
            text={"SI"}
            type="button"
            classname="m-modal-button"
            gtmMark="primary"
            isDisabled={false}
            onClickAction={() => {
              setShowModalValue(false);
              setIsChangeValue(true);
            }}
          />
          <Button
            text={"NO"}
            type="button"
            classname="m-modal-button"
            gtmMark="secondary"
            isDisabled={false}
            onClickAction={() => {
              setShowModalValue(false);
              setValueSolicitud(valorPreaprobado);
              setCurrentTab("simulador");
            }}
          />
        </FlexDivUI>
      </FlexDivUI>
    </Modal>
  );
};

export default ModalCambiarValor;
