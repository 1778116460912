import React, { useState, useContext, useEffect } from "react";
import Loading from "../../atoms/loading/loading";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import TitleUI from "../../atoms/title-ui/TitleUI";

import "./documentos.scss";
import File from "../../molecules/file/file";
import { saveFile } from "../../../services/fileService";
import { AppContext } from "../../../contexts/appContext";
import { fileToBase64 } from "../../../utils/files";

type TFile = {
  name: string;
  file: File;
};

const inputs = [
  {
    name: "cedula",
    text: "Fotocopia de la cédula de ciudadanía, de extranjería o contraseña legible",
    isRequired: true,
    label: "Adjuntar fotocopia de la cédula",
    tooltip: "",
    isLeader: true,
    isGuarantor: true,
    isEmployee: true,
    isPensionary: true,
    isIndependient: true,
  },
  {
    name: "comprobanteNomina",
    text: "Comprobante de pago de nómina, pensión o compensación del último mes",
    isRequired: true,
    label: "Adjuntar comprobante",
    tooltip: "",
    isLeader: true,
    isGuarantor: true,
    isEmployee: true,
    isPensionary: true,
    isIndependient: false,
  },
  {
    name: "certificadoOtrosIngresos",
    text: "Certificado de otros ingresos no laborales",
    isRequired: false,
    label: "Adjuntar certificado de ingresos no laborales",
    tooltip:
      "arrendamientos (certificación de propiedad y contrato de arrendamiento contrato agencia) propietarios de taxi (matricula del vehículo y carta afiliación del vehículo), prestación de servicio (carta donde presta servicio y extractos bancarios)",
    isLeader: true,
    isGuarantor: true,
    isEmployee: true,
    isPensionary: true,
    isIndependient: true,
  },
  {
    name: "certificadoExistencia",
    text: "Certificado de existencia y representación legal",
    isRequired: true,
    label: "Adjuntar certificado",
    tooltip:
      "De acuerdo a tu actividad económica varia tipo de documento. Algunos ejemplos: RUT, certificado de matrícula mercantil, cámara de comercio,  certificado de existencia y representación legal",
    isLeader: true,
    isGuarantor: true,
    isEmployee: false,
    isPensionary: false,
    isIndependient: true,
  },
  {
    name: "extractoCuenta",
    text: "Extractos de cuenta de ahorros o cuenta corriente",
    isRequired: true,
    label: "Adjuntar extracto",
    tooltip: "El documento debe reportar los 3 ultimos meses",
    isLeader: true,
    isGuarantor: true,
    isEmployee: false,
    isPensionary: false,
    isIndependient: true,
  },
];

const Documentos = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [files, setFiles] = useState<TFile[]>([]);
  const { stateSolicitud } = useContext(AppContext);
  const [isLeader, setIsLeader] = useState(true);
  const [ocupation, setOcupation] = useState("EMPLEADO");

  useEffect(() => {
    let indexSolicitante =
      stateSolicitud.solicitanteLider.stateSolicitante || 0;
    setIsLeader(indexSolicitante === 0 ? true : false);
    if (indexSolicitante === 0) {
      setOcupation(
        stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.actividadEconomica || ""
      );
    } else {
      setOcupation(
        stateSolicitud.avalistas[indexSolicitante - 1].solicitante
          .informacionActividadEconomica?.actividadEconomica || ""
      );
    }
  }, [stateSolicitud.solicitanteLider.stateSolicitante]);

  const checkValidity = async (next: () => void) => {
    console.log(files);
    setShowError(false);
    if (files?.length) {
      let isError = false;
      inputs.forEach((element) => {
        if (
          (isLeader && element.isLeader) ||
          (!isLeader && element.isGuarantor)
        ) {
          if (
            (ocupation === "EMPLEADO" && element.isEmployee) ||
            (ocupation === "INDEPENDIENTE" && element.isIndependient) ||
            (ocupation === "PENSIONADO" && element.isPensionary)
          ) {
            if (element.isRequired) {
              let exist = files.some((file) => file.name === element.name);
              if (!exist) {
                setShowError(true);
                isError = true;
              }
            }
          }
        }
      });
      if (!isError) {
        console.log("paso aqui");
        await handleSend();
      }
    } else {
      setShowError(true);
    }
  };

  const handleSend = async () => {
    setLoading(true);
    let indexSolicitante =
      stateSolicitud.solicitanteLider.stateSolicitante || 0;
    let idSolicitante = 0;
      if (indexSolicitante === 0) {
        idSolicitante = stateSolicitud.solicitanteLider.solicitante.id || 0
      }else {
        idSolicitante = stateSolicitud.avalistas[indexSolicitante - 1].solicitante.id || 0
      }
    //Send Files
    if (files?.length) {
      files.forEach(async (element) => {
        let fileConverted = await fileToBase64(element.file);
        let extension = element.file.type
        extension = extension.replace(/(.*)\//g, '')
        try {
          let response = await saveFile({
            idSolicitud: stateSolicitud.solicitanteLider.solicitudData.id,
            idSolicitante: idSolicitante,
            fields: [
              {
                name: element.name,
                value: element.file.size.toString(),
              },
            ],
            contentBase64: fileConverted,
            fileName: `${element.name}.${extension}`,
          });
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      });
    }
  };

  const handleAddFile = (name: string, file: File) => {
    setShowError(false);
    let dumieFiles = files || [];
    let newFile = {
      name,
      file,
    };
    let exist = dumieFiles.findIndex((element) => element.name === name);
    if (exist >= 0) {
      dumieFiles[exist] = newFile;
    } else {
      dumieFiles.push(newFile);
    }
    setFiles(dumieFiles);
  };

  const handleDeleteFile = (name: string) => {
    setShowError(false);
    let dumieFiles = files || [];
    let index = dumieFiles.findIndex((element) => element.name === name);
    dumieFiles.splice(index, 1);
    setFiles(dumieFiles);
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      <Wizard>
        <WizardTab onNext={(next: () => void) => checkValidity(next)}>
          <p className="o-text">
            Recuerda que los campos marcados con asterisco{" "}
            <span className="accent">(*)</span> son obligatorios
          </p>
          <div className="o-form">
            <div className="o-form__title">
              <TitleUI as="h2" color="dark" align="left">
                Documentos:
              </TitleUI>
              <p className="o-form__title__text">
                Adjunta los documentos en formato PDF, JPEG, PNG, peso máximo 2
                MB
              </p>
            </div>
            <div className="o-form__content o-form__flex">
              {inputs.map((input, index) => {
                if (
                  (isLeader && input.isLeader) ||
                  (!isLeader && input.isGuarantor)
                ) {
                  if (
                    (ocupation === "EMPLEADO" && input.isEmployee) ||
                    (ocupation === "INDEPENDIENTE" && input.isIndependient) ||
                    (ocupation === "PENSIONADO" && input.isPensionary)
                  ) {
                    return (
                      <File
                        key={index}
                        name={input.name}
                        text={input.text}
                        isRequired={input.isRequired}
                        label={input.label}
                        deleteFile={(name) => {
                          handleDeleteFile(name);
                        }}
                        handleAddFile={handleAddFile}
                        showError={showError && input.isRequired && !files.some(e => e.name === input.name)}
                        tooltip={input.tooltip}
                      />
                    );
                  }
                }
              })}
            </div>
          </div>
        </WizardTab>
      </Wizard>
    </WrapperContent>
  );
};

export default Documentos;
