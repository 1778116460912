import React, { useEffect, useReducer, useState } from "react";

import closeIcon from "../../../assets/icons/close.svg";
import openIcon from "../../../assets/icons/hamburger.svg";
import asegurabilidadIcon from "../../../assets/icons/icon_asegurabilidad_gris.svg";
import datosContactoIcon from "../../../assets/icons/icon_datos_de_contacto_gris.svg";
import datosPersonalesIcon from "../../../assets/icons/icon_datos_personales_gris.svg";
import financieraIcon from "../../../assets/icons/icon_info_financiera_gris.svg";
import simuladorIcon from "../../../assets/icons/icon_simulador_gris.svg";
import solicitanteIcon from "../../../assets/icons/icon_solicitante adicional.svg";
import terminosIcon from "../../../assets/icons/icono_terminos.svg";
import rechazarIcon from "../../../assets/icons/Layout/icono_solicitud rechazada.svg";
import documentosIcon from "../../../assets/icons/iconosMenu/icon_documentos.svg";
import avatar from "../../../assets/icons/iconosMenu/avatar.svg";
import gestionarIcon from "../../../assets/icons/list-check.svg";
import listarIcon from "../../../assets/icons/list-ul.svg";
import viviendaIcon from "../../../assets/icons/iconosMenu/icon_información de vivienda.svg";
import referenciaIcon from "../../../assets/icons/iconosMenu/icon_referencias.svg";
import actividadEconomicaIcon from "../../../assets/icons/iconosMenu/icon_actividad_economica.svg";

import { AppContext } from "../../../contexts/appContext";
import reducerSolicitud, {
  initialSolicitud,
  TAvalistas,
} from "../../../reducers/reducerSolicitud";

import Divider from "../../atoms/divider/divider";
import MenuCard from "../../atoms/menu-card/menuCard";
import Stepper from "../../atoms/stepper/stepper";
import MenuContent from "../../molecules/menu-content/menuContent";
import Asegurabilidad from "../asegurabilidad/asegurabilidad";
import ValidacionesAfiliacion from "../validacionesAfiliacion/validacionesAfiliacion";
import Comentarios from "../comentarios/comentarios";
import ComentariosEstudio from "../comentarios-estudio/comentariosEstudio";
import DatosPersonales from "../datos-personales/datosPersonales";
import GestionarCreditos from "../gestionar-creditos/gestionarCreditos";
import ListaCreditos from "../lista-creditos/listaCreditos";
import Simulador from "../simulador/simulador";
import SolicitantesAdicionales from "../solicitantes-adicionales/solicitantesAdicionales";
import DatosContactos from "../datos-contactos/datosContactos";
import InformacionVivienda from "../informacion-vivienda/informacionVivienda";
import InformacionFinanciera from "../informacion-financiera/informacionFinanciera";
import ActividadEconomica from "../actividad-economica/actividadEconomica";

import SolicitudEspera from "../solicitud-espera/solicitudEspera";

import {
  getMessages,
  getMunicipios,
  getTipoIdentificacion,
} from "../../../services/parametrizacionServices";
import ParametrizacionContext from "../../../contexts/parametrizacionContext";
import TerminosCondiciones from "../teminos-condiciones/terminosCondiciones";
import ProcesarCredito from "../procesar-credito/procesarCredito";
import Referencia from "../referencia/referencia";
import { capitalize } from "../../../utils/capitalizeFirstLetter";
import Documentos from "../documentos/documentos";

import "./layout.scss";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Button from "../../atoms/button/button";

interface LayoutProps {
  children?: any;
}

type TChildren = {
  title: string;
  icon: string;
  value: string;
  showRadication: boolean;
  showPre: boolean;
};

const Layout = ({ children }: LayoutProps) => {
  const [currentTab, setCurrentTab] = useState<string>("gestionarTusCreditos");

  const [menuOpen, setMenuOpen] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [visitedTabs, setVisitedTabs] = useState<Array<string>>([
    "listaDeCreditos",
    "gestionarTusCreditos",
  ]); //Hábilita las dos primeras secciones sin condición
  const [tipoIdentificacion, setTipoIdentificacion] = useState([]);
  const [isViable, setIsViable] = useState(false);
  const [valueSolicitud, setValueSolicitud] = useState(0);

  const componentsInitialParts = {
    listaDeCreditos: () => <ListaCreditos />,
    gestionarTusCreditos: () => <GestionarCreditos />,
    solicitantesAdicionales: () => <SolicitantesAdicionales />,
    simulador: () => <Simulador />,
    datosPersonales: () => <DatosPersonales />,
    datosContacto: () => <DatosContactos />,
    actividadEconomica: () => <ActividadEconomica />,
    informacionFinanciera: () => <InformacionFinanciera />,
    asegurabilidad: () => <Asegurabilidad />,
    terminosCondiciones: () => <TerminosCondiciones />,
    validacionesAfiliacion: () => <ValidacionesAfiliacion />,
    comentariosEstudio: () => <ComentariosEstudio />,
    comentarios: () => <Comentarios />,
    aceptarContinuar: () => <ProcesarCredito />,
    referencia: () => <Referencia />,
    informacionVivienda: () => <InformacionVivienda />,
    documentos: () => <Documentos />,
  };
  const [componentsParts, setcomponentsParts] = useState<any>({
    ...componentsInitialParts,
  });

  const [isRadication, setIsRadication] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [stateUser, setStateUser] = useState(-1);

  const [messages, setMessages] = useState([]);
  const [steps, setSteps] = useState<TChildren[]>();
  const [formData, setFormData] = useState({
    titularTipoDocumento: "",
    titutlarNumeroDocumento: "",
    titularLineaCredito: "",
    titularSede: "",
    numSolicitantes: 0,
    unoTipoDocumento: "",
    unoNumeroDocumento: "",
    unoPrimerNombre: "",
    unoSegundoNombre: "",
    unoPrimerApellido: "",
    unoSegundoApellido: "",
    unoFechaNacimiento: "",
    unoNumeroCelular: "",
    unoCorreoElectronico: "",
    dosTipoDocumento: "",
    dosNumeroDocumento: "",
    dosPrimerNombre: "",
    dosSegundoNombre: "",
    dosPrimerApellido: "",
    dosSegundoApellido: "",
    dosFechaNacimiento: "",
    dosNumeroCelular: "",
    dosCorreoElectronico: "",
  });
  const [solicitante, setSolicitante] = useState({
    id: 0,
    tipoDocumentoId: 0,
    numeroDocumento: 0,
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    lugarNacimiento: "",
    fechaExpedicion: "",
    lugarExpedicion: "",
    estadoCivilId: 0,
    nivelEstudioId: 0,
    personasACargo: 0,
    celular: "",
    otroNumero: "",
    email: "",
    informacionFinanciera: {
      ingreso: 0,
      otroIngresoLaboral: 0,
      otroIngresoNoLaboral: 0,
      cuotaDeducidaNomina: 0,
      cuotaNoDeducidaNomina: 0,
      valorArriendo: 0,
      otroEgreso: 0,
      solicitanteId: 0,
    },
    informacionActividadEconomica: {
      actividadEconomica: "",
      empresa: "",
      tipoContrato: "",
      fechaTerminacionContrato: "",
      antiguedadLaboralMeses: 0,
      solicitanteId: 0,
    },
    informacionDomicilio: {
      estrato: 0,
      lugarResidencia: "",
      tipoVivienda: "",
      tiempoResidencia: 0,
      tipoVia: "",
      numero1: "",
      letra1: "",
      cuadrante1: "",
      numero2: "",
      letra2: "",
      cuadrante2: "",
      numero3: "",
      informacionAdicional: "",
      solicitanteId: 0,
      lineaCreditoId: 0,
    },
    enfermedadSolicitante: [],
  });

  // reducer datos formulario
  const [stateSolicitud, dispatchSolicitud] = useReducer(
    reducerSolicitud,
    initialSolicitud
  );

  const handleSetCurrentTab = (tab: any) => {
    if (!visitedTabs.includes(tab)) {
      setVisitedTabs((prev) => [...prev, tab]);
    }
    setCurrentTab(tab);
  };

  useEffect(() => {
    Promise.all([getMunicipios(), getTipoIdentificacion(1), getMessages()])
      .then((response) => {
        setMunicipios(response[0].data.result);
        setTipoIdentificacion(response[1].data.result);
        setMessages(response[2].data.result);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      if (stateSolicitud.solicitanteLider.stateSolicitante != -1) {
        if (stateSolicitud.solicitanteLider.solicitante.action != "RADICAR") {
          if (
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.tyCFirmado
          ) {
            setcomponentsParts({
              ...componentsInitialParts,
              gestionarTusCreditos: () => <SolicitudEspera />,
              solicitantesAdicionales: () => <SolicitudEspera />,
              simulador: () => <SolicitudEspera />,
              datosPersonales: () => <SolicitudEspera />,
              datosContacto: () => <SolicitudEspera />,
              actividadEconomica: () => <SolicitudEspera />,
              informacionFinanciera: () => <SolicitudEspera />,
              asegurabilidad: () => <SolicitudEspera />,
              terminosCondiciones: () => <SolicitudEspera />,
            });
          } else {
            setcomponentsParts({
              ...componentsInitialParts,
            });
          }
        }
      }
    } else {
      if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
        if (stateSolicitud.solicitanteLider.solicitante.action != "RADICAR") {
          if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
            setcomponentsParts({
              ...componentsInitialParts,
              solicitantesAdicionales: () => <SolicitudEspera />,
              simulador: () => <SolicitudEspera />,
              datosPersonales: () => <SolicitudEspera />,
              datosContacto: () => <SolicitudEspera />,
              actividadEconomica: () => <SolicitudEspera />,
              informacionFinanciera: () => <SolicitudEspera />,
              asegurabilidad: () => <SolicitudEspera />,
              terminosCondiciones: () => <SolicitudEspera />,
            });
          } else {
            setcomponentsParts({
              ...componentsInitialParts,
            });
          }
          // setFlagSuccess(
          //   stateSolicitud.solicitanteLider.solicitante.tyCFirmado
          // );
        }
      }
    }
  }, [stateSolicitud]);

  const menuItems = [
    {
      title: "Lista de créditos",
      icon: listarIcon,
      value: "listaDeCreditos",
    },
    {
      title: "Gestionar tus créditos",
      icon: gestionarIcon,
      value: "gestionarTusCreditos",
      children: [
        {
          title: "Información del Crédito",
          icon: gestionarIcon,
          value: "aceptarContinuar",
          showRadication: true,
          showPre: false,
        },
        {
          title: "Solicitantes adicionales",
          icon: solicitanteIcon,
          value: "solicitantesAdicionales",
          showRadication: false,
          showPre: true,
          showAditional: false,
        },
        {
          title: "Simulador",
          icon: simuladorIcon,
          value: "simulador",
          showRadication: true,
          showPre: true,
          showAditional: false,
        },
        {
          title: "Datos personales",
          icon: datosPersonalesIcon,
          value: "datosPersonales",
          showRadication: false,
          showPre: true,
        },
        {
          title: "Datos de contacto",
          icon: datosContactoIcon,
          value: "datosContacto",
          showRadication: true,
          showPre: true,
        },
        {
          title: "Actividad económica",
          icon: actividadEconomicaIcon,
          value: "actividadEconomica",
          showRadication: true,
          showPre: true,
        },
        {
          title: "Información financiera",
          icon: financieraIcon,
          value: "informacionFinanciera",
          showRadication: true,
          showPre: true,
        },
        {
          title: "Información de vivienda",
          icon: viviendaIcon,
          value: "informacionVivienda",
          showRadication: true,
          showPre: false,
          showAditional: false,
        },
        {
          title: "Referencia",
          icon: referenciaIcon,
          value: "referencia",
          showRadication: true,
          showPre: false,
        },
        {
          title: "Asegurabilidad",
          icon: asegurabilidadIcon,
          value: "asegurabilidad",
          showRadication: false,
          showPre: true,
        },
        {
          title: "Términos y condiciones",
          icon: terminosIcon,
          value: "terminosCondiciones",
          showRadication: false,
          showPre: true,
        },
        {
          title: "Documentos",
          icon: documentosIcon,
          value: "documentos",
          showRadication: true,
          showPre: false,
        },
      ],
    },
  ];

  useEffect(() => {
    let myArray: TChildren[] = [];
    menuItems[1].children?.forEach((child) => {
      if (isRadication) {
        if (child.showRadication) {
          myArray.push(child);
        }
      } else {
        if (child.showPre) {
          myArray.push(child);
        }
      }
    });
    setSteps(myArray);
  }, [isRadication]);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const setData = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setFormIdData = (id: string, value: any) => {
    setFormData({ ...formData, [id]: value });
  };

  const HandleApplicants = (index: number) => {
    setStateUser(index + 1);
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      if (stateSolicitud.solicitanteLider.stateSolicitante != -1) {
        if (stateSolicitud.solicitanteLider.solicitante.action != "RADICAR") {
          if (
            stateSolicitud.avalistas[
              stateSolicitud.solicitanteLider.stateSolicitante - 1
            ].solicitante.tyCFirmado
          ) {
            dispatchSolicitud({
              type: "UPDATE_STATE_SOLICITANTE_LIDER",
              payload: {
                stateSolicitante: index + 1,
              },
            });
          } else {
            setFlagModal(true);
          }
        } else {
          dispatchSolicitud({
            type: "UPDATE_STATE_SOLICITANTE_LIDER",
            payload: {
              stateSolicitante: index + 1,
            },
          });
        }
      }
    } else {
      if (stateSolicitud.solicitanteLider.solicitante.action != "RADICAR") {
          if (stateSolicitud.solicitanteLider.solicitante.tyCFirmado) {
            dispatchSolicitud({
              type: "UPDATE_STATE_SOLICITANTE_LIDER",
              payload: {
                stateSolicitante: index + 1,
              },
            });
          } else {
            setFlagModal(true);
          }
      }else{
        dispatchSolicitud({
          type: "UPDATE_STATE_SOLICITANTE_LIDER",
          payload: {
            stateSolicitante: index + 1,
          },
        });
      }
    }
    // dispatchSolicitud({
    //   type: "UPDATE_STATE_SOLICITANTE_LIDER",
    //   payload: {
    //     stateSolicitante: index + 1,
    //   },
    // });
  };

  const HancleChangeSolicitante = () => {
    dispatchSolicitud({
      type: "UPDATE_STATE_SOLICITANTE_LIDER",
      payload: {
        stateSolicitante: stateUser,
      },
    });
    setStateUser(-1);
    setFlagModal(false);
  };

  return (
    <AppContext.Provider
      value={{
        currentTab,
        setCurrentTab: handleSetCurrentTab,
        visitedTabs,
        formData,
        solicitante,
        setData,
        setFormIdData,
        stateSolicitud,
        dispatchSolicitud,
        isViable,
        setIsViable,
        valueSolicitud,
        setValueSolicitud,
        isRadication,
        setIsRadication,
      }}
    >
      <ParametrizacionContext.Provider
        value={{
          municipios,
          tipoIdentificacion,
          messages,
        }}
      >
        <Modal open={flagModal} handleClose={() => setFlagModal(false)}>
          <FlexDivUI alignContent="center" flexDirection="column">
            <TitleUI align="center">
              <img src={rechazarIcon} />
            </TitleUI>
            <TitleUI align="center">¿Estás seguro que deseas salir?</TitleUI>
            <div style={{ padding: "0px 30px" }}>
              <TitleUI color="dark" as="h3" align="center">
                Al salir del formulario los datos que ingresaste no quedarán
                guardados, por lo tanto deberas comenazar nuevamente
              </TitleUI>
            </div>
            <div className="o-Layout__Modal">
              <div className="o-Layout__Modal__Button">
                <Button
                  text={"Continuar"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => HancleChangeSolicitante()}
                />
              </div>
              <div className="o-Layout__Modal__Button">
                <Button
                  text={"Salir"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => setFlagModal(false)}
                />
              </div>
            </div>
          </FlexDivUI>
        </Modal>

        <div
          className={`o-layout-container ${
            menuOpen ? "o-layout-container--open" : ""
          }`}
        >
          <header className="o-layout-container__header">
            <img
              src={menuOpen ? closeIcon : openIcon}
              alt={menuOpen ? "Cerrar menú" : "Abrir menú"}
              onClick={handleToggle}
            />
          </header>
          <aside
            className={`o-layout-container__sidebar ${
              menuOpen ? "o-layout-container__sidebar--open" : ""
            }`}
          >
            {stateSolicitud.solicitanteLider.stateSolicitante !== -1 && (
              <>
                <div className="o-layout-container__sidebar--profile">
                  <div className="o-layout-container__sidebar--profile--img">
                    <img
                      src={avatar}
                      className="o-layout-container__siderbar-profile-icon-img"
                      alt="PerfilUsuario"
                      width={50}
                    />
                  </div>
                  {stateSolicitud.solicitanteLider.stateSolicitante === 0 && (
                    <div className="o-layout-container__sidebar--profile--info">
                      <p className="o-layout-container__sidebar--profile--info--nombre">
                        {`${capitalize(
                          stateSolicitud.solicitanteLider.solicitante
                            .primerNombre
                        )} ${capitalize(
                          stateSolicitud.solicitanteLider.solicitante
                            .primerApellido
                        )}`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--cedula">
                        C.C{" "}
                        {`${stateSolicitud.solicitanteLider.solicitante.numeroDocumento}`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--titular">
                        Titular
                      </p>
                      <hr />
                    </div>
                  )}
                  {(stateSolicitud.solicitanteLider.stateSolicitante === 1 ||
                    stateSolicitud.solicitanteLider.stateSolicitante === 2) && (
                    <div className="o-layout-container__sidebar--profile--info">
                      <p className="o-layout-container__sidebar--profile--info--nombre">
                        {`${capitalize(
                          stateSolicitud.avalistas[
                            stateSolicitud.solicitanteLider.stateSolicitante - 1
                          ].formData.primerNombre
                        )} ${capitalize(
                          stateSolicitud.avalistas[
                            stateSolicitud.solicitanteLider.stateSolicitante - 1
                          ].formData.primerApellido
                        )}`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--cedula">
                        C.C{" "}
                        {`${
                          stateSolicitud.avalistas[
                            stateSolicitud.solicitanteLider.stateSolicitante - 1
                          ].formData.numeroDocumento
                        }`}
                      </p>
                      <p className="o-layout-container__sidebar--profile--info--adicional">
                        Solicitante adicional{" "}
                        {stateSolicitud.solicitanteLider.stateSolicitante}
                      </p>
                      <p
                        className="o-layout-container__sidebar--profile--info--adicionalTitular"
                        onClick={() => HandleApplicants(-1)}
                      >
                        Titular:{" "}
                        {`${stateSolicitud.solicitanteLider.solicitante.primerNombre} ${stateSolicitud.solicitanteLider.solicitante.primerApellido}`}
                      </p>
                      <hr />
                    </div>
                  )}
                </div>

                {stateSolicitud.avalistas.length > 0 && (
                  <div className="o-layout-container__sidebar--adicional">
                    <div className="o-layout-container__sidebar--adicional__Container">
                      <div className="o-layout-container__sidebar--adicional__Container-left">
                        <img
                          src={solicitanteIcon}
                          alt="PerfilUsuario"
                          width={24}
                        />
                      </div>
                      <div className="o-layout-container__sidebar--adicional__Container-title">
                        <p>Solicitantes adicionales:</p>
                      </div>
                    </div>
                    {stateSolicitud.avalistas.map(
                      (item: TAvalistas, index: number) => {
                        if (
                          index !==
                          stateSolicitud.solicitanteLider.stateSolicitante - 1
                        ) {
                          return (
                            <div
                              key={index}
                              className="o-layout-container__sidebar--adicional__Container"
                            >
                              <div className="o-layout-container__sidebar--adicional__Container-left"></div>
                              <div
                                className="o-layout-container__sidebar--adicional__Container-right"
                                onClick={() => HandleApplicants(index)}
                              >
                                <p>{`${
                                  capitalize(item.formData.primerNombre) || ""
                                } ${
                                  capitalize(item.formData.primerApellido) || ""
                                }`}</p>
                              </div>
                            </div>
                          );
                        }
                      }
                    )}
                  </div>
                )}
              </>
            )}

            {menuItems.map((item, idx) => (
              <MenuCard
                key={item.value}
                title={item.title}
                icon={item.icon}
                value={currentTab}
                setValue={handleSetCurrentTab}
                index={item.value}
                shouldDisplayChildren={isViable}
                item={item}
                parentTabs={true}
              >
                {item.children &&
                  item.children.map((child) => {
                    if (
                      (!isRadication && child.showPre) ||
                      (isRadication && child.showRadication)
                    ) {
                      return (
                        <React.Fragment key={child.value}>
                          {(stateSolicitud.solicitanteLider.stateSolicitante ===
                            0 ||
                            ((stateSolicitud.solicitanteLider
                              .stateSolicitante === 1 ||
                              stateSolicitud.solicitanteLider
                                .stateSolicitante === 2) &&
                              (child.showAditional ||
                                child.showAditional === undefined))) && (
                            <>
                              <MenuCard
                                title={child.title}
                                icon={child.icon}
                                value={currentTab}
                                setValue={handleSetCurrentTab}
                                index={child.value}
                                isChild={true}
                                item={child}
                              />
                              <Divider
                                dividerWidth="100%"
                                dividerColor="#E5E5E5"
                                dividerMargin=""
                              />
                            </>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
              </MenuCard>
            ))}
          </aside>
          <main className="o-layout-container__main">
            {/*TODO: debe renderizarse solo en los subelementos después de gestionarCreditos*/}
            <Stepper steps={steps as any} currentStep={currentTab} />
            <MenuContent>{componentsParts[currentTab]()} </MenuContent>
          </main>
        </div>
      </ParametrizacionContext.Provider>
    </AppContext.Provider>
  );
};

export default Layout;
