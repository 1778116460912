import { IdaptiveAuth } from "@comfama/comfama-idaptive-auth";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/organisms/layout/layout";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LayoutInfoTitular from "./components/organisms/layout-info-titular/layoutInfoTitular";
import axios from "./services/axiosConfig";
import { getLineaCredito } from "./services/parametrizacionServices";
import { Axios } from "axios";

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let autenticacion = new IdaptiveAuth(
    process.env.REACT_APP_REDIRECT_URI || "",
    process.env.REACT_APP_IDATPTIVEAUTHENDPOINT || "",
    process.env.REACT_APP_IDAPTIVETOKENENDPOINT || "",
    process.env.REACT_APP_IDAPTIVECLIENTID || "",
    process.env.REACT_APP_IDAPTIVECLIENTSECRET || ""
  );

  const idaptiveAuth = async () => {

    const jwt = sessionStorage.getItem("user-jwt");

    //Chequea si el jwt está vencido y lo borra
    if (jwt) {
      await getLineaCredito().catch(error =>  {
        if (error.response && error.response.status === 401) {
          sessionStorage.removeItem('user-jwt');
        }
      })
    }

    let token = prompt('Escribe el token') || '';
    sessionStorage.setItem('user-jwt', token)
    setIsAuthorized(true);

    //  autenticacion
    //   .login()
    //   .then((token: any) => {
    //     // success auth
    //     console.log(token);
    //     setIsAuthorized(true);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     // 401
    //     console.log(err);
    //     setIsLoading(false);
    //   });
  }

  useEffect(() => {
    idaptiveAuth();
  }, []);

  // if (isLoading) return <p>Cargando...</p>;

  // if (!isAuthorized)
  //   return <p>No tienes permisos para acceder a esta página.</p>;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Routes>
        <Route path="/info-titular" element={<LayoutInfoTitular />} />
        <Route path="/" element={
          isAuthorized ? <Layout /> : <></>
        } />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
