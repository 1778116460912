import axios from "./axiosConfig";


export const getTipoVivienda = () => {
    var config = {
        method: "get",
        url: `${process.env.REACT_APP_PARAMETRIZACION_API}/TipoVivienda/1`,
        headers: {}
      };
      return axios(config);
}

export const getCuadrante = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Cuadrante/1`,
      headers: {}
    };
    return axios(config);
  };
  
  export const getVia = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PARAMETRIZACION_API}/Letra/1`,
      headers: {}
    };
    return axios(config);
  };

  export const getTipoVia = () => {
    var config = {
        method: "get",
        url: `${process.env.REACT_APP_PARAMETRIZACION_API}/TipoVia/1`,
        headers: {}
      };
      return axios(config);
  }
  
  export const SendInfoContacto = () => {


    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PARAMETRIZACION_API}/TipoVia/1`,
      headers: {}
    };
    return axios(config);
  }