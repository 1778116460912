import React from 'react'
import CardButton from '../../atoms/card-button/cardButton'
import './cardGroupInput.scss'


interface CardGroupInputProps {
    options: Array<{ title: string, value: any, img: string }>
    register: any;
    onClick?: any;
    value?:any;
}

const CardGroupInput = ({ options, register, onClick = (() => { }), value }: CardGroupInputProps) => {

    const handleClick = () => {
        onClick();
    }
    
    return (
        <div
            className="m-card-group-input"
            onChange={handleClick}
        >
            {options.map(item => {
               return <CardButton title={item.title} img={item.img} isSelected={false} value={item.value} register={register} checked={value} />
            })}
        </div>
    )
}

export default CardGroupInput;