import React from "react";
import Button from "../../atoms/button/button";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";

const WizardTab = ({ children, step, setStep, title, onNext, onPrev, flagButtonPrimary, textButtonSecundary }: any) => {
  
  const next = () => {
    setStep(step + 1);
  };
  const moveNext = () => {
    if (onNext) {
      onNext(next);
    } else {
      next();
    }
  };
  const movePrev = () => {
    if (step) setStep(step - 1);
  };
  return (
    <>
      <h1>{title}</h1>
      {children}
      <FlexDivUI as="section" gap={1} justifyContent="center">
        {!flagButtonPrimary &&  <Button
          type="button"
          classname=""
          text={"Regresar"}
          isDisabled={false}
          gtmMark="primary"
          onClickAction={onPrev || movePrev}
        />}
       
        <Button
          type="button"
          classname=""
          text={textButtonSecundary === undefined?"Continuar":textButtonSecundary}
          isDisabled={false}
          gtmMark="secondary"
          onClickAction={moveNext}
        />
      </FlexDivUI>
    </>
  );
};

export default WizardTab;
