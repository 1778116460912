import React, { useEffect, useState } from "react";
import Button from "../../atoms/button/button";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import InfoBox from "../../atoms/info-box/infoBox";
import TitleUI from "../../atoms/title-ui/TitleUI";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import icono from '../../../assets/icons/icono_solicitud_preaprobada.svg'


import "./SolicitudPreaprobada.scss";

const SolicitudPreaprobada = (props: any) => {
  const data = [
    {
      title: "Destino del credito",
      value: "Compra Usada VIS",
    },
    {
      title: "Monto del crédito hasta",
      value: "$ 50.000.000",
    },
    {
      title: "Plazo",
      value: "180 meses",
    },
    {
      title: "Tasa de interés",
      value:
        "La tasa de interés será la vigente al momento del desembolso para cada tipo de financiación",
    },
    {
      title: "Tipo de amortización",
      value: "Cuota fija en pesos",
    },
    {
      title: "Vigencia de la opreación",
      value: "12 meses a partir de la fecha de esta comunicación",
    },
  ];
  return (
    <WrapperContent>
      <div className="o-SolicitudPreaprobada">
      <div className="o-SolicitudPreaprobada__Title">
        <img src={icono} width={50}/>
        </div>
        <div className="o-SolicitudPreaprobada__Title">
          <h1>Tu solicitud de crédito ha sido pre-aprobada</h1>
        </div>
        <div className="o-SolicitudPreaprobada__SubTitle">
          <h2>Información del crédito</h2>
        </div>
        <div className="o-SolicitudPreaprobada__Container">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="o-SolicitudPreaprobada__Container-label"
                style={{
                  backgroundColor: index % 2 === 1 ? "#F7F7F7" : "white",
                }}
              >
                <div className="o-SolicitudPreaprobada__Container-label--left">
                  <label>{item.title}:</label>
                </div>
                <div className="o-SolicitudPreaprobada__Container-label--right">
                  <label>{item.value}</label>
                </div>
              </div>
            );
          })}
        </div>
        <div className="o-SolicitudPreaprobada__Paragraph">
          <div className="o-SolicitudPreaprobada__Paragraph--p">
            <p>
              La aprobación de tu credito depende de la presentanción de los
              documentos que soporten la información suministrada y al
              cumplimiento de las políticas de crédito de Comfama
            </p>
          </div>
        </div>

        <InfoBox
          title="Recuerda"
          description="Los valores aquí relacionados son susceptibles de modificación."
        />

        <div className="o-SolicitudPreaprobada__Button">
          <Button
            type="input"
            classname=""
            text="Aceptar"
            isDisabled={false}
            gtmMark="secondary"
          />
        </div>
        <div className="o-SolicitudPreaprobada__Question">
          <div className="o-SolicitudPreaprobada__Question--href">
            <label>¿DESEAS CONTINUAR CON LA RADICACIÓN DE TU CRÉDITO?</label>
          </div>
        </div>
      </div>
    </WrapperContent>
  );
};

export default SolicitudPreaprobada;
