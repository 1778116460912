import { ElementType, HTMLAttributes, FC, ReactNode } from "react";
import "./TitleUI.scss";

interface TitleUIProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  color?: "primary" | "dark" | "white";
  align?: "center" | "left" | "right";
  marginTop?: number;
  marginBottom?: number;
  children: ReactNode;
}

const TitleUI: FC<TitleUIProps> = ({
  as: Title = "h1",
  color = "primary",
  align = "left",
  marginTop = 21,
  marginBottom = 48,
  children,
  ...otherProps
}) => {
  const classParent = "a-TitleUI";
  const classString = `${classParent}--${color} ${classParent}--${align}`;
  return (
    <Title className={classString} {...otherProps} style={{ marginTop: marginTop, marginBottom: marginBottom }}>
      {children}
    </Title>
  );
};

export default TitleUI;
