import React from "react";
import "./loading.scss";
import imgLoading from "../../../assets/icons/loaading.gif";

const Loading = () => {
  return (
    <>
      <div className="loading-container"></div>
      <main className="loading-content">
        <img src={imgLoading} alt="Cargando..." className="loading__image" />
        <p className="loading__text">
          Espera un momento, estamos cargando el contenido
        </p>
      </main>
    </>
  );
};

export default Loading;
