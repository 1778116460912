import React from 'react'
import { useForm } from 'react-hook-form';
import Button from '../../atoms/button/button';
import './questionWizard.scss';

interface QuestionWizardContentProps {
    question: string;
    subtitle?: string;
    content: any;
    nextCallback?: any
    previousCallback?: any;
}

const QuestionWizardContent = ({ question, content, nextCallback, previousCallback, subtitle }: QuestionWizardContentProps) => {

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({ mode: "onChange" });

    const onSubmit = (data: any) => {
        console.log(data);
        nextCallback();
    }

    return (
        <div className="o-question-wizard-content-container">
            <h2 className="o-question-wizard-content-container__title">{question}</h2>
            {subtitle && <p className="o-question-wizard-content-container__subtitle">{subtitle}</p>}
            <form onSubmit={handleSubmit(onSubmit)} className="o-question-wizard-content-container__form">
                <div className="o-question-wizard-content-container__form__content">
                    {content(register, errors, watch, setValue)}
                </div>
                <div className="o-question-wizard-content-container__buttons-container">
                    {nextCallback && <Button
                        type="button"
                        classname="o-question-wizard-content-container__buttons-container__button"
                        text={"Volver"}
                        isDisabled={false}
                        onClickAction={() => previousCallback()}
                        gtmMark="primary"
                    />}
                    {previousCallback && <Button
                        type="input"
                        classname="o-question-wizard-content-container__buttons-container__button"
                        text={"Siguiente"}
                        isDisabled={false}
                        gtmMark="secondary"
                    />}
                </div>
            </form>
            {/* {children} */}
            {/* {step > 0 && */}

        </div>
    )
}

export default QuestionWizardContent