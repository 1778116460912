import axios from "./axiosConfig"

export const generarOtp = async (payload: any) => {
  console.log(`${process.env.REACT_APP_NOTIFICATION}/Otp/generation/`);
  console.log(payload);
  
  
    var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOTIFICATION}/Otp/generation/`,
        headers: {},
        data: payload 
      };
    return axios(config);
}

export const validarOtp = async (payload: any) => {
    var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOTIFICATION}/Otp/validation/`,
        headers: {},
        data: payload 
      };
    return axios(config);
}
