import React, { useState, useRef } from "react";

import "./inputFileDashboard.scss";
import iconDocument from "../../../assets/icons/documentos/Icono_documento.svg";
import iconTrash from "../../../assets/icons/documentos/icono_eliminar.svg";
import EditIcon from "../../../assets/icons/documentos/editar.svg"

type TProps = {
  name: string;
  label?: string;
  processFile: (name: string, file: File) => void;
  deleteFile?: (name: string) => void;
  isEdit?: boolean;
  setOpenModal?: any;
};

const InputFile = ({
  name,
  label = "Adjuntar Documento",
  processFile,
  deleteFile,
  isEdit,
  setOpenModal,
}: TProps) => {
  const [file, setFile] = useState<File>();
  const [error, setError] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    input.current?.click();
  };

  const handleDelete = () => {
    setError(false);
    setFile(undefined);
    if (deleteFile) {
      deleteFile(name);
    }
  };

  const handleSend = () => {
    if (file) {
      processFile(name, file);
    }
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    if (target.files?.length) {
      let file = target.files[0];
      if (file.size > 2097152) {
        //2Mb
        setError(true);
        setFile(undefined);
      } else {
        setFile(file);
      }
    } else {
      setFile(undefined);
    }
  };

  return (
    <div className="a-fileDashboard__container">
      <input
        type="file"
        name={name}
        className="a-fileDashboard__input"
        ref={input}
        onChange={handleChange}
        accept="image/png, image/jpeg, image/jpg, application/pdf"
      />
      <div className="a-fileDashboard">
        <div className="a-fileDashboard__content" onClick={handleClick}>
          <img src={iconDocument} width="15" alt="Doc" />
          {file ? (
            <span className="a-fileDashboard__content__nameFile">{file.name}</span>
          ) : (
            <div className="a-fileDashboard__label">
              <span className="a-fileDashboard__label__file">{label}</span>
            </div>
          )}
        </div>
        {isEdit && (
          <div className="a-fileDashboard__icons">
            <div className="a-fileDashboard__icons">
              <div className="container"></div>
              <img src={EditIcon} alt="Doc" width="15" onClick={handleSend} />
              <img src={iconTrash} alt="Doc" width="14" onClick={() => setOpenModal(true)} />
            </div>
          </div>
        )}
      </div>
      {error && (
        <span className="a-fileDashboard__error">
          El peso del archivo no debe pasar los 2Mb
        </span>
      )}
    </div>
  );
};

export default InputFile;
