import React, { useEffect, useState } from 'react'
import Input from '../../atoms/input/input';
import './inputPrestamo.scss';

interface InputPrestamoProps {
    title: string;
    total?: number;
    register?: any;
    errors?: any;
    formData: any;
    setData: any;
    setFormIdData: any;
    setValue?:any
}

const InputPrestamo = ({ title, total, register, errors, formData, setFormIdData, setData, setValue }: InputPrestamoProps) => {
    console.log(formData);
    
    const [manuallyChanged, setManuallyChanged] = useState(false);
    const [dataPrestamos, setDataPrestamo] = useState({
        valorComercial : formData.valorVivienda,
        porcentaje: Number(((formData.porcentajeResultado * 100) / formData.valorVivienda/100).toFixed(1)),
        valor : formData.porcentajeResultado
    });


    const handlePercentageCalculation = async (e: any) => {
        console.log(e);
        
        // setData(e);
        const percentage = e;
        const total = parseInt(formData["valorVivienda"]);

        console.log(percentage);

        // let cuota;

        const cuota = total * percentage;
        setFormIdData("porcentajeResultado", cuota);

        console.log(percentage, total, cuota);
        setDataPrestamo({
            ...dataPrestamos,
            valor : cuota
        })
    }

    const handleCuotaCalculation = (e: any) => {

        // setData(e);
        const total = parseInt(formData["valorVivienda"]);
        const cuota = parseInt(e.target.value);
        let percentage;
        let percentageData = 0;
        if (total) {
            percentage = (cuota * 100) / total;
            setFormIdData("valorPorcentaje", percentage || 0);
            percentageData = Number((percentage/100).toFixed(1));
        }

        setData(e);
        console.log(percentage, total, cuota);
        console.log(percentageData);
        
        setDataPrestamo({
            ...dataPrestamos,
            valor: e.target.value,
            porcentaje: percentageData
        })
    }

    useEffect(() =>{
        setValue("valorPorcentaje", dataPrestamos.porcentaje.toString());
    }, [dataPrestamos.porcentaje])



    // useEffect(() => {
    //     const percentage = formData['valorPorcentaje'];
    //     const total = parseInt(formData["valorVivienda"]);
    //     let cuota;

    //     if (total) {
    //         cuota = total * percentage;
    //         setFormIdData("porcentajeResultado", cuota + "");
    //     }

    //     console.log(percentage, total, cuota);

    // }, [formData['valorPorcentaje']]);

    // useEffect(() => {
    //     const total = parseInt(formData["valorVivienda"]);
    //     const cuota = parseInt(formData["porcentajeResultado"]);
    //     let percentage;

    //     if (total) {
    //         percentage = (cuota * 100) / total;
    //         setFormIdData("valorPorcentaje", Math.round(percentage) || 0 + "%");
    //     }

    //     console.log(percentage, total, cuota);

    // }, [formData['porcentajeResultado']])

    return (
        <div className="m-input-prestamo">
            <Input
                register={register("valorVivienda", {
                    required: "Este campo es requerido",
                    max: {
                        value: 200000000,
                        message: "El valor debe ser menor a $200.000.000"
                    },
                    onChange: setData
                })}
                type="number"
                title="¿Cuál es el valor comercial de la vivienda que quieres?"
                error={errors?.valorVivienda?.message || ""}
                placeholder="Agregar valor"
                className="m-input-prestamo__input-valor"
                setFormIdData={setFormIdData}
                value={formData.valorVivienda}
            />
            <div className="m-input-prestamo__section-title">
                {title && <p>{title}</p>}
            </div>
            <div className="m-input-prestamo__section">
                <Input
                    type='select'
                    title=""
                    register={register("valorPorcentaje", {
                        disabled: !formData['valorVivienda'],
                        required: "Este campo es obligatorio",
                        value: formData['valorVivienda'],
                        onChange: handlePercentageCalculation,
                    })}
                    onChange={handlePercentageCalculation}
                    error={errors?.valorPorcentaje?.message || ""}
                    placeholder={(Math.round(parseInt(formData['valorPorcentaje'])) || "00") + "%"}
                    options={[
                        { label: '80%', value: 0.8 },
                        { label: '70%', value: 0.7 },
                        { label: '60%', value: 0.6 },
                        { label: '50%', value: 0.5 },
                        { label: '40%', value: 0.4 },
                        { label: '30%', value: 0.3 },
                        { label: '20%', value: 0.2 },
                        { label: '10%', value: 0.1 },
                    ]}
                    defaultValue={formData['valorPorcentaje']}
                    value={dataPrestamos.porcentaje}

                    className="m-input-prestamo__percentage"
                />
                <Input
                    type='number'
                    title=""
                    register={register("porcentajeResultado", {
                        disabled: !formData['valorVivienda'] || errors?.valorVivienda?.message,
                        // required: "Este campo es obligatorio",
                        onChange: handleCuotaCalculation,
                        value: formData["porcentajeResultado"]
                    })}
                    value={dataPrestamos.valor}
                    disabled={true}
                    error={errors?.porcentajeResultado?.message}
                    placeholder=""
                    className="m-input-prestamo__value"
                />
            </div>
        </div>
    )
}

export default InputPrestamo