import React, { useState, useEffect } from "react";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Button from "../../atoms/button/button";
import InfoBox from "../../atoms/info-box/infoBox";
import Input from "../../atoms/input/input";
import { useContext } from 'react';
import { AppContext } from '../../../contexts/appContext';
import currencyFormat from '../../../utils/currencyFormat';

function CambiarValor() {
  const [errorInput, setErrorInput] = useState("");
  const [value, setValue] = useState(0);

  const { stateSolicitud, setCurrentTab, setValueSolicitud } = useContext(AppContext);
  const {
    solicitudData: { valorPreaprobado },
  } = stateSolicitud.solicitanteLider;

  const handleChange = (data: number) => {
    if (data > valorPreaprobado) {
        setErrorInput('El valor solicitado no puede superar el valor preaprobado');
    }else {
        setErrorInput('');
        setValue(data);
    }
  }

  useEffect(() => {
    setValue(valorPreaprobado);
  }, [valorPreaprobado]);
  

  return (
    <WrapperContent>
      <FlexDivUI alignContent="center" flexDirection="column">
        <TitleUI color="dark" align="center">
          ¿Por cuánto lo necesitas?
        </TitleUI>
        <Input
          type="money"
          error={errorInput}
          placeholder="Agrega el nuevo valor"
          className="m-input-prestamo__input-valor"
          value={value}
          setValue={(data: number) => {handleChange(data)}}
        />
        <InfoBox
          title="Recuerda que:"
          description="El valor solicitado no puede superar el valor preaprobado"
        />
        <Button
          text={"ACEPTAR"}
          type="button"
          classname="m-modal-button"
          gtmMark="secondary"
          isDisabled={false}
          onClickAction={() => {
            setValueSolicitud(value);
            setCurrentTab("simulador");
          }}
        />
      </FlexDivUI>
    </WrapperContent>
  );
}

export default CambiarValor;
