import React, { useEffect, useState } from "react";

import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import TitleUI from "../../atoms/title-ui/TitleUI";

import AcordeonFormulario from "../../molecules/acordeon/acordeonFormulario";

import { numFormat } from "../../../utils/jsonFormats";

import iconIngreso from "../../../assets/icons/icono_total_ingresos.svg";

import "./formularios.scss";

const Formularios = () => {
  const dataTest = {
    error: null,
    result: [
      {
        solicitanteId: 7,
        tipoSolicitante: "TITULAR",
        datosPersonales: {
          nombreCompleto: "Alejandro Arbelaez",
          tipoDocumento: "Cédula de ciudadania",
          numeroDocumento: 1017255694,
          lugarExpedición: "Colombia, Antioquia, Medellín",
          lugarNacimiento: "Colombia, Antioquia, Medellín",
          fechaNacimiento: "05 / 08 / 1997",
          edad: 25,
          estadoCivil: "Soltero",
          personasACargo: 0,
          nivelEducación: "Universitario",
        },
        datosContacto: {
          celular: 3207747015,
          otroNumero: 3001250203,
          correo: "alejandro@gmail.com",
          direccionResidencia: "Calle 11 # 3 - 3",
          estrato: 4,
          lugarResidencia: "Colombia, Antioquia, Medellín",
          tipoVivienda: "Arrendada",
          tiempoResidencia: "50 meses",
        },
        informacionActividadEconomica: {
          actividadEconomica: "Empleado",
          empresa: "Comfama",
          tipoContrato: "Fijo",
          fechaTerminacionContratoVigente: "06 / 12 / 2023",
        },
        informacionFinanciera: {
          ingresoBasico: 3000000,
          otrosIngresosDemostrables: 2000000,
          descripcion: "Emprendimiento",
          cuotaDeducidaNomina: 400000,
          cuotaNoDeducidaNomina: 0,
          otroEgreso: 800000,
        },
        asegurabilidad: [
          {
            enfermedad: "Ameurismo",
            fechaDiagnostico: "15 / 03 / 1997",
            tratamiento: "Terapias físicas",
          },
        ],
        referencias: [
          {
            tipo: "Familiar",
            nombres: "Alejandro Martinez",
            telefonoFijo: 64785545,
            celular: 304454455,
            parentesco: "Hermano",
            lugarResidencia: "Antioquia, Medellín",
          },
        ],
      },
      {
        solicitanteId: 5,
        tipoSolicitante: "AVALISTA",
        datosPersonales: {
          nombreCompleto: "Luis Perez",
          tipoDocumento: "Cédula de ciudadania",
          numeroDocumento: 105102112,
          lugarExpedición: "Colombia, Antioquia, Medellín",
          lugarNacimiento: "Colombia, Antioquia, Medellín",
          fechaNacimiento: "12 / 02 / 1990",
          edad: 32,
          estadoCivil: "Soltero",
          personasACargo: 0,
          nivelEducación: "Universitario",
        },
        datosContacto: {
          celular: 3172516995,
          otroNumero: 3001250203,
          correo: "luis@gmail.com",
          direccionResidencia: "Calle 10 # 8 - 1",
          estrato: 3,
          lugarResidencia: "Colombia, Antioquia, Medellín",
          tipoVivienda: "Arrendada",
          tiempoResidencia: "60 meses",
        },
        informacionActividadEconomica: {
          actividadEconomica: "Empleado",
          empresa: "Comfama",
          tipoContrato: "Fijo",
          fechaTerminacionContratoVigente: "02 / 12 / 2022",
        },
        informacionFinanciera: {
          ingresoBasico: 4000000,
          otrosIngresosDemostrables: 2000000,
          descripcion: "Emprendimiento",
          cuotaDeducidaNomina: 2000000,
          cuotaNoDeducidaNomina: 0,
          otroEgreso: 100000,
        },
        asegurabilidad: [
          {
            enfermedad: null,
            fechaDiagnostico: null,
            tratamiento: null,
          },
        ],
        referencias: [
          {
            tipo: null,
            nombres: null,
            telefonoFijo: null,
            celular: null,
            parentesco: null,
            lugarResidencia: null,
          },
        ],
      },
      {
        solicitanteId: 12,
        tipoSolicitante: "AVALISTA",
        datosPersonales: {
          nombreCompleto: "Juan Rodriguez",
          tipoDocumento: "Cédula de ciudadania",
          numeroDocumento: null,
          lugarExpedición: "Colombia, Antioquia, Medellín",
          lugarNacimiento: "Colombia, Antioquia, Medellín",
          fechaNacimiento: null,
          edad: 0,
          estadoCivil: null,
          personasACargo: null,
          nivelEducación: null,
        },
        datosContacto: {
          celular: null,
          otroNumero: null,
          correo: null,
          direccionResidencia: null,
          estrato: null,
          lugarResidencia: null,
          tipoVivienda: null,
          tiempoResidencia: null,
        },
        informacionActividadEconomica: {
          actividadEconomica: null,
          empresa: null,
          tipoContrato: null,
          fechaTerminacionContratoVigente: null,
        },
        informacionFinanciera: {
          ingresoBasico: null,
          otrosIngresosDemostrables: null,
          descripcion: null,
          cuotaDeducidaNomina: null,
          cuotaNoDeducidaNomina: null,
          otroEgreso: null,
        },
        asegurabilidad: [
          {
            enfermedad: null,
            fechaDiagnostico: null,
            tratamiento: null,
          },
        ],
        referencias: [
          {
            tipo: null,
            nombres: null,
            telefonoFijo: null,
            celular: null,
            parentesco: null,
            lugarResidencia: null,
          },
        ],
      },
    ],
  };

  const [data, setData] = useState<any>([]);

  const [dataAge, setDataAge] = useState(0);
  const [dataDescription, setDataDescription] = useState("");

  const [dataPosition, setDataPosition] = useState({
    datosPersonales: {
      "Nombre Completo": "",
      "Tipo de documento": "",
      "Número de documento": "",
      "Lugar de expedición": "",
      "Lugar de nacimiento": "",
      "Fecha de nacimiento": "",
      "Estado civil": "",
      "Personas a cargo": "",
      "Nivel de educación": "",
    },
    datosContacto: {
      "Número de celular": "",
      "Otro número": "",
      "Correo electrónico": "",
      "Dirección residencia": "",
      Estrato: "",
      "Lugar de residencia": "",
      "Tipo de vivienda": "",
      "Tiempo de residencia": "",
    },
    actividadEconomica: {
      "Actividad economica": "",
      Empresa: "",
      "Tipo de contrato": "",
      "Fecha de terminación de contrato vigente": "",
    },
    informacionFinanciera: {
      "Salario básico, ingreso o pensión": "",
      "Otros ingresos demostrables": "",
      "Total ingresos": "",
      "Coutas de créditos deducidos por nómina": "",
      "Coutas créditos no deducidos por nómina": "",
      "Gastos Familiares/ sostenimiento": "",
      "Total gastos": "",
    },
    asegurabilidad: [
      {
        Enfermedad: "",
        "Fecha diagnóstico": "",
        Tratamiento: "",
      },
    ],
    referencia: [
      {
        tipo: "",
        "Nombres y apellido": "",
        "Teléfono fijo": "",
        "Teléfono celular": "",
        Parentesco: "",
        "Lugar residencia": "",
      },
    ],
  });

  const [position, setPosition] = useState(0);

  const HandleData = async () => {
    setData(dataTest.result);

    let dataAsegurabilidad: any = [];
    dataTest.result[position].asegurabilidad.forEach((item, index) => {
      dataAsegurabilidad.push({
        Enfermedad: item.enfermedad,
        "Fecha diagnóstico": item.fechaDiagnostico,
        Tratamiento: item.tratamiento,
      });
    });

    let dataReferencia: any = [];
    dataTest.result[position].referencias.forEach((item, index) => {
      dataReferencia.push({
        tipo: item.tipo,
        "Nombres y apellido": item.nombres,
        "Teléfono fijo": item.telefonoFijo,
        "Teléfono celular": item.celular,
        Parentesco: item.parentesco,
        "Lugar residencia": item.lugarResidencia,
      });
    });

    setDataAge(dataTest.result[position].datosPersonales.edad);
    setDataDescription(
      dataTest.result[position].informacionFinanciera.descripcion || ""
    );

    setDataPosition({
      datosPersonales: {
        "Nombre Completo": `${dataTest.result[position].datosPersonales.nombreCompleto}`,
        "Tipo de documento": `${dataTest.result[position].datosPersonales.tipoDocumento}`,
        "Número de documento": `${dataTest.result[position].datosPersonales.numeroDocumento}`,
        "Lugar de expedición": `${dataTest.result[position].datosPersonales.lugarExpedición}`,
        "Lugar de nacimiento": `${dataTest.result[position].datosPersonales.lugarNacimiento}`,
        "Fecha de nacimiento": `${dataTest.result[position].datosPersonales.fechaNacimiento}`,
        "Estado civil": `${dataTest.result[position].datosPersonales.estadoCivil}`,
        "Personas a cargo": `${dataTest.result[position].datosPersonales.personasACargo}`,
        "Nivel de educación": `${dataTest.result[position].datosPersonales.nivelEducación}`,
      },
      datosContacto: {
        "Número de celular": `${dataTest.result[position].datosContacto.celular}`,
        "Otro número": `${dataTest.result[position].datosContacto.otroNumero}`,
        "Correo electrónico": `${dataTest.result[position].datosContacto.correo}`,
        "Dirección residencia": `${dataTest.result[position].datosContacto.direccionResidencia}`,
        Estrato: `${dataTest.result[position].datosContacto.estrato}`,
        "Lugar de residencia": `${dataTest.result[position].datosContacto.lugarResidencia}`,
        "Tipo de vivienda": `${dataTest.result[position].datosContacto.tipoVivienda}`,
        "Tiempo de residencia": `${dataTest.result[position].datosContacto.tiempoResidencia}`,
      },
      actividadEconomica: {
        "Actividad economica": `${dataTest.result[position].informacionActividadEconomica.actividadEconomica}`,
        Empresa: `${dataTest.result[position].informacionActividadEconomica.empresa}`,
        "Tipo de contrato": `${dataTest.result[position].informacionActividadEconomica.tipoContrato}`,
        "Fecha de terminación de contrato vigente": `${dataTest.result[position].informacionActividadEconomica.fechaTerminacionContratoVigente}`,
      },
      informacionFinanciera: {
        "Salario básico, ingreso o pensión": `${
          dataTest.result[position].informacionFinanciera.ingresoBasico != null
            ? numFormat(
                dataTest.result[position].informacionFinanciera.ingresoBasico
              )
            : ""
        }`,
        "Otros ingresos demostrables": `${
          dataTest.result[position].informacionFinanciera
            .otrosIngresosDemostrables != null
            ? numFormat(
                dataTest.result[position].informacionFinanciera
                  .otrosIngresosDemostrables
              )
            : ""
        }`,
        "Total ingresos": `${numFormat(
          (dataTest.result[position].informacionFinanciera.ingresoBasico || 0) +
            (dataTest.result[position].informacionFinanciera
              .otrosIngresosDemostrables || 0)
        )}`,
        "Coutas de créditos deducidos por nómina": `${
          dataTest.result[position].informacionFinanciera.cuotaDeducidaNomina !=
          null
            ? numFormat(
                dataTest.result[position].informacionFinanciera
                  .cuotaDeducidaNomina
              )
            : ""
        }`,

        "Coutas créditos no deducidos por nómina": `${
          dataTest.result[position].informacionFinanciera
            .cuotaNoDeducidaNomina != null
            ? numFormat(
                dataTest.result[position].informacionFinanciera
                  .cuotaNoDeducidaNomina
              )
            : ""
        }`,
        "Gastos Familiares/ sostenimiento": `${
          dataTest.result[position].informacionFinanciera.otroEgreso != null
            ? numFormat(
                dataTest.result[position].informacionFinanciera.otroEgreso
              )
            : ""
        }`,
        "Total gastos": `${numFormat(
          (dataTest.result[position].informacionFinanciera
            .cuotaDeducidaNomina || 0) +
            (dataTest.result[position].informacionFinanciera
              .cuotaNoDeducidaNomina || 0) +
            (dataTest.result[position].informacionFinanciera.otroEgreso || 0)
        )}`,
      },
      asegurabilidad: dataAsegurabilidad,
      referencia: dataReferencia,
    });
  };

  useEffect(() => {
    HandleData();
  }, []);

  useEffect(() => {
    HandleData();
  }, [position]);

  return (
    <div className="o-Formularios">
      <WrapperContent>
        <TitleUI>Formulario</TitleUI>

        <div className="o-Formularios__Title">
          {data.map((element: any, index: number) => {
            return (
              <div
                className={`o-Formularios__Title__Label ${
                  position === index ? "position" : ""
                }`}
                key={index}
                onClick={() => setPosition(index)}
              >
                <label>
                  {index ? `Solicitante adicional ${index}` : "Titular"}
                </label>
                {position === index && (
                  <div className="o-Formularios__Title__Label__Inline"></div>
                )}
              </div>
            );
          })}
        </div>

        <AcordeonFormulario
          title="Datos personales"
          data={Object.entries(dataPosition.datosPersonales)}
          data2={dataAge}
          data2Name={"Edad"}
          position2={5}
        />

        <AcordeonFormulario
          title="Datos de contacto"
          data={Object.entries(dataPosition.datosContacto)}
        />

        <AcordeonFormulario
          title="Actividad económica"
          data={Object.entries(dataPosition.actividadEconomica)}
        />

        <AcordeonFormulario
          title="Información Financiera"
          data={Object.entries(dataPosition.informacionFinanciera)}
          data2={dataDescription}
          data2Name={"Descripción"}
          position2={1}
          editable={true}
          backgroundInput={[2,6]}
          img={iconIngreso}
          imgInputs={[0,3]}
        />

        <AcordeonFormulario
          title="Asegurabilidad"
          dataList={dataPosition.asegurabilidad}
        />

        <AcordeonFormulario
          title="Referencia"
          dataList={dataPosition.referencia}
        />
      </WrapperContent>
    </div>
  );
};

export default Formularios;
