import React, { useEffect, useRef } from 'react'
import './modal.scss'

interface ModalProps {
    handleClose: any;
    children: any;
    open: boolean;
}

const Modal = (props: ModalProps) => {

    const ref = useRef(null) as any;

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current?.contains(event.target)) {
                props.handleClose();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            {props.open &&
                <>
                    <div className="modal-container"></div>
                    <main className="modal-content" ref={ref}>
                        <div className="modal-content__close" onClick={props.handleClose}>x</div>
                        {props.children}
                    </main>
                </>
            }
        </>
    )
}

export default Modal