import React, { useEffect, useState } from 'react'
import './menuCardTitular.scss'
import { Link } from "react-router-dom";
type item = {
    title: string,
    icon: string,
    value: string,
    children?: Array<item>,
}
interface MenuProps {
    icon: any;
    title: string;
    value: any;
    setValue: any;
    index: any;
    children?: any;
    isChild?: boolean;
    shouldDisplayChildren?: boolean;
    isredirect?: boolean;
    item: item;
}

const MenuCardTitular = ({ item, icon, title, value, index, setValue, children, isChild = false, shouldDisplayChildren = true , isredirect = false}: MenuProps) => {
    const [shouldSelect, setShouldSelect] = useState(false);
    const print = () => {
        console.log(value + ' : ' + index);
        setValue(index)
    }
    useEffect(() => {
        if (item.children) {
            const found = item.children.find((child) => child.value === value);
            if (found) setShouldSelect(true);
            else setShouldSelect(false);
        }
    }, [value])

    return (
        <>
        {isredirect ? (
        
        <Link to={index} className='no-style-link'>
            <div
            className={
                `m-MenuCardTitular-container ${isChild ? 'm-MenuCardTitular-container--child' : ''} 
                    ${(value === index || value.toString().split('.')[0] === index + '') ?
                    `m-MenuCardTitular-container--selected${isChild ? '-child' : ''}` : ''}`}
        >
            
            <div onClick={print} className="m-MenuCardTitular-container__content">
                <p>{title}</p>
            </div>
            {shouldDisplayChildren && <div className="m-MenuCardTitular-container__children">
                    {children}
                </div>}

            {/* {
                (children && shouldDisplayChildren && (value === index || value.toString().split('.')[0] === index + '')) &&
                <div className="m-MenuCardTitular-container__children">
                    {children}
                </div>
            } */}
        </div>
        </Link>) : (
            <div
            className={
                `m-MenuCardTitular-container ${isChild ? 'm-MenuCardTitular-container--child' : ''} 
                ${(value === index || shouldSelect) ?
                    `m-menuCard-container--selected${isChild ? '-child' : ''}` : ''}`
                }
        >
            
            <div onClick={print} className="m-MenuCardTitular-container__content">
                <p>{title}</p>
            </div>
            {shouldDisplayChildren && <div className="m-MenuCardTitular-container__children">
                    {children}
                </div>}

            {/* {
                (children && shouldDisplayChildren && (value === index || value.toString().split('.')[0] === index + '')) &&
                <div className="m-MenuCardTitular-container__children">
                    {children}
                </div>
            } */}
        </div>
        )}
        </>
        
        
    )
}

export default MenuCardTitular;