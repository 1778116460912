import React from 'react'
import './infoBox.scss'

interface InfoBoxProps {
    title: string;
    description: string;
    classNa?: string
}

const InfoBox = ({ title, description, classNa }: InfoBoxProps) => {
    return (
        <div className={`a-info-box-container ${classNa}`}>
            <div className="a-info-box-container__box">
                <h3 className="a-info-box-container__box__title">{title}</h3>
                <p className="a-info-box-container__box__description">{description}</p>
            </div>
        </div>
    )
}

export default InfoBox