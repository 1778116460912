import { TErrorDictionary } from '../interfaces/solicitudInterface';

const errorsDictionary: TErrorDictionary[] = [
  {
    name: "AFILIACION_FAIL",
    title: "¡Lo sentimos!",
    description: "No está afiliado",
    message:
      "No es posible continuar con tu solicitud, ya que no te encuentras afiliado.",
  },
  {
    name: 'TIEMPOAFILIACION_FAIL',
    title: '¡Lo sentimos!',
    description: 'No cumple la antigüedad de afiliación',
    message: 'No podemos continuar con tu solicitud, ya que no cumples con la antigüedad de afiliación requerida.',
},
{
    name: 'EMPLEADOR_FAIL',
    title: '¡Lo sentimos!',
    description: '',
    message: '',
},
{
    name: 'EDAD_FAIL',
    title: '¡Lo sentimos!',
    description: 'No cumple rango de edad',
    message: 'No podemos continuar con tu solicitud, ya que no cumples con el rango de edad necesario.',
},
{
    name: 'ANTIGUEDADLABORAL_FAIL',
    title: '¡Lo sentimos!',
    description: 'No cumple la antigüedad laboral',
    message: 'No podemos continuar con tu solicitud, ya que no cumples con la antigüedad laboral requerida.',
},
{
    name: 'MORASVIGENTES_FAIL',
    title: 'Moras vigentes',
    description: 'No está afiliado',
    message: 'Actualmente presentas moras vigentes con nosotros, por esto no es posible continuar tu solicitud.',
},
{
    name: 'SOLICITUDESTRAMITE_FAIL',
    title: '¡Lo sentimos!',
    description: 'Tiene solicitudes en tramite de otra linea',
    message: 'Tienes una solicitud en trámite en otra línea, por esto no es posible continuar este proceso.',
},
];

export default errorsDictionary;
