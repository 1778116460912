import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import AttachIcon from "../../../assets/icons/comentarios/Adjuntar.svg";
import DeleteIcon from "../../../assets/icons/comentarios/Eliminar.svg";

import Button from "../../atoms/button/button";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import Form from "../../atoms/form/form";
import Input from "../../atoms/input/input";
import TitleUI from "../../atoms/title-ui/TitleUI";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";

import "./comentarios.scss";

export interface IDataComment {
  date: any;
  value: any;
  comment?: IDataComment[];
}
const Comentarios = (props: any) => {
  const [comment, setComment] = useState("");
  const [flagHandle, setFlagHandle] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [dataComment, setDataComment] = useState<IDataComment[]>([]);
  const [flagcommentNote, setFlagcommentNote] = useState(false);
  const [indexCommentNote, setIndexcommentNote] = useState(0);
  const [editNotes, setEditNotes] = useState({
    indexDad: 0,
    indexChild: 0,
    flag: false,
  });

  const {
    register,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      titularComentario: "",
      titularComentarios: [],
    },
  });

  const HandleComment = (e: any) => {
    setComment(e.target.value);
  };
  const HandleSave = () => {
    if (comment.length <= 150) {
      if (editNotes.flag) {
        const Month = new Intl.DateTimeFormat("es-ES", {
          month: "long",
        }).format(new Date());
        let newData: IDataComment[] = [];

        let i = 0;
        dataComment.forEach((element) => {
          if (editNotes.indexDad === i) {
            let j = 0;
            let newDataChild: IDataComment[] = [];

            element.comment?.forEach((element2) => {
              if (editNotes.indexChild === j) {
                newDataChild.push({
                  date: `${new Date().getDate()} de ${Month.charAt(
                    0
                  ).toUpperCase()}${Month.slice(
                    1
                  )} ${new Date().getFullYear()}`,
                  value: comment,
                });
              } else {
                newDataChild.push(element2);
              }
              j++;
            });
            newData.push({
              ...element,
              comment: newDataChild,
            });
          } else {
            newData.push(element);
          }
          i++;
        });
        setDataComment(newData);
        setEditNotes({
          indexDad: 0,
          indexChild: 0,
          flag: false,
        });
        setComment("");
      } else {
        if (!flagcommentNote) {
          const Month = new Intl.DateTimeFormat("es-ES", {
            month: "long",
          }).format(new Date());
          setDataComment([
            ...dataComment,
            {
              date: `${new Date().getDate()} de ${Month.charAt(
                0
              ).toUpperCase()}${Month.slice(1)} ${new Date().getFullYear()}`,
              value: comment,
              comment: [],
            },
          ]);
          setComment("");
        } else {
          let newDataNote = dataComment;
          const Month = new Intl.DateTimeFormat("es-ES", {
            month: "long",
          }).format(new Date());
          for (let i = 0; i < newDataNote.length; i++) {
            const element = newDataNote[i];
            if (indexCommentNote === i) {
              element.comment?.push({
                date: `${new Date().getDate()} de ${Month.charAt(
                  0
                ).toUpperCase()}${Month.slice(1)} ${new Date().getFullYear()}`,
                value: comment,
              });
            }
          }
          console.log(newDataNote);

          setDataComment(newDataNote);
          setComment("");
          setFlagcommentNote(false);
          setIndexcommentNote(0);
        }
      }
    }
  };
  const HandleDelete = (position: number) => {
    const data = dataComment.filter((item, index) => index !== position);
    setDataComment(data);
  };
  const HandleEdit = (value: string, index: number) => {
    setComment(value);
    setFlagHandle(true);
    setIndexEdit(index);
  };
  const HandleUpdate = () => {
    if (comment.length <= 150) {
      let newDataComment = [];
      const Month = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
        new Date()
      );
      for (let i = 0; i < dataComment.length; i++) {
        const element = dataComment[i];
        newDataComment.push(
          indexEdit === i
            ? {
                date: `${new Date().getDate()} de ${Month.charAt(
                  0
                ).toUpperCase()}${Month.slice(1)} ${new Date().getFullYear()}`,
                value: comment,
                comment: element.comment,
              }
            : element
        );
      }
      setDataComment(newDataComment);
      setComment("");
      setFlagHandle(false);
      setIndexEdit(0);
    }
  };
  const HandleAddComment = (index: number) => {
    setFlagcommentNote(true);
    setIndexcommentNote(index);
  };
  const HandleEditNotes = (
    indexDad: number,
    itemComment: IDataComment,
    indexComment: number
  ) => {
    setComment(itemComment.value);
    setEditNotes({
      indexDad: indexDad,
      indexChild: indexComment,
      flag: true,
    });
  };

  const HandleDeleteComment = (indexDad: number, indexChild: number) => {
    let data: IDataComment[] = [];
    let i = 0;
    dataComment.forEach((element) => {
      let dataChild: IDataComment[] = [];

      if (i === indexDad) {
        let j = 0;

        element.comment?.forEach((element2) => {
          if (j !== indexChild) {
            dataChild.push(element2);
          }
          j++;
        });

        data.push({
          ...element,
          comment: dataChild,
        });
      } else {
        data.push(element);
      }
      setDataComment(data);
      i++;
    });
  };
  return (
    <WrapperContent>
      <FlexDivUI justifyContent="flex-start" flexDirection="column">
        <TitleUI marginBottom={21} marginTop={0} color="primary">
          Comentarios
        </TitleUI>
      </FlexDivUI>
      <WizardTab>
        <Form>
          <div className="o-Comentarios__note">
            <div className="o-Comentarios__note__input">
              <Input
                title=""
                register={register("titularComentario", {
                  required: true,
                  maxLength: 150,
                  onChange: HandleComment,
                })}
                className="o-Comentarios__note__input--textarea"
                value={comment}
                // error={errors?.titularComentario?.message}
                error={
                  comment.length > 150
                    ? `* Excediste la cantidad de caracteres:" ${-(
                        comment.length - 150
                      )}`
                    : ""
                }
                type="textarea"
                placeholder="Añade una nota"
                height={comment.length > 0 ? "100px" : "50px"}
              />
              <div className="o-Comentarios__note__input--img">
                <img
                  src={AttachIcon}
                  alt="Close"
                  width={24}
                  style={{
                    bottom:
                      comment.length > 0 && comment.length < 150
                        ? "20px"
                        : comment.length > 150
                        ? "40px"
                        : "",
                  }}
                />
              </div>
            </div>

            <div className="o-Comentarios__AddComment">
              {(comment || flagcommentNote) && (
                <Button
                  type="button"
                  isDisabled={false}
                  classname="o-Comentarios__AddComment--button"
                  gtmMark="primary"
                  text={flagHandle ? "Editar comentario" : "Agregar comentario"}
                  onClickAction={flagHandle ? HandleUpdate : HandleSave}
                ></Button>
              )}
            </div>
          </div>
          {dataComment.map((item, index) => {
            return (
              <div className="o-Comentarios__container" key={index}>
                <div
                  className="
                    o-Comentarios__container__section-comment"
                >
                  <div className="o-Comentarios__container__section-comment-span">
                    <span>{item.date}</span>
                  </div>
                  <div className="o-Comentarios__container__section-comment-img">
                    {!flagHandle && (
                      <img
                        src={DeleteIcon}
                        alt="Close"
                        width={18}
                        onClick={() => HandleDelete(index)}
                      />
                    )}
                  </div>
                </div>
                <div className="o-Comentarios__container__section-value">
                  <p onClick={() => HandleEdit(item.value, index)}>
                    {item.value}
                  </p>
                </div>
                <div
                  className="o-Comentarios__container__section-span"
                  onClick={() => HandleAddComment(index)}
                >
                  <span>Agregar comentarios a esta nota</span>
                </div>
                <br />
                {item.comment?.map((itemComment, indexComment) => {
                  return (
                    <div
                      key={indexComment}
                      className="o-Comentarios__container__Notes"
                    >
                      <div className="o-Comentarios__container__Notes-comment">
                        <div className="o-Comentarios__container__Notes-comment-span">
                          <span>{itemComment.date}</span>
                        </div>
                        <div className="o-Comentarios__container__Notes-comment-img">
                          {!flagHandle && (
                            <img
                              src={DeleteIcon}
                              alt="Close"
                              width={15}
                              onClick={() =>
                                HandleDeleteComment(index, indexComment)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="o-Comentarios__container__Notes__section-value">
                        <p
                          onClick={() =>
                            HandleEditNotes(index, itemComment, indexComment)
                          }
                        >
                          {itemComment.value}
                        </p>
                      </div>
                      <br />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Form>
      </WizardTab>
    </WrapperContent>
  );
};

export default Comentarios;
