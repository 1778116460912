import axios from "./axiosConfig";

export const getData = (
  creditLine: string,
  documentType: string,
  documentNumber: number
) => {
  return axios.get(
    `${process.env.REACT_APP_ORQUESTADOR_API}/solicitud/GestionarCreditos?lineaCreditoId=${creditLine}&tipoDocumentoId=${documentType}&numeroDocumento=${documentNumber}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSettle = (documentNumber: number) => {
  return axios.get(
    `${process.env.REACT_APP_ORQUESTADOR_API}/solicitud/ByNumeroDocumento/${documentNumber}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
