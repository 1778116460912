import React, { useEffect, useState } from 'react'
import { getListaRestrictivaById } from '../../../../services/orquestadorServices'

import "./listasRestrictivas.scss"

const ListasRestictivas = () => {
    const [dataListaRestrictiva, setDataListaRestrictiva] = useState<any>([])

    useEffect(() => {
        Promise.all([getListaRestrictivaById(localStorage.getItem('solicitudID'))])
        .then((response) => {
            setDataListaRestrictiva(response[0].data.result)
        })
        .catch((error) => console.error(error))
    }, [])

    console.log(dataListaRestrictiva);
    

    const headers = [
        {
        title: 'Requisito',
        value: 'requisito',
        },
        {
        title: 'Preaprobado',
        value: 'preaprobado',
        },
        {
        title: 'Radicación',
        value: 'radicacion',
        },
        {
        title: 'Ratificación aprobado',
        value: 'aprobado',
        },
        {
        title: 'Análisis de garantía',
        value: 'garantia',
        },
    ]
    
    return (
        <>
      {dataListaRestrictiva.map((element: any, index: number) => {
        return (
          <div className="o-ListasRestrictivas" key={index}>
            <div className="o-ListasRestrictivas__content">
              <div className="o-ListasRestrictivas__content__nombre">
                {element.nombre}
              </div>
              <div className={element.tipoSolicitante === "TITULAR" ? "o-ListasRestrictivas__content__box" : "o-ListasRestrictivas__content__box__solicitante"}>
                {element.tipoSolicitante === "TITULAR" ? "Titular" : `Solicitante Adicional ${index}`}
              </div>
            </div>
            <table className="m-table" cellSpacing="0">
              <thead className="m-table__head">
                <tr>
                  {headers.map((header) => (
                    <th className="m-table__body__th">{header.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="m-table__body">
                {element.requisitos.map((element2: any, index2: number) => {
                  return (
                    <tr key={index2}>
                      <td className="m-table__body__td">
                        <div>Resultado</div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.preaprobado.valido}`}>
                          {element2.preaprobado.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.radicacion.valido}`}>
                          {element2.radicacion.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div
                          className={`${element2.ratificacionAprobado.valido}`}
                        >
                          {element2.ratificacionAprobado.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.analisisGarantia.valido}`}>
                          {element2.analisisGarantia.informacion}
                        </div>
                      </td>
                    </tr>
                  )
                })}
                    <tr >
                      <td className="m-table__body__td">
                        <div>Fecha</div>
                      </td>
                      <td className="m-table__body__td">
                      </td>
                      <td className="m-table__body__td">
                      </td>
                      <td className="m-table__body__td">
                      </td>
                      <td className="m-table__body__td">
                      </td>
                    </tr>
              </tbody>
            </table>
          </div>
        )
      })}
    </>
    )
}

export default ListasRestictivas