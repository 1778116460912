import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AppContext } from '../../../contexts/appContext'
import { getEnfermedades } from '../../../services/parametrizacionServices'
import FlexDivUI from '../../atoms/flexible-div-ui/FlexDivUI'
import Input from '../../atoms/input/input'
import TitleUI from '../../atoms/title-ui/TitleUI'
import WrapperContent from '../../atoms/wrapper-content/WrapperContent'
import icon_corazon from '../../../assets/icons/asegurabilidad/icono_enfermedades.svg'
import './asegurabilidad.scss'
import Button from '../../atoms/button/button'
import { saveAsegurabilidad } from '../../../services/orquestadorServices'

const Asegurabilidad = () => {
    const context = useContext(AppContext);
    const [enfermedades, setEnfermedades] = useState([]);
    const [enfermedadesList, setEnfermedadesList] = useState<any>([]);
    const [otroText, setOtroText] = useState({
        id: '',
        text: ''
    });
    const [enfermedadData, setEnfermedadData] = useState<any>({});
    const { register, formState: { errors }, control } = useForm({});

    const handleSelectEnfermedad = (e: any) => {
        console.log(e.target.value);
        const _enfermedad = enfermedadesList.find((el: any) => el.enfermedadId === e.target.value)
        if (_enfermedad) {
            return;
        }

        let selectedEnfermedad: any = enfermedades.find((el: any) => el.value + "" === e.target.value);
        console.log(selectedEnfermedad);
        
        setEnfermedadesList((prev: any) => [...prev, {enfermedadId: e.target.value, nombre: selectedEnfermedad.label}]);
    }

    const handleChangeDate = (e: any) => {
        const id = e.target.name.split('.')[0];
        console.log(id);
        const _enfermedades: any = {...enfermedadData};

        if (!_enfermedades[id]) {
            _enfermedades[id] = {};
        }
        
        _enfermedades[id].date = e.target.value.format('YYYY-MM-DD');
        setEnfermedadData(_enfermedades);
    }

    const handleChangeTratamiento = (e: any) => {
        const id = e.target.name.split('.')[0];
        console.log(id);
        
        const _enfermedades: any = {...enfermedadData};

        if (!_enfermedades[id]) {
            _enfermedades[id] = {};
        }
        
        _enfermedades[id].text = e.target.value;

        setEnfermedadData(_enfermedades);
    }


    const handleChangeOtras = (e: any) => {

    }

    const saveData = async () => {
        if (enfermedadesList.length === 0) {
            context.setCurrentTab('terminosCondiciones');
            return;
        }
        const parsedData = {
            "SolicitanteId": 1,
            "Enfermedades": enfermedadesList.map((enfermedad: any) => ({
                "EnfermedadId": enfermedad.enfermedadId,
                "FechaDiagnostico": enfermedadData[enfermedad.enfermedadId].date,
                "Tratamiento": enfermedadData[enfermedad.enfermedadId].text
            }))
        }

        console.log(parsedData);
        await saveAsegurabilidad(parsedData).then((res) => {
            alert('Asegurabilidad guardada exitosamente');

            context.setCurrentTab('terminosCondiciones')
        })
        .catch((error) => {
            alert('Hubo un error guardando la asegurabilidad')
        })
    }

    const removeEnfermedad = (idx: number) => {
        const tempEnfermedades = [...enfermedadesList];
        tempEnfermedades.splice(idx, 1);
        setEnfermedadesList(tempEnfermedades);
    }

    useEffect(() => {
        getEnfermedades().then((res) => {
            const tempEnfermedades = res.data.result.map((enfermedad: any) => ({label: enfermedad.nombre, value: enfermedad.id}));
            setEnfermedades(tempEnfermedades);
        })
        // const tempEnfermedades: any = data.map((enfermedad: any) => ({label: enfermedad.nombre, value: enfermedad.id}));
        // setEnfermedades(tempEnfermedades);
    }, [])

    useEffect(() => {
        console.log(enfermedadData);
    }, [enfermedadData]);

    return (
        <WrapperContent>
            <FlexDivUI justifyContent='flex-start' flexDirection='column'>
                <TitleUI marginBottom={21} marginTop={0} color="dark">Declaración de asegurabilidad</TitleUI>
                <TitleUI marginBottom={15} marginTop={0} as="h4" color="dark">Sufres, has sufrido o está siendo tratado por alguna de las siguientes enfermedades</TitleUI>
                <p>Trastornos cardiovasculares, trastornos o insuficiencia renal crónica, tensión arterial alta, derrame cerebral, accidente cerebro vascular, infarto al miocardio, cáncer, tumores, diabetes, epilepsia, asma, úlceras, reumatismo o gota, paraplejía, trastornos nerviosos, enfermedades de transmisión sexual, VIH / SIDA.</p>
                <div className="asegurabilidad-input">
                    <FlexDivUI>
                        <Input
                            register={register("currentEnfermedad",{
                                onChange: handleSelectEnfermedad
                            })}
                            error={errors?.currentEnfermedad?.message}
                            type={"select"}
                            placeholder="Agrega el número de la respuesta"
                            options={enfermedades}
                        />
                    </FlexDivUI>
                </div>
            </FlexDivUI>
            <FlexDivUI flexDirection='column' gap={2}>
                {
                    enfermedadesList.map((enfermedad: any, idx: number) => (
                        <div className="asegurabilidad-card" key={idx}>
                            <header className="asegurabilidad-card__header">
                                <FlexDivUI gap={1}>
                                    <img width={20} src={icon_corazon} alt={enfermedad.nombre}/>
                                    <TitleUI as="h3" color='dark' marginTop={0} marginBottom={0}>{enfermedad.nombre}</TitleUI>
                                </FlexDivUI>
                                <button className="asegurabilidad-card__header__eliminar" onClick={() => removeEnfermedad(idx)}>
                                    <h3>X</h3>
                                </button>
                            </header>
                            <main className="asegurabilidad-card__content">
                                {(enfermedad.enfermedadId + "") === "18" && 
                                    <Input
                                        title='¿Cuál? *'
                                        register={register(`${enfermedad.enfermedadId}.date`,{
                                            onChange: handleChangeOtras
                                        })}
                                        error={errors?.currentEnfermedad?.message}
                                        type={"text"}
                                        placeholder="Escribe la enfermedad"
                                    />
                                }
                                <FlexDivUI gap={1} display="flex">
                                    <Input
                                        title='Fecha de diagnostico *'
                                        register={register(`${enfermedad.enfermedadId}`,{
                                            onChange: handleChangeDate
                                        })}
                                        error={errors?.currentEnfermedad?.message}
                                        type={"date"}
                                        placeholder=""
                                        controller={control}
                                        name={`${enfermedad.enfermedadId}`}
                                        onChange={handleChangeDate}
                                    />
                                    <Input
                                        title='Tratamiento *'
                                        register={register(`${enfermedad.enfermedadId}.text`,{
                                            onChange: handleChangeTratamiento
                                        })}
                                        error={errors?.currentEnfermedad?.message}
                                        type={"text"}
                                        placeholder="Escribe el tratamiento"
                                    />
                                </FlexDivUI>
                            </main>
                        </div>
                    ))
                }
            </FlexDivUI>
            <FlexDivUI gap={1}>
                <Button onClickAction={() => context.setCurrentTab('informacionFinanciera')} type="button" classname="" text="Volver" isDisabled={false} gtmMark="primary"/>
                <Button onClickAction={saveData} type="button" classname="" text="Continuar" isDisabled={false} gtmMark="secondary"/>
            </FlexDivUI>
        </WrapperContent>
    )
}

export default Asegurabilidad