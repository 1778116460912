import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AppContext } from '../../../contexts/appContext'
import FlexDivUI from '../../atoms/flexible-div-ui/FlexDivUI'
import Form from '../../atoms/form/form'
import Input from '../../atoms/input/input'
import TitleUI from '../../atoms/title-ui/TitleUI'
import WrapperContent from '../../atoms/wrapper-content/WrapperContent'
import Wizard from '../wizard/Wizard'
import WizardTab from '../wizard/WizardTab'
import graciasIcon from "../../../assets/icons/datosPersonales/icono_gracias.svg";

import ningunoIcon from "../../../assets/icons/icon_ninguna.svg";
import CardGroupInput from '../../molecules/card-group-input/cardGroupInput'
import InfoBox from '../../atoms/info-box/infoBox'
import ParametrizacionContext from '../../../contexts/parametrizacionContext'
import icono_soltero from '../../../assets/icons/datosPersonales/icono_Soltero.svg';
import icono_casado from '../../../assets/icons/datosPersonales/icono_casado.svg';
import icono_separado from '../../../assets/icons/datosPersonales/icono_Separado.svg';
import icono_viudo from '../../../assets/icons/datosPersonales/icono_viudo.svg';
import icono_union_libre from '../../../assets/icons/datosPersonales/icono_union_libre.svg';
import icono_primaria from '../../../assets/icons/datosPersonales/primaria.svg';
import icono_bachiller from '../../../assets/icons/datosPersonales/icono_bachiller.svg';
import icono_tecnico from '../../../assets/icons/datosPersonales/icono_tecnico_tecnologo.svg';
import icono_universitario from '../../../assets/icons/datosPersonales/icono_universitario.svg';
import icono_posgrado from '../../../assets/icons/datosPersonales/icono_posgrado.svg';
import { getEstadoCivil, getNivelEstudio } from '../../../services/parametrizacionServices'
import { saveDatosPersonales } from '../../../services/orquestadorServices'
import Loading from '../../atoms/loading/loading'
import Datalist from '../../atoms/datalist/datalist'
import { UPDATE_SOLICITANTE_LIDER, UPDATE_SOLICITANTE_AVALISTA } from '../../../reducers/reducerSolicitud'
import Select from '../../atoms/select/select'

type TFields = {
    tipoDocumentoId: string,
    numeroDocumento: string,
    lugarExpedicion: string,
    fechaExpedicion: string,
    lugarNacimiento: string,
    fechaNacimiento: string,
    estadoCivilId?: number,
    personasACargo?: number,
    nivelEstudioId?: number,
    idSolicitante?:number
}

const DatosPersonales = (props: any) => {
    const [dlloMunicipios, setMunicipios] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [expedicionValue, setExpedicionValue] = useState({label: '', value: undefined});
    const [nacimientoValue, setNacimientoValue] = useState({label: '', value: undefined});

    const [estadoCivil, setEstadoCivil] = useState([]);
    const [nivelEstudio, setNivelEstudio] = useState([]);
    const [loading, setLoading] = useState(false);
    const cardsEducacion: any = useMemo(() => ({
        "Ninguno": ningunoIcon,
        "Primaria": icono_primaria,
        "Bachiller": icono_bachiller,
        "Técnico o Tecnólogo": icono_tecnico,
        "Universitario": icono_universitario,
        "Posgrado": icono_posgrado,
    }), [])

    const cardsCivil: any = useMemo(() => ({
        "soltero": icono_soltero,
        "casado": icono_casado,
        "separado": icono_separado,
        "viudo/a": icono_viudo,
        "union libre": icono_union_libre,
    }), []);

    const { municipios, tipoIdentificacion } = useContext(ParametrizacionContext);
    
    const { setCurrentTab, stateSolicitud, dispatchSolicitud } = useContext(AppContext);
    const { register, formState: { errors }, control, trigger, watch, setValue } = useForm<TFields>({
        defaultValues: {
            tipoDocumentoId: '',
            numeroDocumento: '',
            lugarExpedicion: '',
            fechaExpedicion: '',
            lugarNacimiento: '',
            fechaNacimiento: '',
            estadoCivilId: undefined,
            personasACargo: undefined,
            nivelEstudioId: undefined,
        }
    });

    const watchAll = watch();
    
    const saveData = async (next: () => void) => {
        console.log(next);
        setLoading(true);
        setCurrentTab("datosContacto");
    }

    const validateData = async (fields: Array<keyof TFields>, next: any) => {
        const isValid = await trigger(fields);
        if (isValid) {
            const payload: any = {};
            
            fields.forEach((field: keyof TFields) => {
                payload[field] = watchAll[field];
            })
            
            payload["idSolicitante"] = stateSolicitud.solicitanteLider.stateSolicitante;
            console.log(watchAll);
            console.log(payload);
            
            if(stateSolicitud.solicitanteLider.stateSolicitante === 0) {
                dispatchSolicitud({
                    type: UPDATE_SOLICITANTE_LIDER,
                    payload
                });
            }
            else{
                dispatchSolicitud({
                    type: UPDATE_SOLICITANTE_AVALISTA,
                    payload
                });
            }
           
            next();
        }
    }
    useEffect(() => {
        console.log(watchAll);
    }, [watchAll])

    const UpdateSolicitantes = () => {
        if(stateSolicitud.solicitanteLider.stateSolicitante){
            setValue('tipoDocumentoId', String(stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.tipoDocumentoId) || '')
            setValue('numeroDocumento', String(stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.numeroDocumento) || '')
            setValue('lugarExpedicion', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarExpedicion || '')
            setValue('fechaExpedicion', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.fechaExpedicion || '')
            setValue('lugarNacimiento', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarNacimiento || '')
            setValue('fechaNacimiento', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.fechaNacimiento || '')

            setValue('estadoCivilId', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.estadoCivilId != undefined ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.estadoCivilId : undefined)
            setValue('personasACargo', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.personasACargo != undefined ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.personasACargo : undefined)

            setValue('nivelEstudioId', stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.nivelEstudioId != undefined ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.nivelEstudioId : undefined)
        }
        else{
            setValue('tipoDocumentoId', String(stateSolicitud.solicitanteLider.solicitante.tipoDocumentoId) || '')
            setValue('numeroDocumento', String(stateSolicitud.solicitanteLider.solicitante.numeroDocumento) || '')
            setValue('lugarExpedicion', stateSolicitud.solicitanteLider.solicitante.lugarExpedicion || '')
            setValue('fechaExpedicion', stateSolicitud.solicitanteLider.solicitante.fechaExpedicion || '')
            setValue('lugarNacimiento', stateSolicitud.solicitanteLider.solicitante.lugarNacimiento || '')
            setValue('fechaNacimiento', stateSolicitud.solicitanteLider.solicitante.fechaNacimiento || '')
            setValue('estadoCivilId', stateSolicitud.solicitanteLider.solicitante.estadoCivilId != undefined ? stateSolicitud.solicitanteLider.solicitante.estadoCivilId : undefined)
            setValue('personasACargo', stateSolicitud.solicitanteLider.solicitante.personasACargo != undefined ? stateSolicitud.solicitanteLider.solicitante.personasACargo : undefined)
            setValue('nivelEstudioId', stateSolicitud.solicitanteLider.solicitante.nivelEstudioId != undefined ? stateSolicitud.solicitanteLider.solicitante.nivelEstudioId : undefined)
        }
    }

    useEffect(() => {
      

        let expValue : any = {};
        let nacimientoValue : any = {};
        UpdateSolicitantes();

        municipios.forEach(element => {
            const municipioSelect = {
                label: `${element.departamento} - ${element.nombre}`, 
                value: element.codigoDane
            }
            if (stateSolicitud.solicitanteLider.stateSolicitante) {
                if (String(municipioSelect.value) == stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarExpedicion) {
                    expValue = municipioSelect;
                }
                if (String(municipioSelect.value) == stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarNacimiento) {
                    nacimientoValue = municipioSelect;
                }
            }
            else{
                if (String(municipioSelect.value) == stateSolicitud.solicitanteLider.solicitante.lugarExpedicion) {
                    expValue = municipioSelect;
                }
                if (String(municipioSelect.value) == stateSolicitud.solicitanteLider.solicitante.lugarNacimiento) {
                    nacimientoValue = municipioSelect;
                }
            }
        });
        console.log(expValue);
        console.log(expValue != undefined);
        
        
        if (expValue != undefined){
            setExpedicionValue(expValue);
        }
        if (nacimientoValue != undefined){
            setNacimientoValue(nacimientoValue);
        }

        
    }, [stateSolicitud.solicitanteLider.stateSolicitante])
    

    const getEstadoCivilLocal = async () => {
        await getEstadoCivil()
            .then(res => {
                const tempEstadoCivil = res.data.result.map((estado: any) => ({
                    title: estado.nombre,
                    value: estado.id,
                    img: cardsCivil[estado.nombre.toLowerCase()]
                }))
                setEstadoCivil(tempEstadoCivil);
            });
    }
    
    const getEducacionLocal = async () => {
        await getNivelEstudio()
            .then(res => {
                const tempNivelEstudio = res.data.result.map((estudio: any) => ({
                    title: estudio.nombre,
                    value: estudio.id,
                    img: cardsEducacion[estudio.nombre]
                }))
                setNivelEstudio(tempNivelEstudio);
            });
    }

    useEffect(() => {
        
        setLoading(true);

        UpdateSolicitantes();
        console.log(stateSolicitud);

        let tempMunicipios : any = [];
        let expValue : any = {};
        let nacimientoValue : any = {};


        municipios.forEach(element => {
            const municipioSelect = {
                label: `${element.departamento} - ${element.nombre}`, 
                value: element.codigoDane
            }
            if (stateSolicitud.solicitanteLider.stateSolicitante) {
                if (String(municipioSelect.value) == stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarExpedicion) {
                    expValue = municipioSelect;
                }
                if (String(municipioSelect.value) == stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.lugarNacimiento) {
                    nacimientoValue = municipioSelect;
                }
            }
            else{
                if (String(municipioSelect.value) == stateSolicitud.solicitanteLider.solicitante.lugarExpedicion) {
                    expValue = municipioSelect;
                }
                if (String(municipioSelect.value) == stateSolicitud.solicitanteLider.solicitante.lugarNacimiento) {
                    nacimientoValue = municipioSelect;
                }
            }
          
            tempMunicipios.push(municipioSelect);
        });
        if (expValue != undefined) {
            setExpedicionValue(expValue);
        }
        if (nacimientoValue != undefined) {
            setNacimientoValue(nacimientoValue);
        }
        setMunicipios(tempMunicipios);
       
        const tempTipoDocumento: any = tipoIdentificacion?.map((type) => ({label: `${type.nombre}`, value: type.id}));
        setTipoDocumento(tempTipoDocumento);
        getEstadoCivilLocal();
        getEducacionLocal();

        setLoading(false);


    }, [])


    return (
        <WrapperContent>
            {loading && <Loading></Loading>}
            <Wizard>
                <WizardTab onNext={(next: any) => validateData(['tipoDocumentoId', 'numeroDocumento', 'lugarExpedicion', 'fechaExpedicion'], next)}>
                    <TitleUI as="h1" color='dark' align='center'>¿Cuál es el documento de identidad del titular?</TitleUI>
                        <FlexDivUI gap={1} justifyContent="center">
                            {/* <Input
                                title="Tipo de documento"
                                register={register("titularTipoDocumento", {
                                    required: '* Campo obligatorio'
                                })}
                                error={errors?.titularTipoDocumento?.message}
                                options={tipoDocumento}
                                options={tipoDocumento}
                                type={"select"}
                            /> */}
                            <Select 
                                name="tipoDocumentoId" 
                                error={errors?.tipoDocumentoId?.message || ''} 
                                title="Tipo de documento"
                                options={tipoDocumento}
                                rules={{required: '*Campo obligatorio'}}
                                control={control as any}
                                value={watchAll.tipoDocumentoId}
                            />
                            <Input
                                title="Numero de documento"
                                register={register("numeroDocumento", {
                                    required: '* Campo obligatorio'
                                })}
                                error={errors?.numeroDocumento?.message}
                                placeholder="Ingresa el número"
                                type={"number"}
                            />
                        </FlexDivUI>
                        <FlexDivUI gap={1} justifyContent="center">
                            {/* <Input
                                title="Lugar de expedición del documento"
                                register={register("titularLugarExpedicionDocumento", {
                                    required: '*Campo obligatorio'
                                })}
                                error={errors?.titularLugarExpedicionDocumento?.message}
                                options={dlloMunicipios}
                                options={dlloMunicipios}
                                type={"select"}
                            /> */}
                        
                            <Datalist
                                title="Lugar de expedición del documento"
                                name="lugarExpedicion"
                                options={dlloMunicipios}
                                rules={{required: '* Campo obligatorio'}}
                                control={control as any}
                                valueSelect={expedicionValue}
                            />

                           

                           
                            {/* <Datalist
                                title="Lugar de expedición del documento"
                                register={register("titularLugarExpedicionDocumento", {
                                    required: "* Campo requerido"
                                })}
                                name={'titularLugarExpedicionDocumento'}
                                id={"titularLugarExpedicionDocumento1"}
                                options={dlloMunicipios}
                                onChange={setValue}
                                value={watchAll.titularLugarExpedicionDocumento}
                                error={errors?.titularLugarExpedicionDocumento?.message}
                            /> */}
                            <Input
                                title="Fecha de expedición del documento"
                                error={errors?.fechaExpedicion?.message}
                                type={"date"}
                                controller={control}
                                rules={{
                                    required: "* Campo requerido"
                                }}
                                name="fechaExpedicion"
                            />
                        </FlexDivUI>
                </WizardTab>
                <WizardTab>
                    <TitleUI as="h1" color='dark' align='center'>¿Este es el nombre del titular?</TitleUI>
                    <TitleUI as="h2" color='dark' align='center'>{(stateSolicitud.solicitanteLider.solicitante.primerNombre + ' ' + stateSolicitud.solicitanteLider.solicitante.primerApellido) || ''}</TitleUI>
                </WizardTab>
                <WizardTab onNext={(next: any) => validateData(['lugarNacimiento', 'fechaNacimiento'], next)}>
                    <TitleUI as="h1" color='dark' align='center'>¿Cuándo y dónde nació {stateSolicitud.solicitanteLider.solicitante.primerNombre || ""}?</TitleUI>
                        <FlexDivUI gap={1} justifyContent="center">
                           
                             <Datalist
                                title="Lugar de nacimiento"
                                name="lugarNacimiento"
                                options={dlloMunicipios}
                                rules={{required: '* Campo obligatorio'}}
                                control={control as any}
                                valueSelect={nacimientoValue}
                            />
                            <Input
                                title="Fecha de nacimiento"
                                error={errors?.fechaNacimiento?.message}
                                type={"date"}
                                controller={control}
                                name="fechaNacimiento"
                                rules={{
                                    required: '*Campo requerido'
                                }}
                            />
                            {/* <Datalist
                                register={register("titularFechaNacimiento", {
                                    required: "Campo requerido"
                                })}
                                name={'titularFechaNacimiento'}
                                id={"titularFechaNacimiento1"}
                                options={[{id: 1 , name: 'Medellin, Antioquia'},{id: 2 , name: 'Lorem ipsum'},
                                    {id: 3 , name: 'Lorem ipsum'}] || []}
                                onChange={setValue}
                                value={''}
                            /> */}
                        </FlexDivUI>
                </WizardTab>
                <WizardTab onNext={(next: any) => validateData(['estadoCivilId'], next)}>
                    <TitleUI as="h1" color='dark' align='center'>¿Cuál es tu estado civil?</TitleUI>
                    <Form>
                        <FlexDivUI gap={1} justifyContent="center">
                            <CardGroupInput
                                options={estadoCivil}
                                register={register("estadoCivilId", {
                                    required: "Campo requerido"
                                })}
                                value={stateSolicitud.solicitanteLider.stateSolicitante ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.estadoCivilId: stateSolicitud.solicitanteLider.solicitante.estadoCivilId}
                            />
                        </FlexDivUI>
                    </Form>
                </WizardTab>
                <WizardTab onNext={(next: any) => validateData(['personasACargo'], next)}>
                    <TitleUI as="h1" color='dark' align='center'>¿Cuántas personas tienes a cargo?</TitleUI>
                    <Form>
                        <FlexDivUI gap={1} justifyContent="center">
                            <Input
                                register={register("personasACargo", {
                                    required: '*Campo obligatorio'
                                })}
                                error={errors?.personasACargo?.message}
                                type={"number"}
                                placeholder="Agrega el número de la respuesta"
                                value={stateSolicitud.solicitanteLider.stateSolicitante ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.personasACargo : stateSolicitud.solicitanteLider.solicitante.personasACargo}
                            />
                        </FlexDivUI>
                        <InfoBox title='Personas a cargo:' description='Si no tienes personas a cargo marca 0' />
                    </Form>
                </WizardTab>
                <WizardTab onNext={(next: any) => validateData(['nivelEstudioId'], next)}>
                    <TitleUI as="h1" color='dark' align='center'>¿Cuál es tu nivel de educación?</TitleUI>
                    <Form>
                        <FlexDivUI gap={1} justifyContent="center">
                            <CardGroupInput
                                options={nivelEstudio}
                                register={register("nivelEstudioId", {
                                    required: "Campo requerido"
                                })}
                                value={stateSolicitud.solicitanteLider.stateSolicitante ? stateSolicitud.avalistas[stateSolicitud.solicitanteLider.stateSolicitante-1].solicitante.nivelEstudioId : stateSolicitud.solicitanteLider.solicitante.nivelEstudioId}
                            />
                        </FlexDivUI>
                    </Form>
                </WizardTab>
                <WizardTab onNext={(next: any) => saveData(next)}>
                    <FlexDivUI gap={1} justifyContent="center" flexDirection='column' alignContent='center'>
                        <FlexDivUI as='div' justifyContent="center" >
                            <img src={graciasIcon} alt="" width={50} />
                        </FlexDivUI>
                        <TitleUI as="h1" color='dark' align='center' marginBottom={10}>¡Gracias!</TitleUI>
                        <TitleUI as="h2" color='dark' align='center' marginTop={0}>Continuemos con los datos de contacto.</TitleUI>
                    </FlexDivUI>
                </WizardTab>
            </Wizard>
        </WrapperContent>
    )
}

export default DatosPersonales