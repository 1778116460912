import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../contexts/appContext";
import InfoBox from "../../atoms/info-box/infoBox";
import Input from "../../atoms/input/input";
import TitleUI from "../../atoms/title-ui/TitleUI";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import CardGroupInput from "../../molecules/card-group-input/cardGroupInput";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";
import "./informacionFinanciera.scss";
import iconIngreso from "../../../assets/icons/icono_total_ingresos.svg";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import Button from "../../atoms/button/button";
import { numFormat } from "../../../utils/jsonFormats";
import { saveDatosFinancieros } from "../../../services/orquestadorServices";
import {
  UPDATE_CONTACT_AVALISTA,
  UPDATE_FINANCIAL_AVALISTA,
  UPDATE_FINANCIAL_LIDER,
} from "../../../reducers/reducerSolicitud";
import Loading from "../../atoms/loading/loading";

type TFields = {
  ingreso: number;
  otroIngresoLaboral: number;
  descripcion: string;
  otroIngresoNoLaboral: number;
  cuotaDeducidaNomina: number;
  cuotaNoDeducidaNomina: number;
  valorArriendo: number;
  otroEgreso: number;
};

const InformacionFinanciera = (props: any) => {
  const { setCurrentTab, stateSolicitud, dispatchSolicitud, isRadication } =
    useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [currentFunction, setCurrentFunction] = useState(
    () => () => console.log("defecto")
  );
  const [loading, setLoading] = useState(false);

  const CloseModal = () => {
    setOpenModal(!openModal);
  };

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<TFields>({
    defaultValues: {
      ingreso: 0,
      descripcion: "",
      otroIngresoLaboral: 0,
      otroIngresoNoLaboral: 0,
      cuotaDeducidaNomina: 0,
      cuotaNoDeducidaNomina: 0,
      valorArriendo: 0,
      otroEgreso: 0,
    },
  });
  let watchAllFields = watch();

  useEffect(() => {
    HandleDataFinancial();
  }, []);

  useEffect(() => {
    if (watchAllFields.otroIngresoLaboral <= 0) {
      setValue("descripcion", "");
    }
  }, [watchAllFields.otroIngresoLaboral]);

  useEffect(() => {
    HandleDataFinancial();
  }, [stateSolicitud.solicitanteLider.stateSolicitante]);

  const HandleDataFinancial = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      setValue(
        "ingreso",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.ingreso || 0
      );

      setValue(
        "otroIngresoLaboral",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.otroIngresoLaboral || 0
      );

      setValue(
        "otroIngresoNoLaboral",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.otroIngresoNoLaboral || 0
      );

      setValue(
        "cuotaDeducidaNomina",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.cuotaDeducidaNomina || 0
      );

      setValue(
        "cuotaNoDeducidaNomina",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.cuotaNoDeducidaNomina || 0
      );

      setValue(
        "valorArriendo",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.valorArriendo || 0
      );

      setValue(
        "otroEgreso",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.otroEgreso || 0
      );

      setValue(
        "descripcion",
        stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionFinanciera?.descripcion || ""
      );
    } else {
      setValue(
        "ingreso",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.ingreso || 0
      );

      setValue(
        "otroIngresoLaboral",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.otroIngresoLaboral || 0
      );

      setValue(
        "otroIngresoNoLaboral",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.otroIngresoNoLaboral || 0
      );

      setValue(
        "cuotaDeducidaNomina",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.cuotaDeducidaNomina || 0
      );

      setValue(
        "cuotaNoDeducidaNomina",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.cuotaNoDeducidaNomina || 0
      );

      setValue(
        "valorArriendo",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.valorArriendo || 0
      );

      setValue(
        "otroEgreso",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.otroEgreso || 0
      );

      setValue(
        "descripcion",
        stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          ?.descripcion || ""
      );
    }
  };

  const checkValidityModal = async (
    fields: Array<keyof TFields>,
    next: () => void
  ) => {
    let isFilled = await trigger(fields);
    if (isFilled) goNext(next);
  };
  const checkValidityModalNextTab = async (
    fields: Array<keyof TFields>,
    next: () => void
  ) => {
    let isFilled = await trigger(fields);
    if (isFilled) goNextTab();
  };

  const goNextTab = () => {
    setCurrentFunction(() => setCurrentTab);
    CloseModal();
  };

  const goNext = (next: () => void) => {
    setCurrentFunction(() => next);
    CloseModal();
  };

  const sendPayloadIngreso = () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      dispatchSolicitud({
        type: UPDATE_FINANCIAL_AVALISTA,
        payload: watchAllFields,
      });
    } else {
      dispatchSolicitud({
        type: UPDATE_FINANCIAL_LIDER,
        payload: watchAllFields,
      });
    }
  };
  const sendPayload = async () => {
    if (stateSolicitud.solicitanteLider.stateSolicitante) {
      await dispatchSolicitud({
        type: UPDATE_FINANCIAL_AVALISTA,
        payload: watchAllFields,
      });
    } else {
      await dispatchSolicitud({
        type: UPDATE_FINANCIAL_LIDER,
        payload: watchAllFields,
      });
    }

    if (isRadication) {
      setLoading(true);
      sendPayloadIngreso();
      let indexSolicitante =
        stateSolicitud.solicitanteLider.stateSolicitante || 0;
      try {
        if (indexSolicitante === 0) {
          await saveDatosFinancieros(
            stateSolicitud.solicitanteLider.solicitante.informacionFinanciera
          );
        } else {
          await saveDatosFinancieros(
            stateSolicitud.avalistas[indexSolicitante - 1].solicitante
              .informacionFinanciera
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      if (indexSolicitante === 0) {
        setCurrentTab("informacionVivienda");
      } else {
        setCurrentTab("referencia");
      }
    } else {
      setCurrentTab("terminosCondiciones");
    }
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      <Wizard>
        <WizardTab
          flagButtonPrimary={true}
          onNext={(next: () => void) =>
            checkValidityModal(["ingreso", "otroIngresoLaboral"], next)
          }
        >
          <Modal open={openModal} handleClose={() => CloseModal()}>
            <FlexDivUI alignContent="center" flexDirection="column">
              <TitleUI align="center">El total de tus ingresos es:</TitleUI>

              <div className="o-modal-content">
                <div className="o-modal-content-icon-text">
                  <img src={iconIngreso} alt="IconoIngresos" />
                  <div className="o-modal-content-icon-text-desc">
                    <p className="o-modal-content-icon-text-desc-text o-moneytext">{`$ ${numFormat(
                      watchAllFields.ingreso + watchAllFields.otroIngresoLaboral
                    )}`}</p>
                  </div>
                </div>
              </div>
              <div className="o-modal-buttondivs">
                <Button
                  text={"DEBO CORREGIR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    CloseModal();
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  text={"CONTINUAR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    currentFunction();
                    sendPayloadIngreso();
                    CloseModal();
                  }}
                />
              </div>
            </FlexDivUI>
          </Modal>
          <TitleUI as="h1" color="dark" align="center">
            Información financiera de ingresos
          </TitleUI>

          <div className="o-informacionFinanciera">
            <div className="o-informacionFinanciera__Form">
              <div className="o-informacionFinanciera__Form__Label">
                <label>¿Cuál es tu ingreso básico?</label>
              </div>
              <div className="o-informacionFinanciera__Form__Input">
                <Input
                  register={register("ingreso", {
                    required: "Campo requerido",
                  })}
                  error={errors.ingreso?.message || ""}
                  type={"money"}
                  placeholder={"Agrega la cifra"}
                  className="o-Informacionfinanciera-input"
                  setValue={setValue}
                  value={
                    watchAllFields.ingreso != 0 ? watchAllFields.ingreso : ""
                  }
                />
              </div>
            </div>

            <div className="o-informacionFinanciera__Form">
              <div className="o-informacionFinanciera__Form__Label">
                <label>¿Tienes otros ingresos demostrables?</label>
              </div>
              <div className="o-informacionFinanciera__Form__Input">
                <Input
                  register={register("otroIngresoLaboral", {
                    required: "Campo requerido",
                  })}
                  error={errors.otroIngresoLaboral?.message || ""}
                  type={"money"}
                  placeholder={"Agrega la cifra"}
                  className="o-Informacionfinanciera-input"
                  setValue={setValue}
                  value={
                    watchAllFields.otroIngresoLaboral != 0
                      ? watchAllFields.otroIngresoLaboral
                      : ""
                  }
                />
              </div>
            </div>

            {watchAllFields.otroIngresoLaboral > 0 && (
              <div className="o-informacionFinanciera__Form">
                <div className="o-informacionFinanciera__Form__Label">
                  <label>Descripción de ingresos demostrables</label>
                </div>
                <div className="o-informacionFinanciera__Form__Input">
                  <Input
                    register={register("descripcion", {})}
                    error={errors.descripcion?.message || ""}
                    type={"text"}
                    placeholder={"Agrega la cifra"}
                    className="o-Informacionfinanciera-input"
                    value={watchAllFields.descripcion}
                  />
                </div>
              </div>
            )}
          </div>
        </WizardTab>
        <WizardTab
          onNext={(next: () => void) =>
            checkValidityModalNextTab(
              ["cuotaDeducidaNomina", "cuotaNoDeducidaNomina", "otroEgreso"],
              next
            )
          }
        >
          <Modal open={openModal} handleClose={() => CloseModal()}>
            <FlexDivUI alignContent="center" flexDirection="column">
              <TitleUI align="center">El total de tus gastos es:</TitleUI>

              <div className="o-modal-content">
                <div className="o-modal-content-icon-text">
                  <img src={iconIngreso} alt="IconoIngresos" />
                  <div className="o-modal-content-icon-text-desc">
                    <p className="o-modal-content-icon-text-desc-text o-moneytext">{`$ ${numFormat(
                      watchAllFields.cuotaDeducidaNomina +
                        watchAllFields.cuotaNoDeducidaNomina +
                        watchAllFields.otroEgreso
                    )}`}</p>
                  </div>
                </div>
              </div>
              <div className="o-modal-buttondivs">
                <Button
                  text={"DEBO CORREGIR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    CloseModal();
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  text={"CONTINUAR"}
                  type="button"
                  classname=""
                  gtmMark="secondary"
                  isDisabled={false}
                  onClickAction={() => {
                    sendPayload();
                    CloseModal();
                  }}
                />
              </div>
            </FlexDivUI>
          </Modal>

          <TitleUI as="h1" color="dark" align="center">
            Información financiera de gastos
          </TitleUI>

          <div className="o-informacionFinancieraRow">
            <div className="o-informacionFinancieraRow__Wisard">
              <div className="o-informacionFinancieraRow__Wisard__Container">
                <div className="o-informacionFinancieraRow__Wisard__Container__Form">
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Label">
                    <label>
                      ¿Cuál es el valor de tus descuentos por nómina?
                    </label>
                  </div>
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Input">
                    <Input
                      register={register("cuotaDeducidaNomina", {
                        required: "Campo requerido",
                      })}
                      error={errors.cuotaDeducidaNomina?.message || ""}
                      type={"money"}
                      placeholder={"Agrega la cifra"}
                      className="o-Informacionfinanciera-input"
                      setValue={setValue}
                      value={
                        watchAllFields.cuotaDeducidaNomina != 0
                          ? watchAllFields.cuotaDeducidaNomina
                          : ""
                      }
                    />
                  </div>
                </div>

                <div className="o-informacionFinancieraRow__Wisard__Container__Form">
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Label">
                    <label>
                      ¿Cuál es el valor de los créditos que no te descuentan por
                      nómina?
                    </label>
                  </div>
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Input">
                    <Input
                      register={register("cuotaNoDeducidaNomina", {
                        required: "Campo requerido",
                      })}
                      error={errors.cuotaNoDeducidaNomina?.message || ""}
                      type={"money"}
                      placeholder={"Agrega la cifra"}
                      className="o-Informacionfinanciera-input"
                      setValue={setValue}
                      value={
                        watchAllFields.cuotaNoDeducidaNomina != 0
                          ? watchAllFields.cuotaNoDeducidaNomina
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="o-informacionFinancieraRow__Wisard__Container">
                <div className="o-informacionFinancieraRow__Wisard__Container__Form">
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Label">
                    <label>¿Cuál es el valor de tus otros gastos?</label>
                  </div>
                  <div className="o-informacionFinancieraRow__Wisard__Container__Form__Input">
                    <Input
                      register={register("otroEgreso", {
                        required: "Campo requerido",
                      })}
                      error={errors.otroEgreso?.message || ""}
                      type={"money"}
                      placeholder={"Agrega la cifra"}
                      className="o-Informacionfinanciera-input"
                      setValue={setValue}
                      value={
                        watchAllFields.otroEgreso != 0
                          ? watchAllFields.otroEgreso
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WizardTab>
      </Wizard>
    </WrapperContent>
  );
};
export default InformacionFinanciera;
