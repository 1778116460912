import React, { useEffect, useState } from 'react'
import { getValidacionesById } from '../../../../services/orquestadorServices'

import './datosAfiliacion.scss'

const DatosAfiliacion = () => {
  const [dataValidaciones, setDataValidaciones] = useState<any>([])

  useEffect(() => {
    Promise.all([getValidacionesById(localStorage.getItem('solicitudID'))])
      .then((response) => {
        setDataValidaciones(response[0].data.result)
      })
      .catch((error) => console.error(error))
  }, [])

  const headers = [
    {
      title: 'Requisito',
      value: 'requisito',
    },
    {
      title: 'Preaprobado',
      value: 'preaprobado',
    },
    {
      title: 'Radicación',
      value: 'radicacion',
    },
    {
      title: 'Ratificación aprobado',
      value: 'aprobado',
    },
    {
      title: 'Análisis de garantía',
      value: 'garantia',
    },
  ]

  return (
    <>
      {dataValidaciones.map((element: any, index: number) => {
        return (
          <div className="o-DatosAfiliacion" key={index}>
            <div className="o-DatosAfiliacion__content">
              <div className="o-DatosAfiliacion__content__nombre">
                {element.nombre}
              </div>
              <div className={element.tipoSolicitante === "TITULAR" ? "o-DatosAfiliacion__content__box" : "o-DatosAfiliacion__content__box__solicitante"}>
                {element.tipoSolicitante === "TITULAR" ? "Titular" : `Solicitante Adicional ${index}`}
              </div>
            </div>
            <table className="m-table" cellSpacing="0">
              <thead className="m-table__head">
                <tr>
                  {headers.map((header) => (
                    <th className="m-table__body__th">{header.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="m-table__body">
                {element.requisitos.map((element2: any, index2: number) => {
                  return (
                    <tr key={index2}>
                      <td className="m-table__body__td">
                        <div>{element2.requisito}</div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.preaprobado.valido}`}>
                          {element2.preaprobado.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.radicacion.valido}`}>
                          {element2.radicacion.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div
                          className={`${element2.ratificacionAprobado.valido}`}
                        >
                          {element2.ratificacionAprobado.informacion}
                        </div>
                      </td>
                      <td className="m-table__body__td">
                        <div className={`${element2.analisisGarantia.valido}`}>
                          {element2.analisisGarantia.informacion}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
    </>
  )
}

export default DatosAfiliacion
