import React, { useCallback, useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import arrow from '../../../assets/icons/arrow_down.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material'
import { Controller } from "react-hook-form";
import './input.scss'
import moment from 'moment';

interface InputProps {
    title?: string;
    register?: any;
    error: string | undefined;
    placeholder?: string;
    type: "number" | "text" | "email" | "password" | "select" | "money" | "radio" | "date" | "textarea";
    options?: Array<{ value: any, label: string }>;
    value?: any;
    className?: string;
    defaultValue?: any;
    setFormIdData?: any;
    name?: string;
    controller?: any;
    setValue?: any;
    onClick?: () => void;
    onChange?: any;
    rules?: any;
    ref?: any;
    height? : string;
    disabled?: boolean;
    hiddenPlaceholder?: boolean;
    future?:boolean
}

const Input = ({ title = "", height, register, error = "", placeholder, type, options = [], value, className = "", defaultValue, setFormIdData = null, name = "", onClick, setValue, controller, rules, onChange, disabled,hiddenPlaceholder, future }: InputProps) => {
    
    
    const formatter = useMemo(() => {
        return new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'COP',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    }, []);

    switch (type) {

        case "text":
        case "number":
        case "password":
        case "email":
            
            if (disabled) {
                return (
                    <div className={`a-input ${className}`}>
                        {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                        <input
                            className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                            type={type}
                            placeholder={placeholder || ""}
                            value={value}
                            {...register}
                            disabled
                        />
                        {error && <span className='a-input__error'>{error}</span>}
                    </div>
                );
            }
            else{
                
                return (
                    <div className={`a-input ${className}`}>
                        {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                        <input
                            className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                            type={type}
                            placeholder={placeholder || ""}
                            defaultValue={value}
                            {...register}
                        />
                        {error && <span className='a-input__error'>{error}</span>}
                    </div>
                );
            }
            
        case "select":
            return (
                <div className={`a-input ${className}`}>
                    {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`} htmlFor=''>{title}</label>}
                    <div className='a-input__input-select-container'>
                        <select
                            className={`a-input__input-select-container__select ${error ? "a-input__input--error" : ""}`}
                            style={{ color: value ? '#303030' : 'gray' }}
                            type={type}
                            placeholder={placeholder || ""}
                            onChange={onChange ? ({target}) => {onChange(target.value)} : null}

                            {...register}
                            defaultValue={value}
                            // value={value}
                            disabled={disabled ? true : false}
                        >
                            {hiddenPlaceholder ? "" :  <option value="">{placeholder || 'Selecciona'}</option>}
                           
                            {options.map((option) => {
                                if (value === option.value) {
                                    return <option key={option.label + option.value} value={option.value} selected>{option.label}</option>
                                }
                                else{
                                    return <option key={option.label + option.value} value={option.value}>{option.label}</option>
                                }
                            }
                            )}
                        </select>
                        <img
                            src={arrow}
                            alt="Dropdown menu"
                            className='a-input__input-select-container__img'
                        />
                    </div>
                    {error && <span className='a-input__error'>{error}</span>}
                </div>
            )
        case "money":
            return (
                <div className={`a-input ${className}`}>
                    {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                    {/* <input
                        className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                        type="text"
                        placeholder={placeholder || ""}
                        value={formatter.format(parseInt(value))}
                        {...register}
                    /> */}
                    <NumberFormat
                        defaultValue={value ? value : ''}
                        // disabled={disabled}
                        className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                        inputMode="numeric"
                        thousandSeparator={true}
                        placeholder={placeholder || ""}
                        prefix={'$ '}
                        isNumericString={true}
                        onValueChange={(values) => {
                            
                            const { formattedValue, value } = values;
                            console.log(value);
                            console.log(formattedValue);

                            if (setFormIdData) {
                                setFormIdData(register.name, parseInt(value));
                            }
                            if (setValue && register) {
                                setValue(register.name, parseInt(value))
                            }else if (setValue) {
                                setValue(Number(value))
                            }
                        }}
                        value={value}
                        {...register}
                        disabled={disabled ? true : false}
                    />
                    {error && <span className='a-input__error'>{error}</span>}
                </div>
            )
        case "date":
            return (
                <div className={`a-input ${className}`}>
                    {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                    <Controller
                        render={({ field: { onChange, value }, ...rest }) =>
                            <DatePicker
                                {...rest}
                                disableFuture={!future}
                                label="DD/MM/AAAA"
                                views={['year', 'month', 'day']}
                                inputFormat="DD/MM/YYYY"
                                renderInput={({ inputRef, inputProps, InputProps }) => 
                                    <div className="input-container">
                                        <input ref={inputRef} {...inputProps} className={`input-container__input ${error ? 'input-container__input--error' : ''}`}/>
                                        {InputProps?.endAdornment}
                                    </div>
                                }
                                openTo="year"
                                value={value == ""? null: value}
                                onChange={onChange}
                            />
                        }
                        control={controller}
                        name={name}
                        rules={rules}
                    />
                    {error && <span className='a-input__error'>{error}</span>}
                </div>
            )
        case "radio":
            return (<div className={`a-input ${className}`}>

                <input
                    className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                    type={type}
                    placeholder={placeholder || ""}
                    {...register}
                    name={name}
                    value={value}
                    onClick={onClick}
                />
                {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                {error && <span className='a-input__error'>{error}</span>}
            </div>);
        case "textarea":
                return (
                    <div className={`a-input ${className}`}>
                        {title && <label className={`a-input__title ${error ? "a-input__title--error" : ""}`}>{title}</label>}
                        <textarea 
                            className={`a-input__input ${error ? "a-input__input--error" : ""}`}
                            type={type}
                            placeholder={placeholder || ""}
                            {...register}
                            value={value}
                            style={{height : height}}
                        >
                        </textarea>
                        {error && <span className='a-input__error'>{error}</span>}
                    </div>
                );
    }
}
export default Input