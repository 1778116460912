import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AppContext } from "../../../contexts/appContext";

import TitleUI from "../../atoms/title-ui/TitleUI";
import Input from "../../atoms/input/input";
import WrapperContent from "../../atoms/wrapper-content/WrapperContent";
import CheckoxContent from "../../molecules/checkbox-content/checkboxContent";
import Wizard from "../wizard/Wizard";
import WizardTab from "../wizard/WizardTab";

import empleadoIcon from "../../../assets/icons/actividadEconomica/icon_empleado.svg";
import pensionadoIcon from "../../../assets/icons/actividadEconomica/icon_pensionado.svg";
import independienteIcon from "../../../assets/icons/actividadEconomica/icon_independiente.svg";

import completeSteps from "../../../assets/icons/vamosbiencomplete.svg";

import {
  UPDATE_ACTIVITY_AVALISTA,
  UPDATE_ACTIVITY_LIDER,
} from "../../../reducers/reducerSolicitud";

import "./actividadEconomica.scss";

import {
  getOcupation,
  getTypeContract,
} from "../../../services/actividadEconomicaServices";
import Loading from "../../atoms/loading/loading";
import { saveEconomyActivity } from "../../../services/orquestadorServices";

type TFields = {
  actividadEconomica: string;
  empresa: string;
  tipoContrato: string;
  fechaTerminacionContrato: string;
  antiguedadLaboralMeses: number;
};

const ActividadEconomica = (props: any) => {
  const { setCurrentTab, stateSolicitud, dispatchSolicitud, isRadication } =
    useContext(AppContext);
  const [tipoContratoValue, setTipoContratoValue] = useState("");
  const [ocupationData, setOcupationData] = useState([]);
  const [typeContractData, setTypeContractData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dataValidator, setDataValidator] = useState({
    actividadEconomica: "",
    empresa: "",
    tipoContrato: "",
    fechaTerminacionContrato: "",
    antiguedadLaboralMeses: "",
  });

  const HandleValidity = async (
    fields: Array<keyof TFields>,
    next: () => void
  ) => {
    console.log(fields);
    console.log(watchAllFields.actividadEconomica);

    let isFilled = await trigger(fields);

    if (fields.toString() == "fechaTerminacionContrato") {
      const payload = {
        actividadEconomica: watchAllFields.actividadEconomica,
        empresa: watchAllFields.empresa,
        tipoContrato: watchAllFields.tipoContrato,
        fechaTerminacionContrato: watchAllFields.fechaTerminacionContrato,
        antiguedadLaboralMeses: 0,
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_AVALISTA,
          payload,
        });
      } else {
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_LIDER,
          payload,
        });
      }
      await saveData()
      setCurrentTab("informacionFinanciera");
    }
    if (
      fields.toString() == "tipoContrato" &&
      watchAllFields.tipoContrato != "Fijo"
    ) {
      const payload = {
        actividadEconomica: watchAllFields.actividadEconomica,
        empresa: watchAllFields.empresa,
        tipoContrato: watchAllFields.tipoContrato,
        fechaTerminacionContrato: "",
        antiguedadLaboralMeses: 0,
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_AVALISTA,
          payload,
        });
      } else {

        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_LIDER,
          payload,
        });
      }
      await saveData()
      setCurrentTab("informacionFinanciera");
    }
    if (
      fields.toString() == "empresa,antiguedadLaboralMeses" &&
      watchAllFields.actividadEconomica == "INDEPENDIENTE"
    ) {
      const payload = {
        actividadEconomica: watchAllFields.actividadEconomica,
        empresa: "",
        tipoContrato: "",
        fechaTerminacionContrato: "",
        antiguedadLaboralMeses: watchAllFields.antiguedadLaboralMeses,
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_AVALISTA,
          payload,
        });
      } else {
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_LIDER,
          payload,
        });
      }
      await saveData()
      setCurrentTab("informacionFinanciera");
    }
    if (
      fields.toString() == "actividadEconomica" &&
      watchAllFields.actividadEconomica == "PENSIONADO"
    ) {
      const payload = {
        actividadEconomica: watchAllFields.actividadEconomica,
        empresa: "",
        tipoContrato: "",
        fechaTerminacionContrato: "",
        antiguedadLaboralMeses: "",
      };
      if (stateSolicitud.solicitanteLider.stateSolicitante) {
        dispatchSolicitud({
          type: UPDATE_ACTIVITY_AVALISTA,
          payload,
        });
      } else {
        const payload = {
          actividadEconomica: watchAllFields.actividadEconomica,
          empresa: "",
          tipoContrato: "",
          fechaTerminacionContrato: "",
          antiguedadLaboralMeses: 0,
        };
        await dispatchSolicitud({
          type: UPDATE_ACTIVITY_LIDER,
          payload,
        });
      }
      await saveData()
      setCurrentTab("informacionFinanciera");
    }

    if (isFilled) next();
  };

  const saveData = async () => {
    if (isRadication) {
      setLoading(true);
      let indexSolicitante =
        stateSolicitud.solicitanteLider.stateSolicitante || 0;
      let dataSend = {}
      try {
        if (indexSolicitante === 0) { 
          dataSend = {
            ...stateSolicitud.solicitanteLider.solicitante.informacionActividadEconomica,
            solicitanteId: stateSolicitud.solicitanteLider.solicitante.id
          }
          await saveEconomyActivity(
            dataSend
          );
        }else {
          dataSend = {
            ...stateSolicitud.avalistas[indexSolicitante - 1].solicitante.informacionActividadEconomica,
            solicitanteId: stateSolicitud.avalistas[indexSolicitante - 1].solicitante.id
          }
          await saveEconomyActivity(
            dataSend
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  const {
    register,
    watch,
    trigger,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      actividadEconomica: "",
      empresa: "",
      tipoContrato: "",
      fechaTerminacionContrato: "",
      antiguedadLaboralMeses: "",
    },
  });
  let watchAllFields = watch();

  const HandleState = () => {
    const actividadEconomica: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionActividadEconomica?.actividadEconomica || ""
      : stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.actividadEconomica || "";
    const empresa: string = stateSolicitud.solicitanteLider.stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionActividadEconomica?.empresa || ""
      : stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.empresa || "";

    const tipoContrato: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionActividadEconomica?.tipoContrato || ""
      : stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.tipoContrato || "";

    const fechaTerminacionContrato: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionActividadEconomica?.fechaTerminacionContrato ||
        ""
      : stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.fechaTerminacionContrato || "";

    const antiguedadLaboralMeses: number = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.informacionActividadEconomica?.antiguedadLaboralMeses || 0
      : stateSolicitud.solicitanteLider.solicitante
          .informacionActividadEconomica?.antiguedadLaboralMeses || 0;

    setValue("actividadEconomica", actividadEconomica || "");
    setValue("empresa", empresa || "");
    setValue("tipoContrato", tipoContrato || "");
    setTipoContratoValue(tipoContrato);
    setValue("fechaTerminacionContrato", fechaTerminacionContrato || "");

    if (antiguedadLaboralMeses) {
      setValue(
        "antiguedadLaboralMeses",
        antiguedadLaboralMeses.toString() || ""
      );
    }
  };

  const HandleStateValidator = () => {
    const actividadEconomica: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.viabilidadEconomica?.actividadEconomica || ""
      : stateSolicitud.solicitanteLider.solicitante.viabilidadEconomica
          ?.actividadEconomica || "";
    const empresa: string = stateSolicitud.solicitanteLider.stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.viabilidadEconomica?.empresa || ""
      : stateSolicitud.solicitanteLider.solicitante.viabilidadEconomica
          ?.empresa || "";

    const tipoContrato: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.viabilidadEconomica?.tipoContrato || ""
      : stateSolicitud.solicitanteLider.solicitante.viabilidadEconomica
          ?.tipoContrato || "";

    const fechaTerminacionContrato: string = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.viabilidadEconomica?.fechaTerminacionContrato || ""
      : stateSolicitud.solicitanteLider.solicitante.viabilidadEconomica
          ?.fechaTerminacionContrato || "";

    const antiguedadLaboralMeses: number = stateSolicitud.solicitanteLider
      .stateSolicitante
      ? stateSolicitud.avalistas[
          stateSolicitud.solicitanteLider.stateSolicitante - 1
        ].solicitante.viabilidadEconomica?.antiguedadLaboralMeses || 0
      : stateSolicitud.solicitanteLider.solicitante.viabilidadEconomica
          ?.antiguedadLaboralMeses || 0;

    setDataValidator({
      actividadEconomica: actividadEconomica,
      empresa: empresa,
      tipoContrato: tipoContrato,
      fechaTerminacionContrato: fechaTerminacionContrato,
      antiguedadLaboralMeses: antiguedadLaboralMeses.toString(),
    });
  };

  const HandleTipoContrato = () => {
    setValue("tipoContrato", tipoContratoValue || "");
  };
  const HandleData = async () => {
    setLoading(true);

    /* Con Backend */

    // await getOcupation().then((res) => {
    //   const cardOptions = [
    //     {
    //       title: "Empleado",
    //       value: "EMPLEADO",
    //       img: empleadoIcon,
    //     },
    //     {
    //       title: "Independiente",
    //       value: "INDEPENDIENTE",
    //       img: pensionadoIcon,
    //     },
    //     {
    //       title: "Pensionado",
    //       value: "PENSIONADO",
    //       img: independienteIcon,
    //     },
    //   ];
    //   let Ocupation: any = [];
    //   if (res.status === 200) {
    //     cardOptions.forEach((element) => {
    //       res.data.result.forEach((element2: any) => {
    //         if (element.title === element2.nombre) {
    //           Ocupation.push({
    //             value: element2.nombre.toUpperCase(),
    //             title: element2.nombre,
    //             img: element.img,
    //             id: element2.id,
    //             codigo: element2.codigo,
    //             lineaCreditoId: element2.lineaCreditoId,
    //           });
    //         }
    //       });
    //     });
    //   }
    //   setOcupationData(Ocupation);
    // });

    // await getTypeContract().then((res) => {
    //   let data: any = [];
    //   if (res.status === 200) {
    //     res.data.result.forEach((element: any) => {
    //       data.push({
    //         name: element.nombre,
    //         value: element.id,
    //         codigo: element.codigo,
    //         lineaCreditoId: element.lineaCreditoId,
    //       });
    //     });
    //   }
    //   setTypeContractData(data);
    // });

    /* End Con Backend */

    /* Sin Backend */

    // await getOcupation().then((res) => {
    if (true) {
      const res = {
        status: 200,
        data: {
          error: null,
          result: [
            {
              id: 1,
              codigo: "1",
              nombre: "Empleado",
              lineaCreditoId: 1,
            },
            {
              id: 2,
              codigo: "2",
              nombre: "Pensionado",
              lineaCreditoId: 1,
            },
            {
              id: 3,
              codigo: "3",
              nombre: "Independiente",
              lineaCreditoId: 1,
            },
          ],
        },
      };
      const cardOptions = [
        {
          title: "Empleado",
          value: "EMPLEADO",
          img: empleadoIcon,
        },
        {
          title: "Independiente",
          value: "INDEPENDIENTE",
          img: pensionadoIcon,
        },
        {
          title: "Pensionado",
          value: "PENSIONADO",
          img: independienteIcon,
        },
      ];
      let Ocupation: any = [];
      if (res.status === 200) {
        cardOptions.forEach((element) => {
          res.data.result.forEach((element2: any) => {
            if (element.title === element2.nombre) {
              Ocupation.push({
                value: element2.nombre.toUpperCase(),
                title: element2.nombre,
                img: element.img,
                id: element2.id,
                codigo: element2.codigo,
                lineaCreditoId: element2.lineaCreditoId,
              });
            }
          });
        });
      }
      setOcupationData(Ocupation);
    }

    // });

    // await getTypeContract().then((res) => {
    if (true) {
      const res = {
        status: 200,
        data: {
          error: null,
          result: [
            {
              id: 1,
              codigo: "1",
              nombre: "Asociativo",
              lineaCreditoId: 1,
            },
            {
              id: 2,
              codigo: "2",
              nombre: "Fijo",
              lineaCreditoId: 1,
            },
            {
              id: 3,
              codigo: "3",
              nombre: "Indefinido",
              lineaCreditoId: 1,
            },
            {
              id: 4,
              codigo: "4",
              nombre: "Misión",
              lineaCreditoId: 1,
            },
            {
              id: 5,
              codigo: "5",
              nombre: "Obra o labor",
              lineaCreditoId: 1,
            },
          ],
        },
      };

      let data: any = [];
      if (res.status === 200) {
        res.data.result.forEach((element: any) => {
          data.push({
            name: element.nombre,
            value: element.id,
            codigo: element.codigo,
            lineaCreditoId: element.lineaCreditoId,
          });
        });
      }
      setTypeContractData(data);
    }

    // });

    /* End Sin Backend */

    setLoading(false);
  };
  useEffect(() => {
    HandleData();
    HandleState();
    HandleStateValidator();
  }, []);
  useEffect(() => {
    HandleState();
  }, [stateSolicitud.solicitanteLider.stateSolicitante]);
  useEffect(() => {
    HandleTipoContrato();
  }, [tipoContratoValue]);

  const defaultVality = (field: string) => {
    switch (field) {
      case "actividadEconomica":
        if (dataValidator.actividadEconomica != "0") {
          setValue("actividadEconomica", dataValidator.actividadEconomica);
        }
        break;
      case "empresa":
        if (dataValidator.empresa != "") {
          setValue("empresa", dataValidator.empresa);
        }
        break;
      default:
        break;
    }
  };

  return (
    <WrapperContent>
      {loading && <Loading />}
      <Wizard>
        <WizardTab
          flagButtonPrimary={true}
          textButtonSecundary={"SIGUIENTE"}
          onNext={(next: () => void) =>
            HandleValidity(["actividadEconomica"], next)
          }
        >
          <CheckoxContent
            title={"¿Cuál es tu ocupación?"}
            HandleRegister={register("actividadEconomica", {
              required: "Campo requerido",
              onChange: () => defaultVality("actividadEconomica"),
            })}
            options={ocupationData}
            valueOption={watchAllFields.actividadEconomica}
          />
        </WizardTab>
        <WizardTab
          textButtonSecundary={"SIGUIENTE"}
          onNext={(next: () => void) =>
            HandleValidity(["empresa", "antiguedadLaboralMeses"], next)
          }
        >
          {watchAllFields.actividadEconomica === "EMPLEADO" && (
            <form className="o-actividadEconomica__Form">
              <Input
                title="¿Dónde trabajas?"
                register={register("empresa", {
                  required: "Campo requerido",
                  onChange: () => defaultVality("empresa"),
                })}
                // register={register("empresa")}
                disabled={isRadication ? true : false}
                error={errors?.empresa?.message}
                type={"text"}
                placeholder="Agrega el nombre de la empresa"
                className="o-actividadEconomica__Form__Input"
              />
            </form>
          )}
          {watchAllFields.actividadEconomica === "INDEPENDIENTE" && (
            <form className="o-actividadEconomica__Form">
              <Input
                title="¿Tiempo de actividad economica?"
                register={register("antiguedadLaboralMeses", {
                  required: "* Campo obligatorio",
                })}
                error={errors?.antiguedadLaboralMeses?.message}
                placeholder="Ingresa la cantidad en meses"
                type={"number"}
                className="o-actividadEconomica__Form__Input"
              />
            </form>
          )}
        </WizardTab>
        <WizardTab
          textButtonSecundary={"SIGUIENTE"}
          onNext={(next: () => void) => HandleValidity(["tipoContrato"], next)}
        >
          {watchAllFields.actividadEconomica === "EMPLEADO" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TitleUI as="h1" color="dark" align="center">
                ¿Cuál es tu tipo de contrato?
              </TitleUI>
              {typeContractData.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "600px",
                        paddingLeft: "90px",
                      }}
                      key={index}
                    >
                      <Input
                        title={item.name}
                        register={register("tipoContrato", {
                          required: "Campo requerido",
                        })}
                        error={errors?.tipoContrato?.message}
                        type={"radio"}
                        placeholder="Agrega el nombre de la empresa"
                        value={item.name}
                        className={`o-actividadEconomica__Form__radio ${
                          watchAllFields.tipoContrato === item.name
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => setTipoContratoValue(item.name)}
                      />
                      {index < typeContractData.length / 2 && (
                        <Input
                          title={typeContractData[index + 1].name}
                          register={register("tipoContrato", {
                            required: "Campo requerido",
                          })}
                          error={errors?.tipoContrato?.message}
                          type={"radio"}
                          placeholder="Agrega el nombre de la empresa"
                          value={typeContractData[index + 1].name}
                          className={`o-actividadEconomica__Form__radio ${
                            watchAllFields.tipoContrato ===
                            typeContractData[index + 1].name
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            setTipoContratoValue(
                              typeContractData[index + 1].name
                            )
                          }
                        />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </WizardTab>
        <WizardTab
          textButtonSecundary={"SIGUIENTE"}
          onNext={(next: () => void) =>
            HandleValidity(["fechaTerminacionContrato"], next)
          }
        >
          {watchAllFields.actividadEconomica === "EMPLEADO" &&
            watchAllFields.tipoContrato === "Fijo" && (
              <form className="o-actividadEconomica__Form">
                <Input
                  title="¿Cuándo termina tu contrato?"
                  error={errors?.fechaTerminacionContrato?.message}
                  type={"date"}
                  future={true}
                  controller={control}
                  rules={{
                    required: "* Campo requerido",
                  }}
                  name="fechaTerminacionContrato"
                  className="o-actividadEconomica__Form__Input"
                />
              </form>
            )}
        </WizardTab>
      </Wizard>
    </WrapperContent>
  );
};

export default ActividadEconomica;
