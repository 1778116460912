
import FechaSolicitudIcon from "../../../assets/icons/Datos de solicitud/Fecha_solcitud.svg"
import UltimoEstadoSolicitudIcon from "../../../assets/icons/Datos de solicitud/Ultimo_estado_solicitud.svg"
import EtapaIcon from "../../../assets/icons/Datos de solicitud/Etapa.svg"
import EstadoIcon from "../../../assets/icons/Datos de solicitud/Estado.svg"
import NumeroSolicitudIcon from "../../../assets/icons/Datos de solicitud/Numero_solicitud.svg"
import NombreTitularIcon from "../../../assets/icons/Datos de solicitud/Nombre_titular.svg"
import EmpleadoResponsableIcon from "../../../assets/icons/Datos de solicitud/Empleado_responsable.svg"
import LineaCreditoIcon from "../../../assets/icons/Datos de solicitud/money-dollar-circle-line.svg"
import LocationIcon from "../../../assets/icons/Datos de solicitud/map-pin.svg"
import './datosSolicitud.scss'
import { useEffect, useState } from "react"
import Loading from "../../atoms/loading/loading";
import { getSolicitudById } from "../../../services/orquestadorServices"

const DatosSolicitud = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [datosSolicitud, setDatosSolicitud] = useState<any>([])

    const changeDateFormat = (date: string) => {
      if (!date) return ""
        let otherDate = date.split('T')
        
        let newDate = otherDate[0].split('-')
        let hour = otherDate[1].split('.')
    
        if (newDate[1] === '01') {
          newDate[1] = 'Enero'
        } else if (newDate[1] === '02') {
          newDate[1] = 'Febrero'
        } else if (newDate[1] === '03') {
          newDate[1] = 'Marzo'
        } else if (newDate[1] === '04') {
          newDate[1] = 'Abril'
        } else if (newDate[1] === '05') {
          newDate[1] = 'Mayo'
        } else if (newDate[1] === '06') {
          newDate[1] = 'Junio'
        } else if (newDate[1] === '07') {
          newDate[1] = 'Julio'
        } else if (newDate[1] === '08') {
          newDate[1] = 'Agosto'
        } else if (newDate[1] === '09') {
          newDate[1] = 'Septiembre'
        } else if (newDate[1] === '10') {
          newDate[1] = 'Octubre'
        } else if (newDate[1] === '11') {
          newDate[1] = 'Noviembre'
        } else if (newDate[1] === '12') {
          newDate[1] = 'Diciembre'
        }
        return newDate[2] + ' de ' + newDate[1] + ' ' + newDate[0] + ' ' + hour[0]
      }
    
    useEffect(() => {
        
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
        Promise.all([getSolicitudById(localStorage.getItem("solicitudID"))])
          .then((response) => {
            setDatosSolicitud(response[0].data.result);
            console.log(datosSolicitud);
        })
        .catch((error) => console.error(error));
    }, []);

    


    const listaItems = [
        {
            title: "Línea de crédito",
            icon: LineaCreditoIcon,
            description: datosSolicitud.lineaCredito
        },
        {
            title: "Fecha de solicitud",
            icon: FechaSolicitudIcon,
            description: changeDateFormat(datosSolicitud.fechaSolicitud)
        },
        {
            title: "Último estado solicitud",
            icon: UltimoEstadoSolicitudIcon,
            description: changeDateFormat(datosSolicitud.fechaUltimoEstado)
        },
        {
            title: "Número de solicitud",
            icon: NumeroSolicitudIcon,
            description: datosSolicitud.numeroSolicitud
        },
        {
            title: "Etapa",
            icon: EtapaIcon,
            description: datosSolicitud.etapa
        },
        {
            title: "Estado",
            icon: EstadoIcon,
            description: datosSolicitud.estado
        },
        {
            title: "Nombre del Titular 1",
            icon: NombreTitularIcon,
            description: datosSolicitud.titular
        },
        {
            title: "Empleado responsable",
            icon: EmpleadoResponsableIcon,
            description: datosSolicitud.empleadoResponsable
        },
        {
            title: "Sede de orígen",
            icon: LocationIcon,
            description: datosSolicitud.sedeOrigen
        }

    ]

    return (
        <div className="o-datos-solicitud">
            {loading && <Loading />}
            <h1 className="o-datos-solicitud-title">Datos de la Solicitud</h1>
            <div className="gray-line"/>
            <div className="o-datos-solicitud-container">
            {listaItems.map((elem, idx) => (
                <div className="o-datos-solicitud-container-item">
                    <img src={elem.icon} className='o-datos-solicitud-container-item-icon' alt={elem.title}/>
                    <div className="o-datos-solicitud-container-item-content">
                        <h3>{elem.title}</h3>
                        <p>{elem.description}</p>
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default DatosSolicitud