import React from "react";

interface MenuContentProps {
  children: any;
  value: number;
  index: number;
}

const MenuContent = (props: any) => {
  return props.children;
};

export default MenuContent;
