import React, { useState } from 'react'
import './cardButton.scss'

interface CardButtonProps {
    title: string
    img: any;
    // onClick: any;
    isSelected: boolean;
    disabled?: boolean;
    value: any;
    register: any;
    checked?: any
}

const CardButton = (props: CardButtonProps) => {
    const { title, disabled = false, isSelected, value, img, checked } = props;
    const [onCHecked, setOnCHecked] = useState(checked);    
    
    
    return (
        <div
            className={`a-cardButton ${isSelected ? "a-cardButton--selected" : ""}`}
        >
            <input
                className={`a-cardButton__input`}
                key={value}
                id={value}
                value={value}
                type="radio"
                // onChange={() => setOnCHecked(value)}
                {...props.register}
                // onChange={() => setOnCHecked(value)}
                defaultChecked={checked == value}
            >
            </input>
            <img
                src={img}
                alt={title}
                className="a-cardButton__image"
            />
            <section className="a-cardButton__title">
                <h3>{title}</h3>
            </section>
        </div>
    );
}

export default CardButton