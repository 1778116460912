import React from "react";
import Modal from "../../atoms/modal/modal";
import FlexDivUI from "../../atoms/flexible-div-ui/FlexDivUI";
import TitleUI from "../../atoms/title-ui/TitleUI";
import Button from "../../atoms/button/button";
import { TDataModal } from "../../organisms/procesar-credito/procesarCredito";
import iconLike from "../../../assets/icons/icono_like.svg";
import iconWarning from "../../../assets/icons/icono_warning.svg";

type TProps = {
  dataModal?: TDataModal;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalStudy = ({ dataModal, showModal, setShowModal }: TProps) => {
  return (
    <Modal open={showModal} handleClose={() => setShowModal(false)}>
      <FlexDivUI alignContent="center" flexDirection="column">
        <img
          src={dataModal?.type === "success" ? iconLike : iconWarning}
          alt={dataModal?.type}
        />
        <TitleUI align="center">{dataModal?.title}</TitleUI>
        <TitleUI color="dark" as="h3" align="center">
          {dataModal?.text}
        </TitleUI>
        <Button
          text={dataModal?.type === "success" ? "Finalizar" : "Aceptar"}
          type="button"
          classname=""
          gtmMark="secondary"
          isDisabled={false}
          onClickAction={() => {
            setShowModal(false);
          }}
        />
      </FlexDivUI>
    </Modal>
  );
};

export default ModalStudy;
