import axios from "./axiosConfig";

export const simulationSegunValor = (payload: {
  valorSolicitado: number;
  categoria: string;
  plazoNumeroCuotas: number;
  numeroSolicitantes: number;
}) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_SIMULADOR_API}/calculo/SegunValor`,
    data: payload,
    headers: {},
  };
  console.log('payload',payload)
  return axios(config);
};
export const simulationSegunValue = (payload: any) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_SIMULADOR_API}/CreditoHipotecario/calculate/value`,
    data: payload,
    headers: {},
  };
  console.log('payload',payload)
  return axios(config);
};

export const simulationSegunCuota = (payload: {
  valorCuota: number;
  categoria: string;
  plazoNumeroCuotas: number;
  numeroSolicitantes: number;
}) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_SIMULADOR_API}/calculo/SegunCuota`,
    data: payload,
    headers: {},
  };
  return axios(config);
};

export const simulationSegunQuota = (payload: any) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_SIMULADOR_API}/CreditoHipotecario/calculate/quota`,
    data: payload,
    headers: {},
  };
  return axios(config);
};

export const saveSimulation = (payload: {
  solicitantes: Array<{ tipoSolicitante: number; solicitante: string }>;
  base: number;
  valorVivienda: number;
  porcentajePrestamo: number;
  valorPrestamo: number;
  plazo: number;
  valorCuotaMensual: number;
  valorTasaInteres: number;
  valorGastosManejo: number;
  valorSeguroMensualAprox: number;
  valorCuotaSinSeguroMensual: number;
  valorPrestamoTotal: number;
  condicionTasaInteres: number;
  condicionGastosManejo: number;
  condicionBeneficioGastosManejo: number;
  condicionFactorTodoRiesgo: number;
  condicionFactorVida: number;
  usuarioId: number;
  lineaCreditoId: number;
  sedeId: number;
}) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_SIMULADOR_API}/simulation/GuardarSimulacion`,
    data: payload,
    headers: {},
  };
  return axios(config);
};

export const getSimulation = (simulationId: number) => {
  return axios.get(
    `${process.env.REACT_APP_SIMULADOR_API}/retrieve?Id=${simulationId}`
  );
};

export const updateSimulation = (
  payload: any
) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_SIMULADOR_API}/update`,
    data: payload,
    headers: {},
  };
  return axios(config);
};
