import axios from "./axiosConfig"
import { TUpdateSolicitud } from '../interfaces/solicitudInterface';

export const saveAsegurabilidad = async (payload: any) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_ORQUESTADOR_API}/EnfermedadSolicitante/`,
    headers: {},
    data: payload 
  };
  return axios(config);
}

export const saveDatosPersonales = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/DatosPersonales`,
      headers: {},
      data: payload 
    };
  return axios(config);
}

export const saveContactos = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/DatosContacto`,
      headers: {},
      data: payload 
    };
  return axios(config);
}

export const saveDatosFinancieros = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/DatosFinancieros`,
      headers: {},
      data: payload 
    };
  return axios(config);
}


export const saveSolicitantes = async (payload: any) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante`,
    headers: {},
    data: payload 
  };
return axios(config);
}

export const updateSolicitud = async (payload: TUpdateSolicitud) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitud/cambiarestado`,
    headers: {},
    data: payload 
  };
return axios(config);
}

export const saveReferencias = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/referencias`,
      headers: {},
      data: payload 
    };
  return axios(config);
}

export const saveInformacionVivienda = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/InformacionVivienda`,
      headers: {},
      data: payload 
    };
  return axios(config);
}

export const getContactInformation = async (solicitanteId: number) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/DatosContacto/${solicitanteId}`,
    headers: {},
  };
return axios(config);
}

export const getSolicitudByDocument = async (document: any) => {
  var config = {
      method: "get",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/Solicitud/ByNumeroDocumento/${document}`,
      headers: {},
    };
  return axios(config);
}

export const getSolicitudById = async (Id: any) => {
  var config = {
      method: "get",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/Solicitud/DatosSolicitud/${Id}`,
      headers: {},
    };
  return axios(config);
}

export const getValidacionesById = async (Id: any) => {
  var config = {
      method: "get",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/SolicitudValidacion/Afiliacion/BySolicitud/${Id}`,
      headers: {},
    };
  return axios(config);
}

export const getListaRestrictivaById = async (Id: any) => {
  var config = {
      method: "get",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/SolicitudValidacion/Extradata/BySolicitud/${Id}`,
      headers: {},
    };
  return axios(config);
}

export const saveEconomyActivity = async (payload: any) => {
  var config = {
      method: "put",
      url: `${process.env.REACT_APP_ORQUESTADOR_API}/solicitante/InformacionActividadEconomica`,
      headers: {},
      data: payload 
    };
  return axios(config);
}