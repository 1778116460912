import { TSimulation } from "../interfaces/solicitudInterface";

export type TViabilidadEconomica = {
  actividadEconomica?: string;
  empresa?: string;
  tipoContrato?: string;
  fechaTerminacionContrato?: string;
  antiguedadLaboralMeses?: number;
  solicitanteId?: number;
};
export type TSolicitante = {
  id?: number;
  tipoDocumentoId?: number;
  numeroDocumento?: number;
  primerNombre?: string;
  segundoNombre?: string;
  primerApellido?: string;
  segundoApellido?: string;
  fechaNacimiento?: string;
  lugarNacimiento?: string;
  fechaExpedicion?: string;
  lugarExpedicion?: string;
  estadoCivilId?: number;
  nivelEstudioId?: number;
  personasACargo?: number;
  celular?: string;
  otroNumero?: string;
  email?: string;
  action?: string;
  informacionFinanciera?: {
    ingreso?: number;
    descripcion?: string;
    otroIngresoLaboral?: number;
    otroIngresoNoLaboral?: number;
    cuotaDeducidaNomina?: number;
    cuotaNoDeducidaNomina?: number;
    valorArriendo?: number;
    otroEgreso?: number;
    solicitanteId?: number;
  };
  viabilidadEconomica?: TViabilidadEconomica;
  informacionActividadEconomica?: TViabilidadEconomica;
  informacionDomicilio?: {
    estrato?: number;
    lugarResidencia?: string;
    tipoVivienda?: string;
    tiempoResidencia?: number;
    tipoVia?: string;
    numero1?: string;
    letra1?: string;
    cuadrante1?: string;
    numero2?: string;
    letra2?: string;
    cuadrante2?: string;
    numero3?: string;
    informacionAdicional?: string;
    solicitanteId?: number;
    lineaCreditoId?: number;
  };
  enfermedadSolicitante?: [TEnfermedad];
  tyCFirmado?: boolean;
};

export type TAvalistas = {
  formData: {
    tipoDocumento: number;
    numeroDocumento: string;
    primerNombre: string;
    segundoNombre: string;
    primerApellido: string;
    segundoApellido: string;
    fechaNacimiento: string;
    numeroCelular: string;
    correoElectronico: string;
  };
  validationData: {
    fechaAfiliacion?: string;
    fechaFinAfiliacion?: string;
    nitEmpleador?: string;
    nombreEmpleador?: string;
    numeroDocumento?: string;
    primerNombre?: string;
    segundoNombre?: string;
    primerApellido?: string;
    segundoApellido?: string;
    fechaNacimiento?: string;
    edad?: number;
    categoria?: string;
    tipoAfiliado?: string;
    porcentajeAporte?: number;
    antiguedadLaboral?: number;
    salario?: number;
    cuotaMonetaria?: number;
  };
  solicitante: TSolicitante;
};
export type TSolicitud = {
  solicitanteLider: {
    stateSolicitante?: number;
    formData: {
      documentType?: string;
      creditLine?: string;
      documentNumber?: number;
      sede?: string;
      tipoDocumento?: string;
      numeroDocumento?: string;
      lugarExpedicionDocumento?: string;
      fechaExpedicionDocumento?: string;
      lugarDeNacimiento?: string;
      fechaNacimiento?: string;
      estadoCivil?: string;
      cantidadPersonasCargo?: string;
      nivelEducacion?: string;
      tipoVivienda?: string;
      tiempoEstancia?: string;
      viviendaVia?: string;
      viviendaNumero?: string;
      viviendaLetra?: string;
      viviendaCuadrante?: string;
      viviendaNumerodos?: string;
      viviendaLetrados?: string;
      viviendaCuadrantedos?: string;
      viviendaNumerotres?: string;
      viviendaInformacionAdicional?: string;
      estrato?: string;
      ciudadResidencia?: string;
      numeroCelular?: string;
      correoElectronico?: string;
      otroNumero?: string;
      ingresoBasico?: number;
      ingresoDemonstrables?: number;
      ingresoNoDemonstrables?: number;
      descuentoNomina?: number;
      creditosNoDescontables?: number;
      valorArriendo?: number;
      valorOtrosGastos?: number;
    };
    validationData: {
      fechaAfiliacion?: string;
      fechaFinAfiliacion?: string;
      nitEmpleador?: string;
      nombreEmpleador?: string;
      numeroDocumento?: string;
      primerNombre?: string;
      segundoNombre?: string;
      primerApellido?: string;
      segundoApellido?: string;
      fechaNacimiento?: string;
      edad?: number;
      categoria?: string;
      tipoAfiliado?: string;
      porcentajeAporte?: number;
      antiguedadLaboral?: number;
      salario?: number;
      cuotaMonetaria?: number;
    };
    solicitudData: {
      id: number;
      valorPreaprobado: number;
    };
    id?: number;
    solicitante: TSolicitante;
  };
  analista: {
    stateSolicitante?: number;
    solicitante: TSolicitante;
    titularCredito: {
      lineaCreditoId: string;
      tipoDocumentoId: string;
      numeroDocumento: number;
    };
  };
  avalistas: Array<TAvalistas>; //TODO: tipar el objeto de avalistas
  simulacion: TSimulation;
};

export type TEnfermedad = {
  enfermedadId?: number;
  solicitanteId?: number;
  fechaDiagnostico?: string;
  tratamiento?: string;
  nombre?: string;
};

export const initialSolicitud: TSolicitud = {
  solicitanteLider: {
    stateSolicitante: -1,
    formData: {},
    validationData: {},
    solicitudData: {
      id: 0,
      valorPreaprobado: 1000000,
    },
    id: -1,
    solicitante: {},
  },
  analista: {
    solicitante: {},
    stateSolicitante: -1,
    titularCredito: {
      lineaCreditoId: "",
      tipoDocumentoId: "",
      numeroDocumento: 0,
    },
  },
  avalistas: [],
  simulacion: {
    id: 1,
    valorCredito: 0,
    valorTasainteres: 0,
    valorGastosManejo: 0,
    valorCuotaSinSeguroMensualAprox: 0,
    valorCuotaMensual: 0,
    valorSegurosMensualAprox: 0,
    valorTotalCredito: 0,
    factorSeguroVida: 0,
    seguroVidaMensualAprox: 0,
    factorTodoRiesgo: 0,
    seguroTodoRiesgoMensual: 0,
    cuotas: 0,
    beneficioGastosManejo: 0,
    beneficioFrechValido: 0,
    cantidadSolicitantes: 0,
    categoria: "",
    cuotaSinSeguroMensualConFrech: 0,
    cuotaSinSeguroMensualConTasaPlena: 0,
    cuotaConTasaPlena: 0,
    cuotaConFrech: 0,
    denominacion: "",
    gastosManejo: 0,
    plazo: 0,
    seguroTodoRiesgoMensualAproximado: 0,
    segurosMensualesAproximados: 0,
    seguroVidaMensualAproximado: 0,
    sistemaAmortizacion: "",
    tasaInteresPlena: 0,
    tasaInteresSubsidiada: 0,
    tasaInteresUsuario: 0,
    tipoSimulacion: "",
    valorPrestamoTotal: 0,
    valorPrestamoTotalSinManejo: 0,
    valorReposicion: 0,
    valorSolicitado: 0,
    codigoUsoId: 0,
    tipoSubsidioId: 0,
    tipoBeneficioId: 0,
    lineaCreditoId: 0,
  },
};

export const ADD_SOLICITANTE_LIDER = "ADD_SOLICITANTE_LIDER";
export const ADD_SOLICITANTE_ANALISTA = "ADD_SOLICITANTE_ANALISTA";
export const ADD_TITULAR_CREDITO = "ADD_TITULAR_CREDITO";
export const UPDATE_STATE_SOLICITANTE_LIDER = "UPDATE_STATE_SOLICITANTE_LIDER";
export const UPDATE_SOLICITANTE_LIDER = "UPDATE_SOLICITANTE_LIDER";
export const UPDATE_SOLICITANTE_AVALISTA = "UPDATE_SOLICITANTE_AVALISTA";
export const CLEAR_SOLICITUD = "CLEAR_SOLICITUD";
export const ADD_AVALISTA = "ADD_AVALISTA";
export const DELETE_AVALISTAS2 = "DELETE_AVALISTAS2";

export const UPDATE_AVALISTAS = "UPDATE_AVALISTAS";
export const UPDATE_CONTACT_LIDER = "UPDATE_CONTACT_LIDER";
export const UPDATE_CONTACT_AVALISTA = "UPDATE_CONTACT_AVALISTA";
export const UPDATE_FINANCIAL_LIDER = "UPDATE_FINANCIAL_LIDER";
export const UPDATE_FINANCIAL_AVALISTA = "UPDATE_FINANCIAL_AVALISTA";
export const UPDATE_ACTIVITY_LIDER = "UPDATE_ACTIVITY_LIDER";
export const UPDATE_ACTIVITY_AVALISTA = "UPDATE_ACTIVITY_AVALISTA";
export const CLEAR_AVALISTAS = "CLEAR_AVALISTAS";
export const UPDATE_AVALISTA_ID = "UPDATE_AVALISTA_ID";
export const UPDATE_LIDER_ID = "UPDATE_LIDER_ID";
export const UPDATE_SIMULACION = "UPDATE_SIMULACION";
export const UPDATE_TERM_AVALISTA = "UPDATE_TERM_AVALISTA";

const reducerSolicitud = (state: any, action: any) => {
  switch (action.type) {
    case ADD_SOLICITANTE_LIDER:
      return {
        ...state,
        solicitanteLider: action.payload,
      };
    case ADD_SOLICITANTE_ANALISTA:
      return {
        ...state,
        analista: {
          ...state.analista,
          solicitante: action.payload.analista,
        },
        stateSolicitante: action.payload.stateSolicitante,
      };
    case ADD_TITULAR_CREDITO:
      console.log(state);

      return {
        ...state,
        analista: {
          ...state.analista,
          titularCredito: action.payload.titularCredito,
        },
      };
    case UPDATE_STATE_SOLICITANTE_LIDER:
      return {
        ...state,
        solicitanteLider: {
          ...state.solicitanteLider,
          ...action.payload,
        },
      };
    case UPDATE_SOLICITANTE_LIDER:
      state.solicitanteLider.formData = {
        ...state.solicitanteLider.formData,
        ...action.payload,
      };
      state.solicitanteLider.solicitante = {
        ...state.solicitanteLider.solicitante,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_CONTACT_LIDER:
      state.solicitanteLider.formData = {
        ...state.solicitanteLider.formData,
        ...action.payload.data,
      };
      state.solicitanteLider.solicitante = {
        ...state.solicitanteLider.solicitante,
        ...action.payload.data,
        informacionDomicilio: {
          ...state.solicitanteLider.solicitante.informacionDomicilio,
          ...action.payload.informacionDomicilio,
        },
      };
      return {
        ...state,
      };
    case UPDATE_FINANCIAL_LIDER:
      state.solicitanteLider.formData = {
        ...state.solicitanteLider.formData,
        ...action.payload,
      };
      state.solicitanteLider.solicitante = {
        ...state.solicitanteLider.solicitante,
        informacionFinanciera: {
          ...state.solicitanteLider.solicitante.informacionFinanciera,
          ...action.payload,
        },
      };
      return {
        ...state,
      };
    case UPDATE_ACTIVITY_LIDER:
      state.solicitanteLider.formData = {
        ...state.solicitanteLider.formData,
      };
      state.solicitanteLider.solicitante = {
        ...state.solicitanteLider.solicitante,
        informacionActividadEconomica: {
          ...action.payload,
        },
      };
      return {
        ...state,
      };
    case UPDATE_ACTIVITY_AVALISTA:
      state.avalistas[state.solicitanteLider.stateSolicitante - 1].solicitante =
        {
          ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
            .solicitante,
          informacionActividadEconomica: {
            ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
              .solicitante.informacionActividadEconomica,
            ...action.payload,
          },
        };
      return {
        ...state,
      };
    case UPDATE_CONTACT_AVALISTA:
      console.log(action);

      
      state.avalistas[state.solicitanteLider.stateSolicitante - 1].solicitante =
        {
          ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
            .solicitante,
          ...action.payload.data,
          informacionDomicilio: {
            ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
              .solicitante.informacionDomicilio,
            ...action.payload.informacionDomicilio,
          },
        };
      return {
        ...state,
      };
    case UPDATE_FINANCIAL_AVALISTA:
      state.avalistas[state.solicitanteLider.stateSolicitante - 1].solicitante =
        {
          ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
            .solicitante,
          informacionFinanciera: {
            ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
              .solicitante.informacionDomicilio,
            ...action.payload,
          },
        };
      return {
        ...state,
      };
    case UPDATE_SOLICITANTE_AVALISTA:
      state.avalistas[action.payload.idSolicitante - 1].solicitante = {
        ...state.avalistas[action.payload.idSolicitante - 1].solicitante,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_TERM_AVALISTA:
      state.avalistas[state.solicitanteLider.stateSolicitante - 1].solicitante =
        {
          ...state.avalistas[state.solicitanteLider.stateSolicitante - 1]
            .solicitante,
          ...action.payload,
        };
      return {
        ...state,
      };
    case CLEAR_SOLICITUD:
      return initialSolicitud;

    case ADD_AVALISTA:
      let dummie = { ...state };
      dummie.avalistas.push(action.payload); // {formData:{}, validationData:{}}
      return dummie;

    case DELETE_AVALISTAS2 : 
      return { 
        ...state, 
        avalistas: [state.avalistas[0]]
      };
    case UPDATE_AVALISTAS:
      return { ...state, avalistas: action.payload };

    case UPDATE_LIDER_ID:
      let data = { ...state };
      data.solicitanteLider.id = action.payload;
      return data;

    case CLEAR_AVALISTAS:
      return { ...state, avalistas: [] };

    case UPDATE_SIMULACION:
      return { ...state, simulacion: action.payload };

    default:
      throw new Error("NO action provided");
  }
};

export default reducerSolicitud;
