import React, { useContext, useEffect, useState } from "react";
import Button from "../../../atoms/button/button";
import Input from "../../../atoms/input/input";
import Tooltip from "../../../atoms/tooltip/tooltip";
import valorImage from "../../../../assets/icons/simulador/valor_cuota_mensual.svg";
import "./resultado.scss";
import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import currencyFormat from "../../../../utils/currencyFormat";
import {
  simulationSegunQuota,
  getSimulation,
  simulationSegunValue,
} from "../../../../services/simuladorServices";
import Loading from "../../../atoms/loading/loading";
import { AppContext } from "../../../../contexts/appContext";
import { TSimulation } from "../../../../interfaces/solicitudInterface";
import { UPDATE_SIMULACION } from "../../../../reducers/reducerSolicitud";

interface ResultadoProps {
  results: {
    valorCredito: number;
    valorTasainteres: number;
    valorGastosManejo: number;
    valorCuotaSinSeguroMensualAprox: number;
    valorCuotaMensual: number;
    valorSegurosMensualAprox: number;
    valorTotalCredito: number;
    factorSeguroVida: number;
    seguroVidaMensualAprox: number;
    factorTodoRiesgo: number;
    seguroTodoRiesgoMensual: number;
    cuotas: number;

    beneficioGastosManejo: number;
    beneficioFrechValido: number;
    cantidadSolicitantes: number;
    categoria: string;
    cuotaSinSeguroMensualConFrech: number;
    cuotaSinSeguroMensualConTasaPlena: number;
    cuotaConTasaPlena: number;
    cuotaConFrech: number;
    denominacion: string;
    gastosManejo: number;
    plazo: number;
    seguroTodoRiesgoMensualAproximado: number;
    segurosMensualesAproximados: number;
    seguroVidaMensualAproximado: number;
    sistemaAmortizacion: string;
    tasaInteresPlena: number;
    tasaInteresSubsidiada: number;
    tasaInteresUsuario: number;
    tipoSimulacion: string;
    valorPrestamoTotal: number;
    valorPrestamoTotalSinManejo: number;
    valorReposicion: number;
    valorSolicitado: number;
    codigoUsoId: number;
    tipoSubsidioId: number;
    tipoBeneficioId: number;
    lineaCreditoId: number;
  };
  register: any;
  errors: any;
  formData: any;
  setFormIdData: any;
  watch: any;
}

const ComfamaSlider = styled(Slider)({
  color: "#EE2B7B",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#EE2B7B",
  },
  "& .MuiSlider-rail": {
    border: "none",
    backgroundColor: "#EBEBEB",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#EE2B7B",
    boxShadow: "0px 3px 6px #00000029",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      // boxShadow: 'inherit',
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel *": {
    color: "white !important",
  },
});

const Resultado = ({
  results,
  register,
  errors,
  formData,
  setFormIdData,
  watch,
}: ResultadoProps) => {
  const [marcas, setMarcas] = useState<Array<{ value: number; label: string }>>(
    []
  );
  const [cuotas, setCuotas] = useState(formData["cantidadCuotas"]);
  const [enableCalc, setEnableCalc] = useState(false);
  const [stateResults, setStateResults] = useState(results);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  const simulation = appContext.stateSolicitud.simulacion;
  const dispatchSolicitud = appContext.dispatchSolicitud;
  const [minCuotas, setMincuotas] = useState(0);

  useEffect(() => {
    if (appContext.isRadication) {
      //Lleno los campos iniciales
      formData.tipoSimulacion = "SegunValor";
      console.log(appContext.stateSolicitud.solicitanteLider);
      formData.valorVivienda =
        appContext.stateSolicitud.solicitanteLider.solicitudData.valorPreaprobado.toString();
      //Consulto la data del simulador actual
      getDataSimulation();
      //Activo o desactivo el boton recalcular
      //con base en si el valor cambió a la última simulación
      if (appContext.valueSolicitud !== simulation.valorPrestamoTotal) {
        setEnableCalc(true);
      }
    }
  }, [appContext.isRadication]);

  const getDataSimulation = async () => {
    setLoading(true);
    let data: TSimulation;
    try {
      const response = await getSimulation(simulation.id);
      data = response.data.result.simulacion;
      console.log("simulation", data);
    } catch (error) {
      console.log(error);
      data = {
        id: 0,
        valorCredito: 0,
        valorTasainteres: 0,
        valorGastosManejo: 0,
        valorCuotaSinSeguroMensualAprox: 0,
        valorCuotaMensual: 0,
        valorSegurosMensualAprox: 0,
        valorTotalCredito: 0,
        factorSeguroVida: 0,
        seguroVidaMensualAprox: 0,
        factorTodoRiesgo: 0,
        seguroTodoRiesgoMensual: 0,
        cuotas: 0,
        beneficioGastosManejo: 0,
        beneficioFrechValido: 0,
        cantidadSolicitantes: 0,
        categoria: "",
        cuotaSinSeguroMensualConFrech: 0,
        cuotaSinSeguroMensualConTasaPlena: 0,
        cuotaConTasaPlena: 0,
        cuotaConFrech: 0,
        denominacion: "",
        gastosManejo: 0,
        plazo: 0,
        seguroTodoRiesgoMensualAproximado: 0,
        segurosMensualesAproximados: 0,
        seguroVidaMensualAproximado: 0,
        sistemaAmortizacion: "",
        tasaInteresPlena: 0,
        tasaInteresSubsidiada: 0,
        tasaInteresUsuario: 0,
        tipoSimulacion: "",
        valorPrestamoTotal: 0,
        valorPrestamoTotalSinManejo: 0,
        valorReposicion: 0,
        valorSolicitado: 0,
        codigoUsoId: 0,
        tipoSubsidioId: 0,
        tipoBeneficioId: 0,
        lineaCreditoId: 0,
      };
    }
    //Lleno los campos con la data actual
    formData.cuotaMesSolicitante = data.valorCuotaMensual;
    formData.cantidadCuotas = data.plazo;
    setCuotas(data.plazo);
    setMincuotas(data.plazo)
    dispatchSolicitud({
      type: UPDATE_SIMULACION,
      payload: data,
    });
    setLoading(false);
  };

  const handleChangeValue = (name: string, value: string) => {
    if (name === "valorVivienda") {
      if (Number(value) <= appContext.valueSolicitud) {
        formData[name] = value;
        errors[name] = "";
      }
    }
  };

  const monthsToYears = (months: number) => {
    return Math.round(months / 12);
  };

  const min = 60;
  const max = 240;

  const handleSliderChange = (e: any) => {
    if (e.target.value > minCuotas) {
      setCuotas(e.target.value);
    }
  };

  useEffect(() => {
    if (!appContext.isRadication) {
      if (cuotas === formData["cantidadCuotas"]) {
        setEnableCalc(false);
      } else {
        setEnableCalc(true);
      }
    }
  }, [cuotas, formData["cantidadCuotas"], appContext.isRadication]);

  // const recalculate = async () => {
  //     setLoading(true);
  //     getSimulation(
  //         formData["tipoSimulacion"],
  //         formData["porcentajeResultado"],
  //         cuotas,
  //         formData["solicitantes_adicionales"]
  //     ).then((data) => {
  //         setStateResults({ ...data.data.result, cuotas });
  //         setFormIdData("cantidadCuotas", cuotas);
  //     }).finally(() => {
  //         setLoading(false);
  //     })
  // }

  const getResultsValor = () => {
    const montoFormat = appContext.isRadication ? appContext.valueSolicitud : formData["valorVivienda"].replace(/[^0-9\.]+/g, "");
    console.log(montoFormat);

    console.log(cuotas);

    const data = {
      valorSolicitado: montoFormat,
      valorReposicion: 0,
      categoria: "B",
      plazoNumeroCuotas: cuotas,
      codigoUsoId: 1,
      tipoBeneficioId: 1,
      tipoSubsidioId: 1,
      etapaId: 1,
      simulacionId: 0,
      solicitantes: [
        {
          id: 5,
          edad: 50,
          tipoSolicitanteId: 1,
          porcentajeExtraPrima: 0,
        },
      ],
    };
    setLoading(true);
    simulationSegunValue({
      ...data,
      // valorSolicitado: formData["valorVivienda"],
      // categoria:
      //   appContext.stateSolicitud.solicitanteLider.validationData.categoria!,
      // plazoNumeroCuotas: cuotas,
      // numeroSolicitantes: appContext.stateSolicitud.avalistas.length + 1,
    })
      .then((res) => {
        setStateResults({ ...res.data.result, cuotas });
        dispatchSolicitud({
          type: UPDATE_SIMULACION,
          payload: {
            ...simulation,
            ...res.data.result,
          },
        });
        setFormIdData("cantidadCuotas", cuotas);
        // setResults(res.data.result);
        // moveNext();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getResultsCuota = () => {
    console.log(formData);

    const data = {
      valorCuota: formData["cuotaMesSolicitante"],
      valorReposicion: 0,
      categoria: "C",
      plazoNumeroCuotas: formData["cantidadCuotas"],
      codigoUsoId: 1,
      tipoBeneficioId: 1,
      tipoSubsidioId: 1,
      etapaId: 1,
      simulacionId: 0,
      solicitantes: [
        {
          id: 5,
          edad: 50,
          tipoSolicitanteId: 1,
          porcentajeExtraPrima: 0,
        },
      ],
    };
    setLoading(true);
    simulationSegunQuota({
      ...data,
      // valorCuota:
      //   formData["cuotaMesSolicitante"] || stateResults.valorCuotaMensual,
      // categoria:
      //   appContext.stateSolicitud.solicitanteLider.validationData.categoria!,
      // plazoNumeroCuotas: cuotas,
      // numeroSolicitantes: appContext.stateSolicitud.avalistas.length + 1,
    })
      .then((res) => {
        setStateResults({ ...res.data.result, cuotas });
        setFormIdData("cantidadCuotas", cuotas);
        //   setResults(res.data.result);
        //   moveNext();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getResults = async () => {
    if (formData["tipoSimulacion"] === "SegunValor") {
      getResultsValor();
    } else {
      getResultsCuota();
    }
  };

  useEffect(() => {
    const step = (max - min) / 5;
    const marksTemp = [
      {
        value: min,
        label: `${monthsToYears(min)} \naños`,
      },
    ];
    for (let i = 1; i < 6; i++) {
      let value = marksTemp[i - 1].value + step;
      marksTemp.push({
        value,
        label: `${monthsToYears(value)} \naños`,
      });
    }

    setMarcas(marksTemp);
  }, [min, max]);

  return (
    <div className="o-resultado">
      {loading && <Loading />}
      <div className="o-resultado__container">
        <div className="o-resultado__header">
          <div className="o-resultado__header__inputs">
            <Input
              register={register("tipoSimulacion", {
                required: appContext.isRadication
                  ? false
                  : "Este campo no puede estar vacio",
                defaultValue: formData["tipoSimulacion"],
              })}
              type="select"
              title="selecciona una opción" /* Valor del crédito */
              error={errors.tipoSimulacion?.message || ""}
              hiddenPlaceholder={true}
              options={[
                { label: "Valor que te prestamos", value: "SegunValor" },
                { label: "Valor que puedes pagar al mes", value: "SegunCuota" },
              ]}
              value={formData["tipoSimulacion"]}
              className="o-resultado__header__inputs__select"
              disabled={appContext.isRadication ? true : false}
            />
            {formData["tipoSimulacion"] === "SegunValor" ? (
              <Input
                register={register("valorVivienda", {
                  required: appContext.isRadication
                    ? false
                    : "Este campo no puede estar vacio",
                })}
                type="money"
                value={appContext.isRadication ? appContext.valueSolicitud : formData["valorVivienda"].replace(/[^0-9\.]+/g, "")}
                error={errors.valorVivienda?.message || ""}
                placeholder="$00.00"
                className="o-resultado__header__inputs__value"
                setValue={(name: string, value: string) => {
                  handleChangeValue(name, value);
                }}
                disabled={true}
              />
            ) : (
              <Input
                register={register("cuotaMesSolicitante", {
                  required: "Este campo no puede estar vacio",
                })}
                type="number"
                value={
                  formData["cuotaMesSolicitante"] ||
                  stateResults.valorCuotaMensual
                }
                error={errors.cuotaMesSolicitante?.message || ""}
                placeholder="$00.00"
                className="o-resultado__header__inputs__value"
              />
            )}
          </div>
          <div className="o-resultado__header__values">
            <header className="o-resultado__header__values__header">
              <img
                className="o-resultado__header__values__header__icon"
                src={valorImage}
                alt="$"
              />
              <h3 className="o-resultado__header__values__header__title">
                {formData["tipoSimulacion"] === "SegunValor"
                  ? "Valor de la cuota mensual"
                  : "Valor total del préstamo"}
              </h3>
            </header>
            <body className="o-resultado__header__values__body">
              <div className="o-resultado__header__values__body__section">
                <p className="o-resultado__header__values__body__section__cuotas">
                  {formData["tipoSimulacion"] === "SegunValor"
                    ? `${cuotas} cuotas de: ${
                        formData.cuotaMesSolicitante && !appContext.isRadication
                          ? currencyFormat(formData.cuotaMesSolicitante)
                          : ""
                      }`
                    : "Monto total:"}
                </p>
                {/* <p className="o-resultado__header__values__body__section__cuotas">{cuotas} cuotas de:</p> */}
                <h2 className="o-resultado__header__values__body__section__value">
                  {formData["tipoSimulacion"] === "SegunValor"
                    ? currencyFormat(stateResults.valorCuotaMensual) ||
                      currencyFormat(formData.cuotaMesSolicitante) || 
                      currencyFormat(simulation.cuotaConTasaPlena)
                    : currencyFormat(stateResults.valorTotalCredito)}
                </h2>
              </div>
              <Button
                text={"Recalcular"}
                type="button"
                isDisabled={appContext.isRadication ? false : !enableCalc}
                classname="o-resultado__header__values__body__button"
                gtmMark="secondary"
                onClickAction={getResults}
              />
            </body>
          </div>
        </div>
        <div className="o-resultado__progress">
          <h3 className="o-resultado__progress__title">¿A cuántos meses?</h3>
          <div className="o-resultado__progress__slider">
            <ComfamaSlider
              key="cantidadCuotas"
              name="cantidadCuotas"
              aria-label="Custom marks"
              value={cuotas}
              defaultValue={appContext.isRadication ? cuotas : formData.cantidadCuotas}
              step={1}
              min={min}
              max={max}
              marks={marcas}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `${value} meses`}
              onChange={handleSliderChange}
            />
          </div>
        </div>
        <div className="o-resultado__body">
          <div className="o-resultado__body__section">
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("tasaInteres", {
                  value: stateResults.valorTasainteres,
                  disabled: true,
                })}
                type="number"
                value={
                  appContext.isRadication
                    ? simulation.tasaInteresPlena
                    : stateResults.tasaInteresPlena
                }
                disabled={true}
                title="Tasa de interés"
                error={""}
                placeholder=""
                className="o-resultado__body__section__input-container__input"
              />
              <Tooltip
                title="Tasa de interés"
                subtitle="Es el porcentaje de interés mensual que pagarás sobre el saldo de tu crédito."
              />
            </div>
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("seguroVidaAprox", {
                  value: stateResults.valorSegurosMensualAprox,
                  disabled: true,
                })}
                type="text"
                title="Seguro de vida aproximado"
                error={""}
                placeholder=""
                value={
                  appContext.isRadication
                    ? currencyFormat(simulation.seguroVidaMensualAproximado)
                    : currencyFormat(stateResults.seguroVidaMensualAproximado)
                }
                disabled={true}
                className="o-gestionarCreditos-container__section__container__input"
              />
            </div>
          </div>
          <div className="o-resultado__body__section">
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("gastoManejo", {
                  value: stateResults.valorGastosManejo,
                  disabled: true,
                })}
                type="text"
                title="Gasto de manejo"
                error={""}
                placeholder=""
                value={
                  appContext.isRadication
                    ? currencyFormat(simulation.gastosManejo)
                    : currencyFormat(stateResults.gastosManejo)
                }
                disabled={true}
                className="o-resultado__body__section__input-container__input"
              />
              <Tooltip title="Gastos de manejo" subtitle="Es el valor de los costos operativos, administrativos y tecnológicos." />
            </div>
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("seguroTodoRiesgoAprox", {
                  value: stateResults.seguroTodoRiesgoMensual,
                  disabled: true,
                })}
                type="text"
                title="Seguro todo riesgo aproximado"
                error={""}
                placeholder=""
                value={
                  appContext.isRadication
                    ? currencyFormat(simulation.seguroTodoRiesgoMensualAproximado)
                    : currencyFormat(
                        stateResults.seguroTodoRiesgoMensualAproximado
                      )
                }
                disabled={true}
                className="o-gestionarCreditos-container__section__container__input"
              />
              <Tooltip title="Seguro todo riesgo aproximado" subtitle="Es el valor que pagarás por el seguro que cubre el crédito." />
            </div>
          </div>
          <div className="o-resultado__body__section">
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("valorCuotaSinSeguroMensualAprox", {
                  value: stateResults.valorCuotaSinSeguroMensualAprox,
                  disabled: true,
                })}
                type="text"
                title="Cuota sin seguro mensual"
                error={""}
                placeholder=""
                value={
                  appContext.isRadication
                    ? currencyFormat(simulation.cuotaSinSeguroMensualConTasaPlena)
                    : currencyFormat(
                        stateResults.cuotaSinSeguroMensualConTasaPlena
                      )
                }
                disabled={true}
                className="o-resultado__body__section__input-container__input"
              />
              <Tooltip title="Cuota sin seguro mensual" subtitle="Valor a pagar mensual por concepto del crédito, antes de sumar el valor de los seguros." />
            </div>
            <div className="o-resultado__body__section__input-container">
              <Input
                register={register("valorCredito", {
                  value: stateResults.valorCredito,
                  disabled: true,
                })}
                type="text"
                title="Valor del crédito"
                error={""}
                placeholder=""
                value={
                  appContext.isRadication
                    ? currencyFormat(simulation.valorPrestamoTotal)
                    : currencyFormat(stateResults.valorPrestamoTotal)
                }
                disabled={true}
                className="o-gestionarCreditos-container__section__container__input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resultado;
